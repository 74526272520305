export function trimWhiteSpaces(string) {
  return string.replace(/\s+/g, '')
}

export function minStrLength(string, length) {
  return string.length >= length
}

export function maxStrLength(string, length) {
  return string.length <= length
}

export function containsDigit(string) {
  return /\d/.test(string)
}

export function containsLetterCaseInsensitive(string) {
  return /[a-b]/i.test(string)
}
