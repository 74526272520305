import React from 'react'
import ReactMarkdown from 'react-markdown'
import { FormattedMessage } from 'react-intl'
import { ImageViewer, Button } from 'antd-mobile'
import './EventPrizes.css'

const renderWithImgViewer = {
  img: ({ src, alt }) => {
    const handleKeyDown = event => {
      if (event.key === 'Enter') {
        ImageViewer.show({ image: src })
      }
    }

    return (
      <Button
        onClick={() => {
          ImageViewer.show({ image: src })
        }}
        // onKeyPress={e => handleKeyDown(e)}
        className='event-prizes-image-viewer'
      >
        <img src={src} alt={alt} style={{ maxWidth: '100%' }} />
      </Button>
    )
  },
}

const EventPrizes = props => {
  const { prizes } = props

  return (
    <>
      <h4>
        <FormattedMessage id='eventActionPrizes' defaultMessage='Prizes' />
      </h4>
      <div className='event-prizes'>
        <ReactMarkdown components={renderWithImgViewer}>{prizes}</ReactMarkdown>
      </div>
    </>
  )
}

export default EventPrizes
