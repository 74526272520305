import React from 'react'
import ReactMarkdown from 'react-markdown'
import './EventDetails.css'

const EventDetails = ({ event }) => {
  const { description } = event

  return (
    <div className='event-details-container'>
      <div className='event-details-description'>
        <ReactMarkdown>{description}</ReactMarkdown>
      </div>
    </div>
  )
}

export default EventDetails
