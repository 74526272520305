import React, { useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { useDispatch, connect } from 'react-redux'
import { Button, Input, Toast } from 'antd-mobile'
import { useIntl, FormattedMessage } from 'react-intl'
import { setUser } from '../../redux/actions/userAction'
import RostersService from '../../services/RostersService'
import { maxStrLength, minStrLength } from '../../utils/validation/strings'
import { UNNAMED_TEAM } from '../../utils/userHelper'
import SEO from '../SEO'
import Message from '../message/Message'
import './CreateRosterName.css'

const MIN_NAME_LENGTH = 4
const MAX_NAME_LENGTH = 24

const mapStateToProps = state => ({ user: state.user })

const CreateRosterName = props => {
  const { user } = props
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const location = useLocation()
  const intl = useIntl()
  const navigateToLocation = location.state?.from || '/events'

  const displayedRosterName = user.rosterName === UNNAMED_TEAM
    ? ''
    : user.rosterName || ''

  const [rosterName, setRosterName] = useState(displayedRosterName)
  const [isLoading, setIsLoading] = useState(false)
  const [errorMsg, setErrorMsg] = useState('')
  const [isError, setIsError] = useState(false)

  const toastContent = intl.formatMessage({
    id: 'createRosterNameSuccessToast',
    defaultMessage: 'Roster name saved',
  })

  const createName = async () => {
    setIsLoading(true)
    try {
      const res = await RostersService.renameRoster({ name: rosterName })

      if (res.status === 201) {
        const updatedUser = {
          ...user,
          rosterName,
        }

        dispatch(setUser(updatedUser))
        setErrorMsg('')
        setIsError(false)
        Toast.show({
          icon: 'success',
          content: toastContent,
        })

        setTimeout(() => {
          navigate(navigateToLocation)
        }, 1000)
      }
    } catch (error) {
      setIsError(true)
      setErrorMsg(error.message || 'Error, sorry, try again later')
    } finally {
      setIsLoading(false)
    }
  }

  const isMinLengthValid = minStrLength(rosterName, MIN_NAME_LENGTH)
  const isMaxLengthValid = maxStrLength(rosterName, MAX_NAME_LENGTH)
  const isNameInvalid = !isMinLengthValid || !isMaxLengthValid || rosterName.trim() === ''

  const getValidationErrorMsg = () => (
    !isMinLengthValid || rosterName.trim() === '' ? (
      <FormattedMessage
        id='createRosterNameMinLengthError'
        defaultMessage={`Roster name must be at least ${MIN_NAME_LENGTH} characters long`}
        values={{ minLength: MIN_NAME_LENGTH }}
      />
    ) : (
      <FormattedMessage
        id='createRosterNameMaxLengthError'
        defaultMessage={`Roster name should not exceed ${MAX_NAME_LENGTH} characters`}
        values={{ maxLength: MAX_NAME_LENGTH }}
      />
    )
  )

  return (
    <div>
      <SEO
        pageTitle={intl.formatMessage({ id: 'createRosterTitle', defaultMessage: 'Name your roster' })}
        pageDescription={intl.formatMessage({ id: 'createRosterTitle', defaultMessage: 'Name your roster' })}
      />
      <div className='main-container'>
        <div className='roster-name-page-title'>
          <FormattedMessage id='createRosterTitle' defaultMessage='Name your roster' />
        </div>
        <div className={`input-group ${isError ? 'input-group-error' : ''}`}>
          <label>
            <FormattedMessage id='createRosterFieldName' defaultMessage='Roster name' />
          </label>
          <Input
            type='text'
            placeholder={intl.formatMessage({ id: 'createRosterRosterInputPlaceholder', defaultMessage: 'My roster' })}
            className='roster-name-input'
            value={rosterName}
            onChange={value => setRosterName(value)}
          />
          {isError || isNameInvalid ? (
            <Message
              msgType='support'
              message={isNameInvalid ? getValidationErrorMsg() : errorMsg}
            />
          ) : null}
        </div>
        <Button
          className='button-primary'
          onClick={() => createName()}
          block
          loading={isLoading}
          disabled={isLoading || isNameInvalid}
        >
          <FormattedMessage id='createRosterButton' defaultMessage='Save Roster Name' />
        </Button>
        <Button
          onClick={() => navigate(navigateToLocation)}
          className='button-secondary'
          block
        >
          <FormattedMessage id='favouriteTeamSkipBtn' defaultMessage='Skip' />
        </Button>
      </div>
    </div>
  )
}

export default connect(mapStateToProps)(CreateRosterName)
