import React from 'react'
import { Image, Button } from 'antd-mobile'
import { FormattedMessage } from 'react-intl'
import './PlayerCard.css'

const PlayerCardSearch = props => {
  const { player, balance, action, slot } = props
  const isPlaying = player.matches?.length
  const isBalanceSufficient = parseFloat(player.price) <= parseFloat(balance)
  const fadeClass = isBalanceSufficient && isPlaying ? '' : 'opacity-75'

  function getMatches(matches) {
    if (!isPlaying) {
      return (
        <p className='text-small color-white-80'>
          <FormattedMessage id='playerCardNotPlayingText' defaultMessage='Not playing' />
        </p>
      )
    }

    return (
      <>
        <p className='text-small color-white-80'>
          <FormattedMessage id='playerCardPlayingAgainstText' defaultMessage='Facing' />
        </p>
        {matches.map(match => {
          const { teamAId, teamALogoPath, teamBLogoPath } = match.match
          const key = `${match.matchId}-${player.id}`
          return (
            <div key={key} className='player-card__content__match'>
              {teamAId === player.team.id ? (
                <Image
                  src={teamBLogoPath}
                  className='player-card__content__matches__team-logo'
                />
              ) : (
                <Image
                  src={teamALogoPath}
                  className='player-card__content__matches__team-logo'
                />
              )}
            </div>
          )
        })}
      </>
    )
  }

  return (
    <div className={`player-card ${fadeClass}`}>
      <div className='player-card__image'>
        <Image src={player.photoPath} />
      </div>
      <div className='player-card__content'>
        <h4 className=''>{player.name}</h4>
        <div className='player-card__action__team-name'>
          <Image className='player-card__action__team-name__icon' src={player.team.logoPath} />
          <span className='text-small color-white-80'>
            {player.team.name}
          </span>
        </div>
        <div className='player-card__content__match-list list-facing'>
          {getMatches(player.matches)}
        </div>
      </div>
      <div className='player-card__action'>
        <p className='text-small color-white-80'>
          <span>$&nbsp;</span>
          <span>{player.price}</span>
        </p>
        { isBalanceSufficient ? (
          <Button
            className='player-card__action__button'
            onClick={() => action(player, slot)}
          >
            <FormattedMessage id='PlayerCardBuyBtn' defaultMessage='Buy' />
          </Button>
        ) : (
          <p className='text-small color-white-80 align-right'>
            <FormattedMessage
              id='playerCardNoMoneyWarning'
              defaultMessage='No Money'
            />
          </p>
        )}
      </div>
    </div>
  )
}

export default PlayerCardSearch
