import React, { useEffect, useState } from 'react'
import { useParams, useLocation, Link } from 'react-router-dom'
import { Image, Button, Skeleton, Toast } from 'antd-mobile'
import { FormattedMessage } from 'react-intl'
import { Helmet } from 'react-helmet'
import MatchList from './MatchList'
import Countdown from '../Countdown'
import EventsService from '../../services/EventsService'
import EventPrizes from './EventPrizes'
import EventTop3 from './EventTop3'
import PlayerList from './PlayerList'
import usePageView from '../../hooks/usePageView'
import useEventTracker from '../../hooks/useEventTracker'
import inGameLeadSvg from '../../images/roster-role-in-game-lead.svg'
import awperSvg from '../../images/roster-role-awper.svg'
import riflerSvg from '../../images/roster-role-rifler.svg'
import coachSvg from '../../images/roster-role-coach.svg'
import './EventTop3.css'

const EventDetailsNoAuthentication = () => {
  const queryParams = useParams()
  const { id: eventId } = queryParams
  const location = useLocation()
  const { pathname } = location
  const { trackEvent } = useEventTracker()
  const [isEventLoading, setIsEventLoading] = useState(true)
  const [event, setEvent] = useState({
    name: null,
    description: null,
    startDate: null,
    teams: [],
    matches: [],
  })

  const [selectedPlayersData, setSelectedPlayersData] = useState([
    {
      id: null,
      name: null,
      playerPhotoPath: inGameLeadSvg,
      role: { id: 1, name: 'In-game lead' },
      team: { id: null, logoPath: null, name: null },
      matchData: [],
      slot: 0,
    },
    {
      id: null,
      name: null,
      playerPhotoPath: awperSvg,
      role: { id: 3, name: 'AWPer' },
      team: { id: null, logoPath: null, name: null },
      matchData: [],
      slot: 1,
    },
    {
      id: null,
      name: null,
      playerPhotoPath: riflerSvg,
      role: { id: 2, name: 'Rifler' },
      team: { id: null, logoPath: null, name: null },
      matchData: [],
      slot: 2,
    },
    {
      id: null,
      name: null,
      playerPhotoPath: riflerSvg,
      role: { id: 2, name: 'Rifler' },
      team: { id: null, logoPath: null, name: null },
      matchData: [],
      slot: 3,
    },
    {
      id: null,
      name: null,
      playerPhotoPath: riflerSvg,
      role: { id: 2, name: 'Rifler' },
      team: { id: null, logoPath: null, name: null },
      matchData: [],
      slot: 4,
    },
    {
      id: null,
      name: null,
      playerPhotoPath: coachSvg,
      role: { id: 4, name: 'Coach' },
      team: { id: null, logoPath: null, name: null },
      matchData: [],
      slot: 5,
    },
  ])

  usePageView(event.name ? `${event.name} - ClutchKing.gg` : null)

  useEffect(() => {
    const fetchEvent = async () => {
      try {
        const { data } = await EventsService.getPublicEventDetails(eventId)
        setEvent(data)
        setIsEventLoading(false)
      } catch (error) {
        Toast.show({
          icon: 'fail',
          content: error.response?.data?.message || error.message || 'Error, try again',
        })
      }
    }
    fetchEvent()
  }, [eventId])

  useEffect(() => {
    if (event.name) {
      const pageTitle = `${event.name} - ClutchKing.gg`
      window.gtag('config', 'GA_TRACKING_ID', { page_title: pageTitle })
    }
  }, [event])

  const trackButtonClick = identifier => {
    trackEvent('Button Click', `CTA: ${identifier} - ${event.name}`, 1)
  }

  return (
    <>
      <div className='event-header'>
        { isEventLoading ? (
          <>
            <Skeleton animated style={{ height: '260px' }} />
            <div style={{ padding: '0 16px' }}>
              <Skeleton animated style={{ height: '48px', margin: '24px 0' }} />
              <Skeleton.Title animated />
              <Skeleton animated style={{ height: '600px', margin: '16px 0' }} />
            </div>
          </>
        ) : (
          <>
            <Helmet>
              <title>
                {event.name}
                &nbsp;- ClutchKing.gg
              </title>
              <meta name='description' content={`${event.name} - ClutchKing.gg`} />
            </Helmet>
            <div className='event-header__content'>
              <div className='event-header__content__title'>
                <p className='text-small bold color-gray-1'>
                  { event.status === 'ENDED' ? <FormattedMessage id='eventStatusEnded' defaultMessage='Ended' />
                    : <Countdown targetDate={event.startDate} /> }
                </p>
                <h3>{event.name}</h3>
              </div>

              { event.status === 'ENDED' ? (
                <EventTop3 eventId={event.id} />
              ) : (
                <div className='event-header__inline-prizes'>
                  <EventPrizes prizes={event.description} />
                </div>
              )}
              <Link to='/create-account' state={{ previousPath: pathname }} onClick={() => trackButtonClick('Play now')}>
                <Button className='button-primary full-width margin-top-32'>
                  <FormattedMessage id='eventPlayNow' defaultMessage='Play now' />
                </Button>
              </Link>
            </div>
          </>
        )}
      </div>

      <div className='event-header__teams margin-bottom-48'>
        <div className='margin-bottom-48'>
          <h4>
            <FormattedMessage id='eventMyTeam' defaultMessage='My team' />
          </h4>
          <Link to='/create-account' state={{ previousPath: pathname }} onClick={() => trackButtonClick('Player list')}>
            <PlayerList selectedPlayersData={selectedPlayersData} dummyList />
          </Link>
        </div>
        { !isEventLoading ? <MatchList event={event} /> : null }

        <Link to='/create-account' state={{ previousPath: pathname }} onClick={() => trackButtonClick('Build your team')}>
          <Button className='button-primary full-width margin-top-32'>
            <FormattedMessage id='eventCreateYourTeam' defaultMessage='Build your team for free' />
          </Button>
        </Link>

        <h4 className='margin-top-48'>
          <FormattedMessage id='eventTeams' defaultMessage='Teams' />
        </h4>
        <div className='event-header__teams__list'>
          {event.teams.map(team => (
            <Image
              key={team.id}
              src={team.logoPath}
              className='event-header__teams__list__logo'
            />
          ))}
        </div>
      </div>
    </>
  )
}

export default EventDetailsNoAuthentication
