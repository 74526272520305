import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { Button, Toast, Skeleton } from 'antd-mobile'
import { ChevronRightIcon } from '@heroicons/react/24/solid'
import { FormattedMessage } from 'react-intl'
import LeagueService from '../../services/LeagueService'
import LeagueRanking from './LeagueRanking'
import './MyLeagues.css'

const MyLeagues = props => {
  const { event, setLeftAction, setTitle } = props
  const [loading, setLoading] = useState(true)
  const [myLeagues, setMyLeagues] = useState([])
  const [selectedLeague, setSelectedLeague] = useState(false)

  useEffect(() => {
    const fetchMyLeagues = async () => {
      try {
        const { data } = await LeagueService.getMyLeagues()
        setMyLeagues(data)
      } catch (error) {
        Toast.show({
          icon: 'fail',
          content: error.response?.data?.message || error.message,
        })
      } finally {
        setLoading(false)
      }
    }

    fetchMyLeagues()
  }, [event])

  const handleKeyPress = (e, league) => {
    if (e.key === 'Enter' || e.key === ' ') {
      e.preventDefault()
      setSelectedLeague(league)
    }
  }

  function renderMyLeagues() {
    if (loading) {
      return (
        <>
          <Skeleton animated style={{ height: '40px' }} />
          <Skeleton animated style={{ height: '84px', marginTop: '4px' }} />
          <Skeleton animated style={{ height: '84px', marginTop: '4px' }} />
          <Skeleton animated style={{ height: '84px', marginTop: '4px' }} />
          <Skeleton animated style={{ height: '84px', marginTop: '4px' }} />
        </>
      )
    }

    if (!myLeagues || myLeagues.length === 0) {
      return (
        <>
          <h4>
            <FormattedMessage id='leaguesPageHeader' defaultMessage='Leagues' />
          </h4>
          <p className='color-white-80 margin-bottom-24'>
            <FormattedMessage id='leaguesPageCreateNoLeaguesMsg' defaultMessage='You are not part of a league yet' />
          </p>
          <Link to='/leagues'>
            <Button className='button-primary full-width'>
              <FormattedMessage id='noLeaguesSeeLeagues' defaultMessage='See leagues' />
            </Button>
          </Link>
        </>
      )
    }

    return (
      <>
        <h4 className='margin-bottom-24'>
          <FormattedMessage id='leaguesPageHeader' defaultMessage='Leagues' />
        </h4>
        <div className='event-leagues'>
          {myLeagues.map(league => (
            <div
              className='event-leagues__league'
              key={league.id}
              onClick={() => setSelectedLeague(league)}
              role='button'
              tabIndex='0'
              onKeyPress={e => handleKeyPress(e, league)}
            >
              <div className='event-leagues__league__content'>
                <p className='text-bold'>{league.name}</p>
                <p className='color-white-80'>
                  {league.memberCount}
                  <span>
                    &nbsp;
                    <FormattedMessage id='leaguesPageMembersTitle' defaultMessage=' members' />
                  </span>
                </p>
              </div>
              <ChevronRightIcon className='event-leagues__league__icon' />
            </div>
          ))}
        </div>
      </>
    )
  }

  return (
    !selectedLeague
      ? renderMyLeagues()
      : (
        <LeagueRanking
          event={event}
          league={selectedLeague}
          setSelectedLeague={setSelectedLeague}
          setLeftAction={setLeftAction}
          setTitle={setTitle}
        />
      )
  )
}

export default MyLeagues
