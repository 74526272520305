import React from 'react'
import { Routes, Route, useLocation, useMatch } from 'react-router-dom'
import { connect } from 'react-redux'
import { AnimatePresence } from 'framer-motion'
import { LanguageProvider } from './LanguageContext'
import { setUser, logoutUser } from './redux/actions/userAction'
import { fetchEvents } from './redux/actions/eventsAction'
import NavigationMenuBar from './components/NavigationMenuBar'
import Footer from './components/Footer'
import MainPage from './components/MainPage'
import CookieBanner from './components/CookieBanner'
import PrivacyPolicy from './components/PrivacyPolicy'
import TermsOfService from './components/TermsOfService'
import EventsList from './components/events/EventsList'
import EventDetailsNoAuthentication from './components/events/EventDetailsNoAuthentication'
import EventController from './components/events/EventController'
import Leagues from './components/leagues/Leagues'
import CreateLeague from './components/leagues/CreateLeague'
import LeagueDetails from './components/leagues/LeagueDetails'
import EditLeague from './components/leagues/EditLeague'
import PlayerList from './components/events/PlayerList'
import CreateRosterName from './components/events/CreateRosterName'
import Login from './components/user/Login'
import AuthCallback from './components/user/AuthCallback'
import Logout from './components/user/Logout'
import ResetPassword from './components/user/ResetPassword'
import ConfirmEmail from './components/user/ConfirmEmail'
import CreateAccount from './components/user/CreateAccount'
import ChangePassword from './components/user/ChangePassword'
import SelectFavouriteTeam from './components/user/SelectFavouriteTeam'
import EventDetails from './components/events/EventDetails'
import EventRanking from './components/events/EventRanking'
import Settings from './components/user/Settings'
import EmailSettings from './components/user/EmailSettings'
import ContactInfo from './components/ContactInfo'
import LanguageSwitch from './components/LanguageSwitch'
import PrivateRoute from './components/PrivateRoute'
import AnimatedRoute from './components/AnimatedRoute'
import ErrorPage404 from './components/ErrorPage404'
import EditUsername from './components/user/EditUsername'
import Announcements from './components/announcements/Announcements'
import AnnouncementDetail from './components/announcements/AnnouncementDetail'
import ReferralsPage from './components/user/ReferralsPage'
import ReferralCodeHandler from './components/ReferralCodeHandler'
import './utils/common.css'
import './App.css'

const mapStateToProps = state => ({
  user: state.user,
  accessToken: state.user?.accessToken,
  refreshToken: state.user?.refreshToken,
  events: state.events.events,
})

const mapDispatchToProps = dispatch => ({
  setEvents: () => dispatch(fetchEvents()),
  logoutUser,
})

const fadeVariants = {
  initial: { opacity: 0 },
  in: { opacity: 1 },
  out: { opacity: 0 },
}

const fadeInTransition = {
  type: 'spring',
  ease: 'easeInOut',
  duration: 0.24,
}

const fadeOutTransition = {
  type: 'spring',
  duration: 0,
}

const LoginPage = () => <Login />
const AuthCallbackPage = () => <AuthCallback />
const LogoutPage = () => <Logout />
const MainPageComponent = () => <MainPage />
const AnnouncementsComponent = () => <Announcements />
const AnnouncementDetailComponent = () => <AnnouncementDetail />
const CookieBannerComponent = () => <CookieBanner />
const PrivacyPolicyComponent = () => <PrivacyPolicy />
const TermsOfServiceComponent = () => <TermsOfService />
const RosterNameComponent = () => <CreateRosterName />
const EditUsernameComponent = () => <EditUsername />
const EventsListComponent = () => <EventsList />
const EventControllerComponent = () => <EventController />
const PlayerListComponent = () => <PlayerList />
const EventDetailsComponent = () => <EventDetails />
const EventRankingComponent = () => <EventRanking />
const LeaguesComponent = () => <Leagues />
const CreateLeagueComponent = () => <CreateLeague />
const LeagueDetailsComponent = () => <LeagueDetails />
const FavouriteTeamComponent = () => <SelectFavouriteTeam />
const ResetPasswordComponent = () => <ResetPassword />
const ChangePasswordComponent = () => <ChangePassword />
const ConfirmEmailComponent = () => <ConfirmEmail />
const SettingsComponent = () => <Settings />
const EmailSettingsComponent = () => <EmailSettings />
const ContactInfoComponent = () => <ContactInfo />

function App({ user, accessToken, events }) {
  const initialLocale = user?.language
  || localStorage.getItem('appLocale')
  || (() => {
    const browserLanguage = navigator.language.split(/[-_]/)[0].toLowerCase()
    if (browserLanguage === 'pt') {
      return 'pt'
    }
    if (browserLanguage === 'es') {
      return 'es'
    }
    return 'en'
  })()

  const location = useLocation()
  const isEventDetailsPage = useMatch('/events/:id')

  return (
    <LanguageProvider initialLocale={initialLocale}>
      <ReferralCodeHandler />
      <NavigationMenuBar className={isEventDetailsPage ? 'navigation-midnight-navy' : ''} />
      <div className='app-container'>
        <AnimatePresence mode='wait' onExitComplete={() => window.scrollTo(0, 0)}>
          <Routes location={location} key={location.pathname}>
            <Route
              exact
              path='/'
              element={(
                <AnimatedRoute
                  element={<MainPageComponent events={events} />}
                  variants={fadeVariants}
                  transition={{ in: fadeInTransition, out: fadeOutTransition }}
                />
              )}
            />
            <Route
              exact
              path='/login'
              element={(
                <AnimatedRoute
                  element={<LoginPage />}
                  variants={fadeVariants}
                  transition={{ in: fadeInTransition, out: fadeOutTransition }}
                />
              )}
            />
            <Route
              exact
              path='/auth/callback'
              element={(
                <AnimatedRoute
                  element={<AuthCallbackPage />}
                  variants={fadeVariants}
                  transition={{ in: fadeInTransition, out: fadeOutTransition }}
                />
              )}
            />
            <Route
              exact
              path='/logout'
              element={(
                <AnimatedRoute
                  element={<LogoutPage />}
                  variants={fadeVariants}
                  transition={{ in: fadeInTransition, out: fadeOutTransition }}
                />
              )}
            />
            <Route
              exact
              path='/edit-username'
              element={(
                <AnimatedRoute
                  element={<PrivateRoute element={EditUsernameComponent} user={user} />}
                  variants={fadeVariants}
                  transition={{ in: fadeInTransition, out: fadeOutTransition }}
                />
              )}
            />
            <Route
              path='/roster-name'
              element={(
                <AnimatedRoute
                  element={
                    <PrivateRoute element={RosterNameComponent} user={user} events={events} />
                  }
                  variants={fadeVariants}
                  transition={{ in: fadeInTransition, out: fadeOutTransition }}
                />
              )}
            />
            <Route
              path='/favourite-team'
              element={(
                <AnimatedRoute
                  element={<PrivateRoute element={FavouriteTeamComponent} user={user} />}
                  variants={fadeVariants}
                  transition={{ in: fadeInTransition, out: fadeOutTransition }}
                />
              )}
            />
            <Route
              path='/announcements'
              element={(
                <AnimatedRoute
                  element={<PrivateRoute element={AnnouncementsComponent} user={user} />}
                  variants={fadeVariants}
                  transition={{ in: fadeInTransition, out: fadeOutTransition }}
                />
              )}
            />
            <Route
              path='/announcements/:announcementSlug'
              element={(
                <AnimatedRoute
                  element={<PrivateRoute element={AnnouncementDetailComponent} user={user} />}
                  variants={fadeVariants}
                  transition={{ in: fadeInTransition, out: fadeOutTransition }}
                />
              )}
            />
            <Route
              exact
              path='/referrals'
              element={(
                <AnimatedRoute
                  element={<PrivateRoute element={ReferralsPage} user={user} />}
                  variants={fadeVariants}
                  transition={{ in: fadeInTransition, out: fadeOutTransition }}
                />
              )}
            />
            <Route
              exact
              path='/reset-password'
              element={(
                <AnimatedRoute
                  element={<ResetPasswordComponent />}
                  variants={fadeVariants}
                  transition={{ in: fadeInTransition, out: fadeOutTransition }}
                />
              )}
            />
            <Route
              exact
              path='/create-account'
              element={(
                <AnimatedRoute
                  element={<CreateAccount />}
                  variants={fadeVariants}
                  transition={{ in: fadeInTransition, out: fadeOutTransition }}
                />
              )}
            />
            <Route
              exact
              path='/change-password'
              element={(
                <AnimatedRoute
                  element={<ChangePasswordComponent />}
                  variants={fadeVariants}
                  transition={{ in: fadeInTransition, out: fadeOutTransition }}
                />
              )}
            />
            <Route
              exact
              path='/change-password/:token'
              element={(
                <AnimatedRoute
                  element={<ChangePasswordComponent />}
                  variants={fadeVariants}
                  transition={{ in: fadeInTransition, out: fadeOutTransition }}
                />
              )}
            />
            <Route
              exact
              path='/confirm-email/:token'
              element={(
                <AnimatedRoute
                  element={<ConfirmEmailComponent />}
                  variants={fadeVariants}
                  transition={{ in: fadeInTransition, out: fadeOutTransition }}
                />
              )}
            />
            <Route
              path='/events'
              element={(
                <AnimatedRoute
                  element={<EventsListComponent />}
                  variants={fadeVariants}
                  transition={{ in: fadeInTransition, out: fadeOutTransition }}
                />
              )}
            />
            <Route
              path='/events/:id'
              element={(
                <AnimatedRoute
                  element={user ? <EventControllerComponent /> : <EventDetailsNoAuthentication />}
                  variants={fadeVariants}
                  transition={{ in: fadeInTransition, out: fadeOutTransition }}
                />
              )}
            >
              <Route
                path='roster'
                element={(
                  <AnimatedRoute
                    element={<PrivateRoute element={PlayerListComponent} user={user} />}
                    variants={fadeVariants}
                    transition={{ in: fadeInTransition, out: fadeOutTransition }}
                  />
                )}
              />
              <Route
                path='details'
                element={(
                  <AnimatedRoute
                    element={<PrivateRoute element={EventDetailsComponent} user={user} />}
                    variants={fadeVariants}
                    transition={{ in: fadeInTransition, out: fadeOutTransition }}
                  />
                )}
              />
              <Route
                path='matches'
                element={(
                  <AnimatedRoute
                    element={<PrivateRoute element={EventRankingComponent} user={user} />}
                    variants={fadeVariants}
                    transition={{ in: fadeInTransition, out: fadeOutTransition }}
                  />
                )}
              />
            </Route>
            <Route
              path='/language/:langCode'
              element={(
                <AnimatedRoute
                  element={<LanguageSwitch />}
                  variants={fadeVariants}
                  transition={{ in: fadeInTransition, out: fadeOutTransition }}
                />
              )}
            />
            <Route
              path='/leagues'
              element={(
                <AnimatedRoute
                  element={<PrivateRoute element={LeaguesComponent} user={user} />}
                  variants={fadeVariants}
                  transition={{ in: fadeInTransition, out: fadeOutTransition }}
                />
              )}
            />
            <Route
              path='/create-league'
              element={(
                <AnimatedRoute
                  element={<PrivateRoute element={CreateLeagueComponent} user={user} />}
                  variants={fadeVariants}
                  transition={{ in: fadeInTransition, out: fadeOutTransition }}
                />
              )}
            />
            <Route
              path='/leagues/:leagueId/edit'
              element={(
                <AnimatedRoute
                  element={<PrivateRoute element={EditLeague} user={user} />}
                  variants={fadeVariants}
                  transition={{ in: fadeInTransition, out: fadeOutTransition }}
                />
              )}
            />
            <Route
              path='/leagues/:leagueId'
              element={(
                <AnimatedRoute
                  element={<PrivateRoute element={LeagueDetailsComponent} user={user} />}
                  variants={fadeVariants}
                  transition={{ in: fadeInTransition, out: fadeOutTransition }}
                />
              )}
            />
            <Route
              path='/settings'
              element={(
                <AnimatedRoute
                  element={<PrivateRoute element={SettingsComponent} user={user} />}
                  variants={fadeVariants}
                  transition={{ in: fadeInTransition, out: fadeOutTransition }}
                />
              )}
            />
            <Route
              path='/email-settings'
              element={(
                <AnimatedRoute
                  element={<PrivateRoute element={EmailSettingsComponent} user={user} />}
                  variants={fadeVariants}
                  transition={{ in: fadeInTransition, out: fadeOutTransition }}
                />
              )}
            />
            <Route
              exact
              path='/privacy-policy'
              element={(
                <AnimatedRoute
                  element={<PrivacyPolicyComponent />}
                  variants={fadeVariants}
                  transition={{ in: fadeInTransition, out: fadeOutTransition }}
                />
              )}
            />
            <Route
              exact
              path='/terms-of-service'
              element={(
                <AnimatedRoute
                  element={<TermsOfServiceComponent />}
                  variants={fadeVariants}
                  transition={{ in: fadeInTransition, out: fadeOutTransition }}
                />
              )}
            />
            <Route
              exact
              path='/contact'
              element={(
                <AnimatedRoute
                  element={<ContactInfoComponent />}
                  variants={fadeVariants}
                  transition={{ in: fadeInTransition, out: fadeOutTransition }}
                />
              )}
            />
            {/* path=* catches all invalid routes */}
            <Route
              path='*'
              element={(
                <AnimatedRoute
                  element={<ErrorPage404 />}
                  variants={fadeVariants}
                  transition={{ in: fadeInTransition, out: fadeOutTransition }}
                />
              )}
            />
          </Routes>
        </AnimatePresence>
        <Footer />
        <CookieBannerComponent />
      </div>
    </LanguageProvider>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(App)
