import React, { useState, useEffect } from 'react'
import { useNavigate, Link } from 'react-router-dom'
import { Button, Toast } from 'antd-mobile'
import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'
import { useMediaQuery } from 'react-responsive'
import EventCard from './events/EventCard'
import EventsService from '../services/EventsService'
import RostersService from '../services/RostersService'
import Logo from './Logo'
import './MainPage.css'

const mapStateToProps = state => ({
  user: state.user,
  events: state.events.events,
})

const MainPage = ({ user, events }) => {
  const [isLoading, setIsLoading] = useState(false)
  const [eventsData, setEventsData] = useState([])
  const [isLoadingEvents, setIsLoadingEvents] = useState(true)
  const navigate = useNavigate()

  const renderEvents = () => (
    (!events.length || events.length > 1)
      ? navigate('/events')
      : navigate(`/events/${events[0].id}`)
  )

  const handleOnClick = async () => {
    if (!user) {
      navigate('/login')
      return
    }

    try {
      setIsLoading(true)
      const { data } = await RostersService.getRosters()

      if (data !== null && user.isAuthenticated) {
        renderEvents()
      } else {
        navigate('/roster-name')
      }
    } catch (error) {
      Toast.show({
        icon: 'fail',
        content: error.response?.data?.message || error.message,
      })
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    const fetchEvents = async () => {
      try {
        const { data } = await EventsService.getAllEvents()
        setEventsData(data)
      } catch (error) {
        Toast.show({
          icon: 'fail',
          content: error.response?.data?.message || error.message,
        })
      } finally {
        setIsLoadingEvents(false)
      }
    }
    fetchEvents()
  }, [])

  return (
    <>
      <div className='landing-page'>
        <div className='landing-page__hero'>
          <div className='landing-page__hero__content'>
            <Logo className='landing-page__hero__content__logo' />
            <h2 className='landing-page__hero__content__eye-brow'>CS2 FANTASY LEAGUE</h2>
            <h1 className='landing-page__hero__content__title'>
              <FormattedMessage id='mainPageTitle' defaultMessage='Build your team, make your call.' />
            </h1>
            <p className='landing-page__hero__content_description'>
              <FormattedMessage
                id='mainPageSubtitle'
                defaultMessage='Create your dream Counter-Strike line-up and compete for prizes.'
              />
            </p>
            <div className='landing-page__hero__content_cta-container'>
              <Button
                className='button-primary full-width'
                onClick={handleOnClick}
                loading={isLoading}
              >
                <FormattedMessage id='mainPageJoinButton' defaultMessage='Login' />
              </Button>
              <Link to='/create-account'>
                <Button className='button-secondary full-width'>
                  <FormattedMessage id='mainPageCreateAccountButton' defaultMessage='Create an account' />
                </Button>
              </Link>
            </div>
          </div>
        </div>
        <div className='landing-page__how-to'>
          <h2 className='landing-page__how-to__title'>
            <FormattedMessage id='mainPageHowToTitle' defaultMessage='How it works' />
          </h2>
          <div className='landing-page__how-to__item'>
            <p className='landing-page__how-to__item__step-number'>
              01.
            </p>
            <p className='landing-page__how-to__item__step-description'>
              <FormattedMessage id='mainPageHowToItemOne' defaultMessage='Create and name your team' />
            </p>
          </div>
          <div className='landing-page__how-to__item'>
            <p className='landing-page__how-to__item__step-number'>
              02.
            </p>
            <p className='landing-page__how-to__item__step-description'>
              <FormattedMessage
                id='mainPageHowToItemTwo'
                defaultMessage='Join real Counter-Strike 2 events across the globe'
              />
            </p>
          </div>
          <div className='landing-page__how-to__item'>
            <p className='landing-page__how-to__item__step-number'>
              03.
            </p>
            <p className='landing-page__how-to__item__step-description'>
              <FormattedMessage
                id='mainPageHowToItemThree'
                defaultMessage='Build a line-up with your favourite players'
              />
            </p>
          </div>
          <div className='landing-page__how-to__item'>
            <p className='landing-page__how-to__item__step-number'>
              04.
            </p>
            <p className='landing-page__how-to__item__step-description'>
              <FormattedMessage
                id='mainPageHowToItemFour'
                defaultMessage='Buy and sell players during the tournament'
              />
            </p>
          </div>
          <div className='landing-page__how-to__item'>
            <p className='landing-page__how-to__item__step-number'>
              05.
            </p>
            <p className='landing-page__how-to__item__step-description'>
              <FormattedMessage
                id='mainPageHowToItemFive'
                defaultMessage='Compete for prizes'
              />
            </p>
          </div>
          <Link to='/create-account'>
            <Button className='button-secondary full-width'>
              <FormattedMessage id='mainPageHowToButton' defaultMessage='Join now' />
            </Button>
          </Link>
        </div>
      </div>
      <div className='landing-page__events'>
        <h2 className='margin-top-16 margin-bottom-24'>
          <FormattedMessage id='eventsListTitle' defaultMessage='Tournaments' />
        </h2>
        { !isLoadingEvents && eventsData.length ? (
          <div className='display-grid landing-page__events__list'>
            { eventsData.map(event => (
              <EventCard event={event} key={event.id} />
            ))}
          </div>
        ) : (
          <div>
            <p>
              <FormattedMessage
                id='eventsListNoEventsPlaceholder'
                defaultMessage='No events at the moment.'
              />
            </p>
          </div>
        )}
      </div>
    </>
  )
}

export default connect(mapStateToProps)(MainPage)

// to do error handling
