import React, { useEffect, useState } from 'react'
import { Skeleton, Toast } from 'antd-mobile'
import { FormattedMessage, useIntl } from 'react-intl'
import EventCard from './EventCard'
import EventsService from '../../services/EventsService'
import SEO from '../SEO'
import './EventsList.css'

const EventsList = () => {
  const [eventsData, setEventsData] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const intl = useIntl()

  useEffect(() => {
    const fetchEvents = async () => {
      try {
        const { data } = await EventsService.getAllEvents()
        setEventsData(data)
      } catch (error) {
        Toast.show({
          icon: 'fail',
          content: error.response?.data?.message || error.message,
        })
      } finally {
        setIsLoading(false)
      }
    }
    fetchEvents()
  }, [])

  const renderContent = () => {
    if (isLoading) {
      return (
        <>
          <Skeleton animated style={{ height: '140px', marginBottom: '24px' }} />
          <Skeleton animated style={{ height: '140px', marginBottom: '24px' }} />
          <Skeleton animated style={{ height: '140px', marginBottom: '24px' }} />
        </>
      )
    }

    return (
      eventsData.length ? (
        <div className='display-grid'>
          { eventsData.map(event => (
            <EventCard event={event} key={event.id} />
          ))}
        </div>
      ) : (
        <div>
          <p>
            <FormattedMessage
              id='eventsListNoEventsPlaceholder'
              defaultMessage='No events at the moment.'
            />
          </p>
        </div>
      )
    )
  }

  return (
    <>
      <SEO
        pageTitle={intl.formatMessage({ id: 'eventsListTitle', defaultMessage: 'Tournaments' })}
        pageDescription={intl.formatMessage({ id: 'eventsListTitle', defaultMessage: 'Tournaments' })}
      />
      <div className='main-container'>
        <h3 className='margin-top-16 margin-bottom-24'>
          <FormattedMessage id='eventsListTitle' defaultMessage='Tournaments' />
        </h3>
        {renderContent()}
      </div>
    </>
  )
}

export default EventsList
