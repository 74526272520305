import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet'

const SEO = ({ pageTitle, pageDescription }) => {
  useEffect(() => {
    window.gtag('config', 'G-5LHFS9NTQX', {
      page_title: pageTitle,
      page_path: window.location.pathname + window.location.search,
    })
  }, [pageTitle, pageDescription])

  return (
    <Helmet>
      <title>
        {pageTitle}
        &nbsp;- ClutchKing.gg
      </title>
      <meta name='description' content={`${pageDescription} - ClutchKing.gg`} />
    </Helmet>
  )
}

export default SEO
