import React from 'react'
import { Image } from 'antd-mobile'
import { FormattedMessage } from 'react-intl'
import './PointsPerLineupPlayerCard.css'

const PointsPerLineupPlayerCard = props => {

  const { player } = props

  return (
    <div className='points_per_lineup_player_card'>
      <div className='points_per_lineup_player_card__image'>
        <Image src={player.playerPhotoPath || player.photoPath} />
      </div>
      <div className='points_per_lineup_player_card__content'>
        <p className='points_per_lineup_player_card__content__player-name'>{player.playerName}</p>
        <p className='text-small color-white-80'>{player.roleName}</p>
      </div>
      <div className='points_per_lineup_player_card__action__points__container width-100'>
        <h6>
          {player.playerPoints || '-'}
        </h6>
        <p className='text-small'>
          <FormattedMessage
            id='PointsPerLineupPlayerCardPointsLabel'
            defaultMessage='points'
          />
        </p>
      </div>
    </div>
  )

}

export default PointsPerLineupPlayerCard
