import http from '../http-common'

class UserService {
  login = data => (
    http.post('/users/login', data)
  )

  newAccount = data => (
    http.post('/users', data)
  )

  confirmEmail = data => (
    http.post('/users/confirm-email', data)
  )

  updateFavouriteTeam = data => (
    http.put('/users/favourite-team', data)
  )

  resetPasswordRequest = data => (
    http.post('/password-reset/request', data)
  )

  resetPasswordConfirm = data => (
    http.post('/password-reset/confirm', data)
  )

  changePassword = data => (
    http.put('/users/change-password', data)
  )

  getEmailPreferences = () => (
    http.get('/users/email-preferences')
  )

  updateEmailPreferences = data => (
    http.put('/users/email-preferences', data)
  )

  updateLanguage = data => (
    http.put('users/language', data)
  )

  updateUsername = data => (
    http.put('users/username', data)
  )

  cookieConsent = data => (
    http.post('users/cookie-consent', data)
  )

  getReferrals = () => (
    http.get('users/referrals')
  )

}

export default new UserService()
