import React from 'react'
import { useNavigate } from 'react-router-dom'
import { Button } from 'antd-mobile'
import { FormattedMessage } from 'react-intl'
import './ErrorPage404.css'

const ErrorPage404 = () => {
  const navigate = useNavigate()

  const navigateToValidRoute = () => {
    navigate('/events')
  }

  return (
    <div className='error-page__chicken__hero'>
      <div className='error-page__chicken__hero__error__title'>
        404
      </div>
      <h4 className='error-page__chicken__hero__error__content'>
        <FormattedMessage id='errorPage_404' defaultMessage='Page not found' />
      </h4>
      <Button
        className='error-page__chicken__hero__button button-primary '
        onClick={navigateToValidRoute}
      >
        <FormattedMessage id='errorPage_404_backButton' defaultMessage='Back to events' />
      </Button>
    </div>
  )
}

export default ErrorPage404
