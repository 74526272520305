import React from 'react'
import { Button, Collapse, Form, Input, TextArea, Radio } from 'antd-mobile'
import { useIntl, FormattedMessage } from 'react-intl'

const LeagueForm = props => {
  const {
    onFinish,
    buttonText,
    buttonLoading = false,
    initialValues,
  } = props

  const [form] = Form.useForm()
  const intl = useIntl()

  const adjustedInitialValues = {
    ...initialValues,
    description: initialValues.description || '',
  }

  return (
    <Form
      form={form}
      onFinish={onFinish}
      initialValues={adjustedInitialValues}
      footer={(
        <Button
          type='submit'
          className='button-primary full-width margin-top-16'
          loading={buttonLoading}
        >
          {buttonText}
        </Button>
      )}
    >
      <Form.Item
        className='input-group'
        name='name'
        label={intl.formatMessage({ id: 'createLeagueNameLabel', defaultMessage: 'League Name' })}
        rules={[
          {
            required: true,
            message: intl.formatMessage({ id: 'createLeagueNameError', defaultMessage: 'League name is required' }),
          },
          {
            min: 3,
            message: intl.formatMessage({ id: 'createLeagueNameMinLengthError', defaultMessage: 'League name must be at least 3 characters' }),
          },
          {
            max: 50,
            message: intl.formatMessage({ id: 'createLeagueNameMaxLengthError', defaultMessage: 'League name must be at most 50 characters' }),
          },
        ]}
      >
        <Input
          placeholder={intl.formatMessage({ id: 'createLeagueNamePlaceholder', defaultMessage: 'Enter the league Name' })}
        />
      </Form.Item>

      <Form.Item
        name='description'
        label={intl.formatMessage({ id: 'createLeagueDescriptionLabel', defaultMessage: 'Description (optional)' })}
        rules={[
          { required: false },
          {
            max: 140,
            message: intl.formatMessage({ id: 'createLeagueDescriptionMaxLengthError', defaultMessage: 'Description must be at most 140 characters' }),
          },
        ]}
      >
        <TextArea
          placeholder={intl.formatMessage({ id: 'createLeagueDescriptionPlaceholder', defaultMessage: 'Enter the description' })}
          rows={3}
          showCount
          maxLength={140}
        />
      </Form.Item>

      <Form.Item
        name='isPrivate'
        label={intl.formatMessage({ id: 'createLeaguePrivacyLabel', defaultMessage: 'Privacy' })}
        rules={[{
          required: true,
          message: intl.formatMessage({ id: 'createLeaguePrivacyError', defaultMessage: 'Please select an option' }),
        }]}
      >
        <Radio.Group>
          <Radio value={false}>
            <p className='radio__title'>
              <FormattedMessage id='createLeaguePublicLabel' defaultMessage='Public league' />
            </p>
            <p className='radio__description'>
              <FormattedMessage
                id='createLeaguePublicDescription'
                defaultMessage='Any player will be able to join your league.'
              />
            </p>
          </Radio>
          <Radio value>
            <p className='radio__title'>
              <FormattedMessage id='createLeaguePrivateLabel' defaultMessage='Private league' />
            </p>
            <p className='radio__description'>
              <FormattedMessage
                id='createLeaguePrivateDescription'
                defaultMessage='You’ll need to invite players or accept their request to join your league.'
              />
            </p>
          </Radio>
        </Radio.Group>
      </Form.Item>
      <Collapse className='margin-bottom-16'>
        <Collapse.Panel
          key='1'
          title={intl.formatMessage({ id: 'createLeagueAdvancedSettingsLabel', defaultMessage: 'Advanced settings' })}
        >
          <Form.Item
            name='rankingCriteria'
            label={intl.formatMessage({ id: 'createLeagueRankingCriteriaLabel', defaultMessage: 'Ranking criteria' })}
            rules={[{
              required: true,
              message: intl.formatMessage({ id: 'createLeagueRankingCriteriaError', defaultMessage: 'Please select an option' }),
            }]}
          >
            <Radio.Group>
              <Radio value='points'>
                <p className='radio__title'>
                  <FormattedMessage id='createLeaguePointsLabel' defaultMessage='Points' />
                </p>
                <p className='radio__description'>
                  <FormattedMessage
                    id='createLeaguePointsDescription'
                    defaultMessage='Use our standard point system.'
                  />
                </p>
              </Radio>
              <Radio value='kills'>
                <p className='radio__title'>
                  <FormattedMessage id='createLeagueKillsLabel' defaultMessage='Kills' />
                </p>
                <p className='radio__description'>
                  <FormattedMessage
                    id='createLeagueKillsDescription'
                    defaultMessage='Use number of kills for ranking.'
                  />
                </p>
              </Radio>
              <Radio value='deaths'>
                <p className='radio__title'>
                  <FormattedMessage id='createLeagueDeathsLabel' defaultMessage='Deaths' />
                </p>
                <p className='radio__description'>
                  <FormattedMessage
                    id='createLeagueDeathsDescription'
                    defaultMessage='Use number of deaths for ranking.'
                  />
                </p>
              </Radio>
            </Radio.Group>
          </Form.Item>
          <Form.Item
            name='winningCondition'
            label={intl.formatMessage({ id: 'createLeagueWinningConditionLabel', defaultMessage: 'Winning condition' })}
            rules={[{
              required: true,
              message: intl.formatMessage({ id: 'createLeagueWinningConditionError', defaultMessage: 'Please select an option' }),
            }]}
          >
            <Radio.Group>
              <Radio value='best'>
                <p className='radio__title'>
                  <FormattedMessage id='createLeagueBestTeamWinsLabel' defaultMessage='Best team wins' />
                </p>
                <p className='radio__description'>
                  <FormattedMessage
                    id='createLeagueBestTeamWinsDescription'
                    defaultMessage='The team with the highest selected criteria wins.'
                  />
                </p>
              </Radio>
              <Radio value='worst'>
                <p className='radio__title'>
                  <FormattedMessage id='createLeagueWorstTeamWinsLabel' defaultMessage='Worst team wins' />
                </p>
                <p className='radio__description'>
                  <FormattedMessage
                    id='createWorstTeamWinsDescription'
                    defaultMessage='The team with the lowest selected criteria wins.'
                  />
                </p>
              </Radio>
            </Radio.Group>
          </Form.Item>
        </Collapse.Panel>
      </Collapse>
    </Form>
  )
}

export default LeagueForm
