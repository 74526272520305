import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { Toast } from 'antd-mobile'
import { useIntl, FormattedMessage } from 'react-intl'
import LeagueForm from './LeagueForm'
import LeagueService from '../../services/LeagueService'
import SEO from '../SEO'

const CreateLeague = () => {
  const navigate = useNavigate()
  const intl = useIntl()
  const initialValues = {
    isPrivate: false,
    rankingCriteria: 'points',
    winningCondition: 'best',
  }
  const onFinish = async values => {
    try {
      const response = await LeagueService.createLeague(values)
      if (response.status !== 201) {
        throw new Error('There was an error while creating your league')
      }
      navigate(`/leagues/${response.data?.id}`)
    } catch (error) {
      Toast.show({
        icon: 'fail',
        content: error.response?.data?.message || error.message,
      })
    }
  }

  return (
    <>
      <SEO
        pageTitle={intl.formatMessage({ id: 'createLeagueTitle', defaultMessage: 'Create your league' })}
        pageDescription={intl.formatMessage({ id: 'createLeagueTitle', defaultMessage: 'Create your league' })}
      />
      <div className='main-container margin-bottom-48 margin-top-24 display-grid'>
        <div className='col-span-4 lg-col-span-5'>
          <h3>
            <FormattedMessage id='createLeagueTitle' defaultMessage='Create your league' />
          </h3>
          <LeagueForm
            onFinish={onFinish}
            initialValues={initialValues}
            buttonText=<FormattedMessage id='createLeagueButton' defaultMessage='Create league' />
          />
        </div>
      </div>
    </>
  )
}

export default CreateLeague
