import React, { useEffect } from 'react'
import { useParams, useNavigate, useLocation } from 'react-router-dom'
import { useLanguage } from '../LanguageContext'

const LanguageSwitch = () => {
  const { langCode } = useParams()
  const { handleLocaleChange } = useLanguage()
  const location = useLocation()
  const navigate = useNavigate()
  const navigateTo = location.state?.previousPath || '/events'

  useEffect(() => {
    const updateLanguage = async () => {
      const newLanguage = (() => {
        switch (langCode) {
        case 'pt':
          return 'pt'
        case 'es':
          return 'es'
        default:
          return 'en'
        }
      })()

      await handleLocaleChange(newLanguage)
      navigate(navigateTo)
    }
    updateLanguage()
  })

  return null
}

export default LanguageSwitch
