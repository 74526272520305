import React, { useEffect, useState } from 'react'
import { Toast, Skeleton } from 'antd-mobile'
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/solid'
import { FormattedMessage } from 'react-intl'
import MatchCard from './MatchCard'
import EventsService from '../../services/EventsService'
import './MatchList.css'

const MatchList = props => {
  const { event } = props
  const [loading, setLoading] = useState(true)
  const [matchList, setMatchList] = useState([])
  const [currentIndex, setCurrentIndex] = useState(0)

  useEffect(() => {
    const fetchMatches = async () => {
      try {
        const { data } = await EventsService.getMatches(event.id)
        setMatchList(data)

        const liveStageIndex = data.findIndex(stage => stage.status === 'LIVE')
        setCurrentIndex(liveStageIndex !== -1 ? liveStageIndex : 0)
      } catch (error) {
        Toast.show({
          icon: 'fail',
          content: error.response?.data?.message || error.message,
        })
      } finally {
        setLoading(false)
      }
    }

    fetchMatches()
  }, [event])

  const handlePrevClick = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1)
    }
  }

  const handleNextClick = () => {
    if (currentIndex < matchList.length - 1) {
      setCurrentIndex(currentIndex + 1)
    }
  }

  function renderMatchList() {
    if (loading) {
      return (
        <>
          <Skeleton animated style={{ height: '40px' }} />
          <Skeleton animated style={{ height: '84px', marginTop: '4px' }} />
          <Skeleton animated style={{ height: '84px', marginTop: '4px' }} />
          <Skeleton animated style={{ height: '84px', marginTop: '4px' }} />
          <Skeleton animated style={{ height: '84px', marginTop: '4px' }} />
        </>
      )
    }

    const currentStage = matchList[currentIndex]

    return (
      <>
        <h4 className='lg-hidden margin-bottom-24'>
          <FormattedMessage id='eventControllerRoundMatchesLabel' defaultMessage='Matches this round' />
        </h4>
        {!matchList || matchList.length === 0 ? (
          <p>
            <FormattedMessage id='eventControllerNoMatchesPlaceholder' defaultMessage='No matches yet' />
          </p>
        ) : (
          <div className='match-list' key={matchList.id}>
            <div className='match-list__stage'>
              <button
                type='button'
                className='match-list__stage__nav-button'
                onClick={handlePrevClick}
                disabled={currentIndex === 0}
              >
                <ChevronLeftIcon className='match-list__stage__nav-button__icon' />
              </button>
              <div className='match-list__stage__name'>
                <h6 className='no-margin'>{currentStage.name}</h6>
              </div>
              <button
                type='button'
                className='match-list__stage__nav-button'
                onClick={handleNextClick}
                disabled={currentIndex === matchList.length - 1}
              >
                <ChevronRightIcon className='match-list__stage__nav-button__icon' />
              </button>
            </div>
            {currentStage.matches.map(match => (
              <MatchCard match={match} key={match.id} />
            ))}
          </div>
        )}
      </>
    )
  }

  return (
    renderMatchList()
  )
}

export default MatchList
