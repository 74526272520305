import React from 'react'
import { FormattedMessage } from 'react-intl'

const PrivacyPolicy = () => (
  <div className='main-container justify-text'>
    <h2><FormattedMessage id='privacyPolicyMainTitle' defaultMessage='ClutchKing Privacy Policy' /></h2>
    &nbsp;
    <h4>
      1.&nbsp;
      <FormattedMessage id='privacyPolicyArticle1' defaultMessage='Data Collection' />
    </h4>
    <p>
      1.1&nbsp;
      <FormattedMessage
        id='privacyPolicyArticle1_1'
        defaultMessage='Information Provided by Users: At ClutchKing, we collect information that
        you provide directly to us. This includes but is not limited to:'
      />
    </p>
    <ul>
      <li>
        <FormattedMessage
          id='privacyPolicyArticle1_1_li1'
          defaultMessage='Account Registration Data: Such as your email address, username, and
          chosen team name when you register for an account. If you choose to select a
          favorite Counter-Strike team, this information is also collected.'
        />
      </li>
      <li>
        <FormattedMessage
          id='privacyPolicyArticle1_1_li2'
          defaultMessage='User Communications: Any information you provide when you communicate with us,
          such as feedback, contact form responses, or customer support inquiries.'
        />
      </li>
    </ul>
    <p>
      1.2&nbsp;
      <FormattedMessage
        id='privacyPolicyArticle1_2'
        defaultMessage='Automated Data Collection: When you use ClutchKing, we
        automatically collect certain information, including:'
      />
    </p>
    <ul>
      <li>
        <FormattedMessage
          id='privacyPolicyArticle1_2_li1'
          defaultMessage='Usage Data: Information about your interactions with ClutchKing,
          such as game choices, team selections, and participation in events.'
        />
      </li>
      <li>
        <FormattedMessage
          id='privacyPolicyArticle1_2_li2'
          defaultMessage='Device and Access Information: This may include information such
          as your IP address, browser type, operating system, and
          device identifiers.'
        />
      </li>
      <li>
        <FormattedMessage
          id='privacyPolicyArticle1_2_li3'
          defaultMessage='Cookies and Tracking Technologies: We use cookies and similar
          tracking technologies to collect data about your activity
          on our platform, which helps us improve ClutchKing and personalize
          your experience.'
        />
      </li>
    </ul>
    <p>
      1.3&nbsp;
      <FormattedMessage
        id='privacyPolicyArticle1_3_dataFromOtherSources'
        defaultMessage='Data from Other Sources: We may also receive information
        about you from other sources, including:'
      />
    </p>
    <p>
      <FormattedMessage
        id='privacyPolicyArticle1_3_thirdPartyServices'
        defaultMessage='Third-Party Services: If you choose to link or integrate
        third-party services with ClutchKing, we may receive information
        from these services, subject to the policies of those services.'
      />
    </p>
    <p>
      <FormattedMessage
        id='privacyPolicyArticle1_3_otherUsers'
        defaultMessage='Other Users: We may receive information about you from other
        users, for example, if they invite you to join ClutchKing.'
      />
    </p>
    <h4>
      2.&nbsp;
      <FormattedMessage
        id='privacyPolicyArticle2'
        defaultMessage='Use of Data'
      />
    </h4>
    <p>
      2.1&nbsp;
      <FormattedMessage
        id='privacyPolicyArticle2_1_collectionAndUsePersonalData'
        defaultMessage='Collection and Use of Personal Data: At ClutchKing, we collect personal
        data necessary for providing and improving our gaming service. This includes:'
      />
    </p>
    <p>
      <FormattedMessage
        id='privacyPolicyArticle2_1_registrationInformation'
        defaultMessage='Registration Information: We collect information during the account
        registration process, such as email address, username, and team name.
        This data is used to create and manage user accounts.'
      />
    </p>
    <p>
      <FormattedMessage
        id='privacyPolicyArticle2_1_favoriteTeamSelection'
        defaultMessage='Favorite Team Selection (Optional): Users may choose to select a favorite
        Counter-Strike team. This information is used to personalize the gaming
        experience and, with user consent, for marketing purposes.'
      />
    </p>
    <p>
      <FormattedMessage
        id='privacyPolicyArticle2_1_gameplayData'
        defaultMessage='Gameplay Data: We collect data related to user interactions within the game,
        such as team management decisions, event participation, and rankings.
        This data is used to operate the game and provide a competitive gaming environment.'
      />
    </p>
    <p>
      2.2&nbsp;
      <FormattedMessage
        id='privacyPolicyArticle2_2'
        defaultMessage='Marketing and Communication: With your consent, we use your
        email address to send you game updates, news, and promotional offers
        related to ClutchKing. You have the option to opt out of receiving
        these communications at any time.'
      />
    </p>
    <p>
      2.3&nbsp;
      <FormattedMessage
        id='privacyPolicyArticle2_3'
        defaultMessage='Data Sharing: ClutchKing does not share your identifiable
        personal data with third parties, except as necessary for the operation
        of the game, compliance with legal obligations, or as part
        of a business transfer.'
      />
    </p>
    <p>
      2.4&nbsp;
      <FormattedMessage
        id='privacyPolicyArticle2_4'
        defaultMessage='Analytical Use: We may use aggregated, non-identifiable data for analytical
        purposes to improve our game, analyze trends, and manage our operational needs.'
      />
    </p>
    <p>
      2.5&nbsp;
      <FormattedMessage
        id='privacyPolicyArticle2_5'
        defaultMessage='Legal Basis for Processing: Our processing of your personal data
        is based on the necessity to fulfill our contractual obligations to you as
        a user of ClutchKing, your consent for specific uses of data, and our
        legitimate interests in operating and improving our gaming service.'
      />
    </p>
    <h4>
      3.&nbsp;
      <FormattedMessage
        id='privacyPolicyArticle3'
        defaultMessage='Data Sharing'
      />
    </h4>
    <p>
      3.1&nbsp;
      <FormattedMessage
        id='privacyPolicyArticle3_1'
        defaultMessage='Sharing of Personal Data: ClutchKing values your privacy and is committed
        to protecting your personal data. We do not sell, rent, or trade your
        personal information with third parties for their commercial purposes.'
      />
    </p>
    <p>
      3.2&nbsp;
      <FormattedMessage
        id='privacyPolicyArticle3_2'
        defaultMessage='Service Providers: We may share your data with third-party service
        providers to the extent necessary for them to provide services on our behalf.
        These services may include payment processing, data analysis, email delivery,
        hosting services, customer service, and marketing assistance. All service
        providers are obligated to protect your data and may not use
        it for any other purpose.'
      />
    </p>
    <p>
      3.3&nbsp;
      <FormattedMessage
        id='privacyPolicyArticle3_3'
        defaultMessage='Legal Requirements: ClutchKing may disclose your personal data if
        required to do so by law or in response to valid requests by
        public authorities (e.g., a court or a government agency).'
      />
    </p>
    <p>
      3.4&nbsp;
      <FormattedMessage
        id='privacyPolicyArticle3_4'
        defaultMessage='Business Transfers: In the event of a merger, acquisition, bankruptcy,
        dissolution, reorganization, or similar transaction or proceeding,
        we may transfer or share your personal data as part of the transaction.'
      />
    </p>
    <p>
      3.5&nbsp;
      <FormattedMessage
        id='privacyPolicyArticle3_5'
        defaultMessage='Consent-Based Sharing: In cases where your consent is obtained,
        we may share your data for purposes specified at the time of consent.'
      />
    </p>
    <p>
      3.6&nbsp;
      <FormattedMessage
        id='privacyPolicyArticle3_6'
        defaultMessage='Aggregated and Non-Identifiable Data: We may share aggregated
        or non-personally identifiable information publicly and with our partners.
        This information may include analytics or trends that do not
        identify any individual user.'
      />
    </p>
    <p>
      3.7&nbsp;
      <FormattedMessage
        id='privacyPolicyArticle3_7'
        defaultMessage='International Transfers: If ClutchKing transfers personal data
        outside of your country, we will take steps to ensure that
        appropriate safeguards are in place to protect your personal
        data to the standard required by your local data protection laws.'
      />
    </p>
    <p>
      3.8&nbsp;
      <FormattedMessage
        id='privacyPolicyArticle3_8'
        defaultMessage='Changes to Our Data Sharing Practices: If there are any changes
        to our data sharing practices, these will be reflected in this Privacy
        Policy, and, if necessary, we will seek your consent.'
      />
    </p>
    <h4>
      4.&nbsp;
      <FormattedMessage
        id='privacyPolicyArticle4'
        defaultMessage='User Rights'
      />
    </h4>
    <p>
      4.1&nbsp;
      <FormattedMessage
        id='privacyPolicyArticle4_1'
        defaultMessage='Right to Access: Users have the right to request access to their
        personal data that ClutchKing holds.'
      />
    </p>
    <p>
      4.2&nbsp;
      <FormattedMessage
        id='privacyPolicyArticle4_2'
        defaultMessage={`Right to Rectification: If a user's personal data is incorrect
        or incomplete, they have the right to have it corrected.`}
      />
    </p>
    <p>
      4.3&nbsp;
      <FormattedMessage
        id='privacyPolicyArticle4_3'
        defaultMessage='Right to Erasure: Users can request the deletion of their personal
        data, especially when it is no longer necessary for the purposes it
        was collected. This request may be subject to certain conditions.'
      />
    </p>
    <p>
      4.4&nbsp;
      <FormattedMessage
        id='privacyPolicyArticle4_4'
        defaultMessage='Right to Object: Users have the right to object to the processing
        of their personal data, particularly for marketing purposes.'
      />
    </p>
    <p>
      4.5&nbsp;
      <FormattedMessage
        id='privacyPolicyArticle4_5'
        defaultMessage='Right to Data Portability: Users can request their data in a
        portable format or have it transferred directly to another organization,
        under certain conditions.'
      />
    </p>
    <p>
      4.6&nbsp;
      <FormattedMessage
        id='privacyPolicyArticle4_6'
        defaultMessage='Right to Withdraw Consent: Where processing is based on consent,
        users have the right to withdraw their consent at any time.'
      />
    </p>
    <p>
      4.7&nbsp;
      <FormattedMessage
        id='privacyPolicyArticle4_7'
        defaultMessage='Exercising Rights: To exercise any of these rights, users should
        contact ClutchKing at questions@clutchking.com.br'
      />
    </p>
    <p>
      4.8&nbsp;
      <FormattedMessage
        id='privacyPolicyArticle4_8'
        defaultMessage='Additional Information: For more information about these rights or
        any concerns about data processing at ClutchKing, please contact
        us at questions@clutchking.com.br.'
      />
    </p>
    <h4>
      5.&nbsp;
      <FormattedMessage
        id='privacyPolicyArticle5'
        defaultMessage='Data Security'
      />
    </h4>
    <p>
      <FormattedMessage
        id='privacyPolicyArticle5_dataEncryption'
        defaultMessage='Data Encryption: All personal data collected by ClutchKing is protected using
        encryption in transit. This ensures that user data remains secure
        and inaccessible to unauthorized parties.'
      />
    </p>
    <p>
      <FormattedMessage
        id='privacyPolicyArticle5_secureServers'
        defaultMessage='Secure Servers: Personal data is stored on secure servers.
        These servers are safeguarded with advanced security measures
        to prevent unauthorized access and ensure data integrity.'
      />
    </p>
    <h4>
      6.&nbsp;
      <FormattedMessage
        id='privacyPolicyArticle6'
        defaultMessage='Google Analytics'
      />
    </h4>
    <p>
      <FormattedMessage
        id='privacyPolicyArticle6_googleAnalyticsUse'
        defaultMessage={`ClutchKing uses Google Analytics to analyze and track users' use of our website.
        This helps us to understand user behavior and improve our services.
        Google Analytics may collect data about your interactions on our site via
        cookies and similar technologies.
        For more information on Google's data practices, please visit their Privacy Policy.`}
      />
    </p>
    <h4>
      7.&nbsp;
      <FormattedMessage
        id='privacyPolicyArticle7'
        defaultMessage='Changes to Privacy Policy'
      />
    </h4>
    <p>
      7.1&nbsp;
      <FormattedMessage
        id='privacyPolicyArticle7_1'
        defaultMessage='Right to Modify: ClutchKing reserves the right to update or modify this Privacy
        Policy at any time. Changes to our Privacy Policy become effective when they
        are posted on our website.'
      />
    </p>
    <p>
      7.2&nbsp;
      <FormattedMessage
        id='privacyPolicyArticle7_2'
        defaultMessage='Notification of Changes: Whenever significant changes are made to the
        Privacy Policy, we will endeavor to inform users through email notifications,
        provided they have confirmed their email address with us. We will also update
        the "Last Updated" date at the top of the Privacy Policy.'
      />
    </p>
    <p>
      7.3&nbsp;
      <FormattedMessage
        id='privacyPolicyArticle7_3'
        defaultMessage='Reviewing Changes: Users are encouraged to frequently check this Privacy Policy
        for any changes. Continued use of the ClutchKing service after any change in the
        Privacy Policy will constitute your acceptance of such changes.'
      />
    </p>
    <p>
      7.4&nbsp;
      <FormattedMessage
        id='privacyPolicyArticle7_4'
        defaultMessage='Your Rights: If you disagree with any changes to this Privacy Policy,
        you have the right to discontinue using our services. If you wish to exercise
        any of your data protection rights (as outlined in Section 4),
        please contact us at questions@clutchking.com.br.'
      />
    </p>
    <p>
      7.5&nbsp;
      <FormattedMessage
        id='privacyPolicyArticle7_5'
        defaultMessage='Contact for Questions: If you have any questions or concerns about our
        Privacy Policy or its updates, please contact us at questions@clutchking.com.br.'
      />
    </p>
    <h4>
      8.&nbsp;
      <FormattedMessage
        id='privacyPolicyArticle8'
        defaultMessage='Contact Information'
      />
    </h4>
    <p>
      <FormattedMessage
        id='privacyPolicyArticle8_privacyConcerns'
        defaultMessage='For privacy concerns: questions@clutchking.com.br'
      />
    </p>
  </div>
)

export default PrivacyPolicy
