/* eslint-disable quotes */
import React from 'react'
import { FormattedMessage } from 'react-intl'

const EventRules = () => {
  const rules = [
    {
      key: 'inGameLead',
      role: 'In-game Lead',
      criteria: {
        roundDifference: {
          score: 'x1',
          description: <FormattedMessage id='eventRulesInGameLeadRoundDifference' defaultMessage='Round difference for the map.' />,
        },
        winning: {
          score: '+5',
          description: <FormattedMessage id='eventRulesInGameLeadWinning' defaultMessage='Winning a map.' />,
        },
        losing: {
          score: '-5',
          description: <FormattedMessage id='eventRulesInGameLeadLosing' defaultMessage='Losing a map.' />,
        },
        teamFirstKill: {
          score: '+0.3',
          description: <FormattedMessage id='eventRulesInGameLeadTeamFirstKill' defaultMessage='Every own team first kill.' />,
        },
        teamFlashAssists: {
          score: '+0.2',
          description: <FormattedMessage id='eventRulesInGameLeadTeamFlashAssists' defaultMessage='Every own team flash assists.' />,
        },
        fullEcoWon: {
          score: '+3',
          description: <FormattedMessage id='eventRulesInGameLeadFullEco' defaultMessage='Every full eco won.' />,
        },
        semiEcoWon: {
          score: '+1',
          description: <FormattedMessage id='eventRulesInGameLeadSemiEco' defaultMessage='Every semi eco won.' />,
        },
        teamClutches: {
          score: '+1',
          description: <FormattedMessage id='eventRulesInGameLeadTeamClutch' defaultMessage='Every own team clutch.' />,
        },
      },
    },
    {
      key: 'awPer',
      role: 'AWPer',
      criteria: {
        killDeathDifference: {
          score: 'x3',
          description: <FormattedMessage id='eventRulesAWPerKillDeathDifference' defaultMessage='Kill / Death difference.' />,
        },
        awpKill: {
          score: '+0.5',
          description: <FormattedMessage id='eventRulesAWPerAwpKill' defaultMessage='Every AWP kill.' />,
        },
        firstKill: {
          score: '+2',
          description: <FormattedMessage id='eventRulesAWPerFirstKill' defaultMessage='Every first kill' />,
        },
        firstDeath: {
          score: '-3',
          description: <FormattedMessage id='eventRulesAWPerFirstDeath' defaultMessage='Every time they are first to die.' />,
        },
      },
    },
    {
      key: 'rifler',
      role: 'Rifler',
      criteria: {
        killDeathDifference: {
          score: 'x3',
          description: <FormattedMessage id='eventRulesRiflerKillDeathDifference' defaultMessage='Kill / Death difference.' />,
        },
        headshotKill: {
          score: '+0.2',
          description: <FormattedMessage id='eventRulesRiflerHeadshotKill' defaultMessage='Every headshot kill.' />,
        },
        firstKill: {
          score: '+1',
          description: <FormattedMessage id='eventRulesRiflerOwnFirstKill' defaultMessage='Every first kill.' />,
        },
        assists: {
          score: '+0.3',
          description: <FormattedMessage id='eventRulesRiflerAssists' defaultMessage='Every assist.' />,
        },
      },
    },
    {
      key: 'coach',
      role: <FormattedMessage id='eventRulesCoachHeader' defaultMessage='Coach' />,
      criteria: {
        points: {
          score: 'ø',
          description: <FormattedMessage id='eventRulesCoach' defaultMessage={`Gets their own team's average points.`} />,
        },
      },
    },
  ]

  return (
    <>
      <h4>
        <FormattedMessage id='eventActionRules' defaultMessage='Rules' />
      </h4>
      <p className='color-gray-1'>
        <FormattedMessage id='eventRulesPointSystem' defaultMessage='Point System' />
      </p>
      <table className='event-rules-table'>
        <tbody>
          {rules.map(role => (
            <React.Fragment key={role.key}>
              <tr>
                <td colSpan='2' className='event-rules-table__role'>
                  <h6>{role.role}</h6>
                </td>
              </tr>
              {Object.entries(role.criteria).map(([key, criteria]) => (
                <tr key={key} className='event-rules-table__criteria'>
                  <td className='event-rules-table__score'>
                    <span className='text-small'>{criteria.score}</span>
                  </td>
                  <td className='event-rules-table__description'>
                    <span className='text-small'>{criteria.description}</span>
                  </td>
                </tr>
              ))}
            </React.Fragment>
          ))}
        </tbody>
      </table>
    </>
  )
}

export default EventRules
