import React from 'react'
import { Image } from 'antd-mobile'

const Logo = ({ className }) => (
  <Image
    className={className}
    src='https://cdnz.clutchking.gg/misc/clutchking-logo.svg'
    alt='ClutchKing logo'
  />
)

export default Logo
