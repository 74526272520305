import React, { useState, useEffect } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { Toast, Button, Dialog, Skeleton } from 'antd-mobile'
import { FormattedMessage, useIntl } from 'react-intl'
import LeagueForm from './LeagueForm'
import LeagueService from '../../services/LeagueService'
import SEO from '../SEO'
import './LeagueDetails.css'

const EditLeague = () => {
  const [league, setLeague] = useState([])
  const [loadingLeague, setLoadingLeague] = useState(true)
  const [leagueError, setLeagueError] = useState(null)
  const [isLeagueAdmin, setIsLeagueAdmin] = useState(null)
  const user = useSelector(state => state.user)

  const navigate = useNavigate()
  const intl = useIntl()
  const queryParams = useParams()
  const { leagueId } = queryParams

  const deleteLeagueSuccessToastContent = intl.formatMessage({
    id: 'deleteLeagueSuccessToastContent',
    defaultMessage: 'League deleted',
  })

  useEffect(() => {
    const fetchLeague = async () => {
      try {
        const response = await LeagueService.getLeagueDetails(leagueId)
        const { data, data: { league: leagueData } } = response
        setLeague(leagueData)

        if (leagueData.userId !== user?.id) {
          navigate(`/leagues/${leagueId}`)
        }
      } catch (error) {
        console.error('Error fetching leagues:', error)
        setLeagueError('Error fetching leagues. Please try again later.')
      } finally {
        setLoadingLeague(false)
      }
    }
    fetchLeague()
  }, [leagueId, user.id, navigate])

  const onFinish = async values => {
    try {
      const response = await LeagueService.updateLeague(leagueId, values)
      if (response.status !== 200) {
        throw new Error('There was an error while updating your league')
      }
      navigate(`/leagues/${leagueId}`)
    } catch (error) {
      Toast.show({
        icon: 'fail',
        content: error.response?.data?.message || error.message,
      })
    }
  }

  const renderPage = () => {
    if (loadingLeague) {
      return (
        <>
          <Skeleton animated style={{ height: '100px', marginBottom: '8px' }} />
          <Skeleton animated style={{ height: '166px', marginBottom: '8px' }} />
          <Skeleton.Title animated />
          <Skeleton animated style={{ height: '76px' }} />
          <Skeleton animated style={{ height: '100px' }} />
          <Skeleton.Title animated />
          <Skeleton animated style={{ height: '50px', marginBottom: '48px' }} />
          <Skeleton.Title animated />
          <Skeleton animated style={{ height: '50px' }} />
        </>
      )
    }

    if (leagueError) {
      return <p>{leagueError}</p>
    }

    return (
      <LeagueForm
        onFinish={onFinish}
        buttonText='Save changes'
        initialValues={league}
      />
    )
  }

  const handleDeleteLeague = async () => {
    try {
      const res = await LeagueService.deleteLeague(leagueId)

      Toast.show({
        icon: 'success',
        content: deleteLeagueSuccessToastContent,
      })

      navigate('/leagues')
    } catch (error) {
      Toast.show({
        icon: 'fail',
        content: error.response?.data?.message || error.message,
      })
    }
  }

  const showDeleteConfirmation = async () => {
    const promptResult = await Dialog
      .confirm({
        content: intl.formatMessage({
          id: 'editLeagueConfirmDeleteLeagueMsg',
          defaultMessage: 'Are you sure you want to delete this league?',
        }),
        confirmText: intl.formatMessage({
          id: 'editLeagueConfirmDeleteLeagueBtn',
          defaultMessage: 'Yes',
        }),
        cancelText: intl.formatMessage({
          id: 'editLeagueCancelDeleteBtn',
          defaultMessage: 'Cancel',
        }),
        closeOnAction: true,
        closeOnMaskClick: true,
      })

    if (promptResult) {
      handleDeleteLeague()
    }
  }

  return (
    <>
      <SEO
        pageTitle={intl.formatMessage({ id: 'leagueDetailsEditBtn', defaultMessage: 'Edit league' })}
        pageDescription={intl.formatMessage({ id: 'leagueDetailsEditBtn', defaultMessage: 'Edit league' })}
      />
      <div className='main-container display-grid margin-bottom-24'>
        <div className='col-span-4 lg-col-span-5'>
          {renderPage()}
          <h4 className='margin-top-48'>
            <FormattedMessage id='deleteLeagueTitle' defaultMessage='Delete league' />
          </h4>
          <Button
            onClick={() => showDeleteConfirmation()}
            className='button-secondary full-width button-secondary-delete-action'
          >
            <FormattedMessage id='deleteLeagueButton' defaultMessage='Delete league' />
          </Button>
        </div>
      </div>
    </>
  )
}

export default EditLeague
