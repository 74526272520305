export const setUser = user => {
  localStorage.setItem('user', JSON.stringify(user))

  return {
    type: 'SET_USER',
    payload: user,
  }
}

export const logoutUser = () => {
  localStorage.removeItem('user')

  return { type: 'LOGOUT_USER' }
}
