import { useLocation } from 'react-router-dom'

const useAuthUrls = (baseUrls, language) => {
  const location = useLocation()
  const redirectTo = location.state?.previousPath || null
  const referralCode = localStorage.getItem('referralCode')

  const buildAuthUrl = baseUrl => {
    const params = new URLSearchParams()
    if (redirectTo) params.append('redirect', redirectTo)
    if (language) params.append('language', language)
    if (referralCode) params.append('referralCode', referralCode)
    return `${baseUrl}?${params.toString()}`
  }

  return {
    googleAuthUrl: buildAuthUrl(baseUrls.google),
    facebookAuthUrl: buildAuthUrl(baseUrls.facebook),
    twitchAuthUrl: buildAuthUrl(baseUrls.twitch),
  }
}

export default useAuthUrls
