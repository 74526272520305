import http from '../http-common'

class RostersService {
  getRosters = () => (
    http.get('/rosters/me')
  )

  renameRoster = data => (
    http.put('/rosters', data)
  )
}

export default new RostersService()
