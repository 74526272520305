import React from 'react'
import { Button, Input } from 'antd-mobile'
import { useIntl, FormattedMessage } from 'react-intl'
import { trimWhiteSpaces } from '../../utils/validation/strings'
import './Login.css'

const LoginForm = props => {

  const {
    setUserEmail,
    setPassword,
    handleLogin,
    isLoading,
    password,
    formErrorState,
  } = props

  const intl = useIntl()

  return (
    <>
      <div className={`input-group ${formErrorState ? 'input-group-error' : ''}`}>
        <label htmlFor='email'>
          <FormattedMessage id='LoginFormEmailField' defaultMessage='Email address' />
        </label>
        <Input
          id='email'
          type='email'
          placeholder={intl.formatMessage({ id: 'LoginFormEmailPlaceholder', defaultMessage: 'name@example.com' })}
          onChange={value => setUserEmail(value)}
        />
      </div>
      <div className={`input-group ${formErrorState ? 'input-group-error' : ''}`}>
        <label htmlFor='password'>
          <FormattedMessage id='LoginFormPasswordField' defaultMessage='Password' />
        </label>
        <Input
          id='password'
          type='password'
          placeholder={intl.formatMessage({ id: 'LoginFormPasswordPlaceholder', defaultMessage: 'Password' })}
          onChange={value => setPassword(trimWhiteSpaces(value))}
        />
      </div>
      <Button
        className='button-primary button-login'
        block
        loading={isLoading}
        disabled={password === ''}
        onClick={() => handleLogin()}
      >
        <FormattedMessage id='LoginFormPasswordButton' defaultMessage='Login' />
      </Button>
    </>
  )
}

export default LoginForm
