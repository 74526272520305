import React from 'react'
// import './Message.css'

const Message = props => {

  const {
    message,
    msgType = null,
    className = '',
  } = props

  const getClassName = () => {
    const classes = [className]

    if (msgType === 'support') {
      classes.push('form-supporting-text')
    } else if (msgType === 'error') {
      classes.push('input-group-error', 'text-color-error')
    }

    return classes.join(' ').trim()
  }

  return (
    <p className={getClassName()}>{message}</p>
  )
}

export default Message
