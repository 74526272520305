import React, { useState } from 'react'
import { useNavigate, useLocation, Link } from 'react-router-dom'
import { useDispatch, connect } from 'react-redux'
import { useIntl, FormattedMessage } from 'react-intl'
import { Button, Input, Checkbox, Toast } from 'antd-mobile'
import { setUser } from '../../redux/actions/userAction'
import UserService from '../../services/UserService'
import SEO from '../SEO'
import SocialMediaButtons from '../SocialMediaAuthButtons'
import './CreateAccount.css'

function CreateAccount() {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const intl = useIntl()

  const userLanguage = localStorage.getItem('appLocale')
  || (() => {
    const browserLanguage = navigator.language.split(/[-_]/)[0].toLowerCase()
    if (browserLanguage === 'pt') {
      return 'pt'
    }
    if (browserLanguage === 'es') {
      return 'es'
    }
    return 'en'
  })()

  const [formData, setFormData] = useState({
    username: '',
    email: '',
    password: '',
    isCommsActive: true,
  })

  const [error, setError] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [isLoading, setIsLoading] = useState(false)

  const handleChange = (value, fieldName) => {
    setFormData({ ...formData, [fieldName]: value })
  }

  const handleSubmit = async () => {
    setIsLoading(true)
    try {
      const referralCode = localStorage.getItem('referralCode')
      const response = await UserService.newAccount({
        email: formData.email,
        username: formData.username,
        password: formData.password,
        isCommsActive: formData.isCommsActive,
        language: userLanguage,
        referralCode,
      })

      if (!response.data.user) {
        Toast.show({
          icon: 'fail',
          content: 'Internal Server Error, try again.',
        })

        window.gtag('event', 'exception', {
          description: `Bad response while creating account: ${JSON.stringify(response?.data)}`,
          fatal: false,
        })
        return false
      }

      const { user } = response.data
      dispatch(setUser(user))

      window.gtag('event', 'sign_up', {
        event_category: 'User',
        event_label: 'Account Created',
        value: 1,
      })

      localStorage.removeItem('referralCode')

      navigate('/favourite-team')
    } catch (errors) {
      const simplifiedErrors = errors.response.data.errors.reduce((acc, vError) => {
        acc[vError.path] = vError.msg
        return acc
      }, {})

      setError(true)
      setErrorMessage(simplifiedErrors)

      window.gtag('event', 'exception', {
        description: `Creating account failed: ${JSON.stringify(simplifiedErrors)}`,
        fatal: false,
      })
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <>
      <SEO
        pageTitle={intl.formatMessage({ id: 'pageTitleCreateAccount', defaultMessage: 'Create your account' })}
        pageDescription={intl.formatMessage({ id: 'pageTitleCreateAccount', defaultMessage: 'Create your account' })}
      />
      <div className='main-container main-container--grid'>
        <div className='main-container__create-account'>
          <h2 className='align-center margin-top-16 margin-bottom-24'>
            <FormattedMessage id='createAccountTitle' defaultMessage='Create your account' />
          </h2>

          <SocialMediaButtons
            language={userLanguage}
            baseUrls={{
              google: `${process.env.REACT_APP_API_URL}/auth/google`,
              facebook: `${process.env.REACT_APP_API_URL}/auth/facebook`,
              twitch: `${process.env.REACT_APP_API_URL}/auth/twitch`,
            }}
          />

          <div className={`input-group ${error && errorMessage.email ? 'input-group-error' : ''}`}>
            <label htmlFor='email'>
              <FormattedMessage id='createAccountEmailField' defaultMessage='Email address' />
            </label>
            <Input
              id='email'
              name='email'
              type='email'
              placeholder={intl.formatMessage({ id: 'createAccountEmailPlaceholder', defaultMessage: 'name@example.com' })}
              onChange={value => handleChange(value, 'email')}
            />
            {
              error && errorMessage.email
              && (
                <p className='form-supporting-text'>
                  {errorMessage.email}
                </p>
              )
            }
          </div>
          <div className={`input-group ${error && errorMessage.username ? 'input-group-error' : ''}`}>
            <label htmlFor='username'>
              <FormattedMessage id='createAccountUsernameField' defaultMessage='Username' />
            </label>
            <Input
              id='username'
              name='username'
              type='text'
              placeholder={intl.formatMessage({ id: 'createAccountUsernamePlaceholder', defaultMessage: 'Username' })}
              onChange={value => handleChange(value, 'username')}
            />
            <p className='form-supporting-text'>
              { error && errorMessage.username ? errorMessage.username : (
                <FormattedMessage
                  id='createAccountNoteWarning'
                  defaultMessage='Note: Your username will be public and visible to others.'
                />
              )}
            </p>
          </div>
          <div className={`input-group ${error && errorMessage.password ? 'input-group-error' : ''}`}>
            <label htmlFor='password'>
              <FormattedMessage id='createAccountPasswordField' defaultMessage='Password' />
            </label>
            <Input
              id='password'
              name='password'
              type='password'
              placeholder={intl.formatMessage({ id: 'createAccountPasswordPlaceholder', defaultMessage: 'Password' })}
              onChange={value => handleChange(value, 'password')}
            />
            <p className='form-supporting-text'>
              { error && errorMessage.password ? errorMessage.password : (
                <FormattedMessage
                  id='createAccountPasswordRule'
                  defaultMessage='Minimum 8 characters including letters and numbers.'
                />
              )}
            </p>
          </div>

          <div className='input-group'>
            <Checkbox
              defaultChecked
              onChange={value => handleChange(value, 'isCommsActive')}
            >
              <FormattedMessage
                id='createAccountPromotionalAdCheckmark'
                defaultMessage='I want to receive promotional content from ClutchKing.'
              />
            </Checkbox>
          </div>

          <p className='terms-and-conditions'>
            <FormattedMessage
              id='createAccountTermsOfServiceNote'
              defaultMessage='By creating an account, you agree to our'
            />
            &nbsp;
            <Link to='/terms-of-service'>
              <FormattedMessage
                id='createAccountTermsOfServiceLink'
                defaultMessage='Terms of Service'
              />
            </Link>
            &nbsp;
            <FormattedMessage
              id='createAccountPrivacyPolicyRead'
              defaultMessage=' and have read and understood the'
            />
           &nbsp;
            <Link to='/privacy-policy'>
              <FormattedMessage
                id='createAccountPrivacyPolicyLink'
                defaultMessage='Privacy Policy'
              />
            </Link>
          </p>
          <Button
            className='button-primary'
            block
            loading={isLoading}
            // disabled={!isPasswordValid}
            onClick={handleSubmit}
          >
            <FormattedMessage
              id='createAccountContinueBtn'
              defaultMessage='Continue'
            />

          </Button>
        </div>
      </div>
    </>
  )
}

export default connect(null)(CreateAccount)
