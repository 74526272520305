/* eslint-disable default-param-last */
import { initialState } from './initialState'

const userReducer = (state = initialState.user, action) => {
  switch (action.type) {
  case 'SET_USER':
    return {
      ...state,
      ...action.payload,
    }
    // return action.payload
  case 'LOGOUT_USER':
    return null
  default:
    return state
  }
}

export default userReducer
