import http from '../http-common'

class EventsService {
  getAllEvents = () => (
    http.get('/events')
  )

  getEventDetails = eventId => (
    http.get(`/events/${eventId}`)
  )

  getPlayersByEvent = (eventId, stageId = null) => {
    if (stageId !== null) {
      return http.get(`/events/${eventId}/players/${stageId}`)
    }
    return http.get(`/events/${eventId}/players`)
  }

  getPublicEventDetails = eventId => (
    http.get(`/events/${eventId}/public`)
  )

  getMyLastLineup = stageId => (
    http.get(`/rosters/lineup/me/stage/${stageId}`)
  )

  getMatches = eventId => (
    http.get(`/events/${eventId}/matches`)
  )

  updateLineUp = data => (
    http.post('rosters/lineup', data)
  )

  getRanking = eventId => (
    http.get(`/events/${eventId}/ranking`)
  )

  getTop3 = eventId => (
    http.get(`/events/${eventId}/top3`)
  )

  getAllLineupsByUser = (eventId, userId) => (
    http.get(`/events/${eventId}/user/${userId}`)
  )

  getLeagueRanking = (eventId, leagueId) => (
    http.get(`/events/${eventId}/league/${leagueId}`)
  )

}

export default new EventsService()
