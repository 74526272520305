import React, { useState, useEffect } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { FormattedMessage, useIntl } from 'react-intl'
import { CheckCircleIcon } from '@heroicons/react/24/outline'
import SEO from '../SEO'
import ChangePasswordForm from './ChangePasswordForm'
import Confirmation from '../confirmation/Confirmation'
import UserService from '../../services/UserService'

function ChangePassword() {
  const navigate = useNavigate()
  const queryParams = useParams()
  const intl = useIntl()
  const { token } = queryParams
  const [isPasswordReset, setIsPasswordReset] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [hasError, setHasError] = useState(false)
  const [errorMessage, setErrorMessage] = useState(null)
  const [isChangeConfirmed, setIsChangeConfirmed] = useState(false)
  const [navigationLabel, setNavigationLabel] = useState('Back to log in')
  const [formData, setFormData] = useState({
    curretPassword: '',
    newPassword: '',
    confirmPassword: '',
  })

  const handleChange = (value, fieldName) => {
    setFormData({ ...formData, [fieldName]: value })
  }

  const handleSubmit = async e => {
    setIsLoading(true)
    try {
      if (formData.newPassword !== formData.confirmPassword) {
        setHasError(true)
        setErrorMessage({ confirmPassword: 'Your passwords do not match.' })

        return
      }

      if (isPasswordReset) {
        const response = await UserService.resetPasswordConfirm({
          newPassword: formData.newPassword,
          resetToken: token,
        })
      } else {
        const response = await UserService.changePassword({
          currentPassword: formData.currentPassword,
          newPassword: formData.newPassword,
        })
        setNavigationLabel('Back to events')
      }

      setIsChangeConfirmed(true)
    } catch (error) {
      setHasError(true)
      const { data } = error.response
      if (data.errors && data.errors.length) {
        if (data.errors[0].path === 'currentPassword') {
          setErrorMessage({ [data.errors[0].path]: data.errors[0].msg })
        } else {
          setErrorMessage({ newPassword: data.errors[0].msg })
        }
      } else {
        setErrorMessage({ global: error.response.data.message })
      }
    } finally {
      setIsLoading(false)
    }
  }

  const handleNavigation = () => {
    if (isPasswordReset) {
      navigate('/login')
    } else {
      navigate('/events')
    }
  }

  useEffect(() => {
    const fetchTokenIfReset = () => {
      try {
        if (token) {
          setIsPasswordReset(true)
        }
      } catch (error) {
        setHasError(true)
        setErrorMessage(error.message)
      }
    }

    fetchTokenIfReset()
  }, [token])

  return (
    <>
      <SEO
        pageTitle={intl.formatMessage({ id: 'changePasswordTitle', defaultMessage: 'Change your password' })}
        pageDescription={intl.formatMessage({ id: 'changePasswordTitle', defaultMessage: 'Change your password' })}
      />
      <div className='main-container'>
        <h2 className='align-center'>
          <FormattedMessage id='changePasswordTitle' defaultMessage='Change your password' />
        </h2>

        { isChangeConfirmed
          ? (
            <Confirmation
              buttonLabel={navigationLabel}
              icon={CheckCircleIcon}
              isLoading={isLoading}
              handleAction={handleNavigation}
              componentFlag='changePassword'
            />
          )
          : (
            <ChangePasswordForm
              errorMessage={errorMessage}
              formData={formData}
              handleChange={handleChange}
              handleSubmit={handleSubmit}
              hasError={hasError}
              isLoading={isLoading}
              isPasswordReset={isPasswordReset}
            />
          )}
      </div>
    </>
  )
}

export default ChangePassword
