import React, { useState, useEffect } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { ActionSheet, Button, Skeleton, Toast } from 'antd-mobile'
import { TrashIcon } from '@heroicons/react/16/solid'
import { FormattedMessage, useIntl } from 'react-intl'
import { getDecodedText } from '../../utils/stringsGeneralHelper'
import { getDisplayRosterName } from '../../utils/userHelper'
import LeagueService from '../../services/LeagueService'
import SEO from '../SEO'
import './LeagueDetails.css'

const EXIT_OWN_LEAGUE_ERROR_MSG = 'You can\'t leave your own league'

const LeagueDetails = () => {
  const [league, setLeague] = useState([])
  const [loadingLeague, setLoadingLeague] = useState(true)
  const [leagueError, setLeagueError] = useState(null)
  const [isLeagueAdmin, setIsLeagueAdmin] = useState(null)
  const [isLeagueMember, setIsLeagueMember] = useState(false)
  const [pendingStatus, setPendingStatus] = useState(null)
  const [pendingRequests, setPendingRequests] = useState(null)
  const [buttonLoading, setButtonLoading] = useState(false)
  const [actionSheetVisible, setActionSheetVisible] = useState(null)
  const [selectedPlayerId, setSelectedPlayerId] = useState(null)
  const user = useSelector(state => state.user)

  const intl = useIntl()

  const navigate = useNavigate()
  const queryParams = useParams()
  const { leagueId } = queryParams

  const cantLeaveOwnLeagueErrorMessage = intl.formatMessage({
    id: 'ownLeagueErrorMsgToastContent',
    defaultMessage: EXIT_OWN_LEAGUE_ERROR_MSG,
  })

  const addMember = newMember => {
    setLeague(prevLeague => ({
      ...prevLeague,
      members: [...prevLeague.members, newMember],
      memberCount: prevLeague.memberCount + 1,
    }))
  }

  const removeMember = memberId => {
    setLeague(prevLeague => ({
      ...prevLeague,
      members: prevLeague.members.filter(member => member.id !== memberId),
      memberCount: prevLeague.memberCount - 1,
    }))
  }

  const removeRequest = requestId => {
    setPendingRequests(prevRequests => prevRequests.filter(request => request.id !== requestId))
  }

  const actions = [
    {
      text: intl.formatMessage({ id: 'leagueDetailsActionsRemove', defaultMessage: 'Remove' }),
      key: 'delete',
      danger: true,
      bold: true,
      onClick: async () => {
        try {
          const response = await LeagueService.removePlayer(leagueId, selectedPlayerId)
          if (response.status === 200) {
            removeMember(selectedPlayerId)
          } else {
            throw new Error(response.data)
          }
        } catch (error) {
          if (error.response?.data?.message === EXIT_OWN_LEAGUE_ERROR_MSG
              || error.status === 400) {
            Toast.show({
              icon: 'fail',
              content: cantLeaveOwnLeagueErrorMessage,
            })
          } else {
            Toast.show({
              icon: 'fail',
              content: error.response?.data?.message || error.message,
            })
          }
        } finally {
          setActionSheetVisible(false)
        }
      },
    },
  ]

  useEffect(() => {
    const fetchLeague = async () => {
      try {
        const response = await LeagueService.getLeagueDetails(leagueId)
        const { data, data: { league: leagueData } } = response
        setLeague(leagueData)
        setIsLeagueAdmin(leagueData.userId === user?.id)
        setIsLeagueMember(leagueData.members.some(member => member.id === user?.id))
        setPendingStatus(data.pendingStatus)
        setPendingRequests(data.pendingRequests)
      } catch (error) {
        setLeagueError('Error fetching leagues. Please try again later.')
      } finally {
        setLoadingLeague(false)
      }
    }
    fetchLeague()
  }, [leagueId, user.id])

  const getRelativeTime = date => {
    const now = new Date()
    const pastDate = new Date(date)
    const diff = (now - pastDate) / (1000 * 60 * 60 * 24)

    const rtf = new Intl.RelativeTimeFormat('en', { numeric: 'auto' })

    if (diff < 1) {
      return <FormattedMessage id='LeagueDetailsTodayDateFormat' defaultMessage='Today' />
    }

    return rtf.format(Math.round(diff), 'day')
  }

  const handleMainButtonOnClick = async action => {
    switch (action) {
    case 'edit':
      navigate(`/leagues/${leagueId}/edit`)
      break
    case 'leave': {
      setButtonLoading(true)
      try {
        const response = await LeagueService.leaveLeague(leagueId)
        const { data } = response.data

        if (response.status !== 200) {
          throw new Error(data.message)
        }

        setIsLeagueMember(false)
        removeMember(user.id)
      } catch (error) {
        Toast.show({
          icon: 'fail',
          content: error.message,
        })
      } finally {
        setButtonLoading(false)
      }
      break
    }
    case 'request':
      setButtonLoading(true)
      try {
        const response = await LeagueService.requestToJoin(leagueId)
        const { data } = response

        if (response.status !== 201) {
          throw new Error(data.message)
        }

        setPendingStatus(
          {
            id: data.id,
            type: 'request',
          },
        )
      } catch (error) {
        Toast.show({
          icon: 'fail',
          content: error.message,
        })
      } finally {
        setButtonLoading(false)
      }
      break
    case 'accept':
      setButtonLoading(true)
      try {
        const response = await LeagueService.accept(pendingStatus?.id)
        const { data } = response.data

        if (response.status !== 200) {
          throw new Error(data)
        }

        setIsLeagueMember(true)
        addMember({
          id: user.id,
          username: user.username,
          roster: [
            { name: user.rosterName },
          ],
        })
      } catch (error) {
        Toast.show({
          icon: 'fail',
          content: error.message,
        })
      } finally {
        setButtonLoading(false)
      }
      break
    case 'join':
      setButtonLoading(true)
      try {
        const response = await LeagueService.joinLeague(leagueId)
        const { data } = response.data

        if (response.status !== 201) {
          throw new Error(data.message)
        }

        setIsLeagueMember(true)
        addMember({
          id: user.id,
          username: user.username,
          roster: [
            { name: user.rosterName },
          ],
        })
      } catch (error) {
        Toast.show({
          icon: 'fail',
          content: error.message,
        })
      } finally {
        setButtonLoading(false)
      }
      break
    default:
      break
    }
    return action
  }

  const handleAcceptRequest = async (action, requestId, fromUser) => {
    try {
      const response = await LeagueService.accept({ requestId })
      const { data } = response.data

      if (response.status !== 200) {
        throw new Error(data)
      }

      removeRequest(requestId)
      addMember(fromUser)
    } catch (error) {
      Toast.show({
        icon: 'fail',
        content: error.message,
      })
    }
  }

  const handleRefuseRequest = async (action, requestId) => {
    try {
      const response = await LeagueService.refuse({ requestId })
      const { data } = response.data

      if (response.status !== 200) {
        throw new Error(data)
      }

      removeRequest(requestId)
    } catch (error) {
      Toast.show({
        icon: 'fail',
        content: error.message,
      })
    }
  }

  function renderMainButton() {
    const buttonConfig = (() => {
      if (isLeagueAdmin) {
        return {
          text: 'Edit league',
          action: 'edit',
          intLId: 'leagueDetailsEditBtn',
        }
      }
      if (isLeagueMember) {
        return {
          text: 'Leave league',
          action: 'leave',
          intLId: 'leagueDetailsLeaveBtn',
        }
      }
      if (pendingStatus?.type === 'invite') {
        return {
          text: 'Accept invite',
          action: 'accept',
          intLId: 'leagueDetailsAcceptBtn',
        }
      }
      if (pendingStatus?.type === 'request') {
        return {
          text: 'Requested to join',
          action: null,
          intLId: 'leagueDetailsRequestedToJoinBtn',
        }
      }
      if (league.isPrivate) {
        return {
          text: 'Ask to join league',
          action: 'request',
          intLId: 'leagueDetailsPrivateAskToJoinBtn',
        }
      }
      return {
        text: 'Join league',
        action: 'join',
        intLId: 'leagueDetailsPublicJoinBtn',
      }
    })()

    return (
      <Button
        className='button-primary full-width margin-bottom-32'
        disabled={!buttonConfig.action}
        loading={buttonLoading}
        onClick={() => handleMainButtonOnClick(buttonConfig.action)}
      >
        <FormattedMessage id={buttonConfig.intLId} defaultMessage={buttonConfig.text} />
      </Button>
    )
  }

  const renderLeague = () => {
    if (loadingLeague) {
      return (
        <>
          <Skeleton animated style={{ height: '176px', marginBottom: '24px' }} />
          <Skeleton animated style={{ height: '48px', marginBottom: '24px' }} />
          <Skeleton animated style={{ height: '200px', marginBottom: '32px' }} />
        </>
      )
    }

    if (leagueError) {
      return <p>{leagueError}</p>
    }

    const getSingularOrPluralIntlL = () => (
      league.memberCount > 1
        ? { intLId: 'leagueDetailsMemberTextPlural', defaultMsg: 'Members' }
        : { intLId: 'leagueDetailsMemberTextSingular', defaultMsg: 'Member' }
    )

    const memberTxt = getSingularOrPluralIntlL()

    return (
      <div className='grid-col-1-6 col-span-4'>
        <h3 className='margin-bottom-4 margin-top-8'>
          {league.name}
        </h3>
        <p className='color-white-80'>
          {league.description}
        </p>
        <p className='margin-bottom-16 color-white-80 text-small'>
          <span>
            {getDecodedText(league.owner.username)}
          </span>
        </p>
        <div className='league-stats'>
          <div className='league-stats__column'>
            <h4>{league.memberCount}</h4>
            <p className='color-white-80 text-small'>
              <FormattedMessage id={memberTxt.intLId} defaultMessage={memberTxt.defaultMsg} />
            </p>
          </div>
          <div className='league-stats__column'>
            <h4>
              { league.isPrivate
                ? <FormattedMessage id='leaguesPagePrivate' defaultMessage='Private' />
                : <FormattedMessage id='leaguesPagePublic' defaultMessage='Public' /> }
            </h4>
            <p className='color-white-80 text-small'>
              <FormattedMessage id='leagueDetailsStatusText' defaultMessage='Status' />
            </p>
          </div>
          <div className='league-stats__column'>
            <h4>
              {(() => {
                switch (league.rankingCriteria) {
                case 'points':
                  return <FormattedMessage id='createLeaguePointsLabel' defaultMessage='Points' />
                case 'kills':
                  return <FormattedMessage id='leagueDetailsKills' defaultMessage='Kills' />
                case 'deaths':
                  return <FormattedMessage id='leagueDetailsDeaths' defaultMessage='Deaths' />
                default:
                  return null
                }
              })()}
            </h4>
            <p className='color-white-80 text-small'>
              { league.winningCondition === 'best'
                ? <FormattedMessage id='createLeagueBestTeamWinsLabel' defaultMessage='Best team wins' />
                : <FormattedMessage id='createLeagueWorstTeamWinsLabel' defaultMessage='Worst team wins' /> }
            </p>
          </div>
        </div>

        { renderMainButton() }
      </div>
    )
  }

  function renderPendingRequests() {
    if (!isLeagueAdmin || !pendingRequests || pendingRequests.length === 0) return

    return (
      <div className='leagues-list margin-bottom-32'>
        <h4 className='margin-bottom-4'>
          <FormattedMessage id='leagueDetailsPendingRequestText' defaultMessage='Pending Requests' />
        </h4>
        <p className='color-white-80 margin-bottom-24'>
          <FormattedMessage id='leagueDetailsPendingRequestsParagraph' defaultMessage='Players who want to join your league' />
        </p>
        { pendingRequests.map(pendingRequest => {
          const { id, user: fromUser } = pendingRequest
          return (
            <div key={id} className='leagues-list__request-card'>
              <div className='leagues-list__request-card__content'>
                <div>
                  <h6>{fromUser.username}</h6>
                  <p className='color-white-80'>{fromUser.roster[0].name}</p>
                </div>
                <p className='color-white-80 text-small'>
                  {getRelativeTime(pendingRequest.createdAt)}
                </p>
              </div>
              <div className='leagues-list__request-card__actions'>
                <Button
                  className='player-card__action__button'
                  onClick={() => handleAcceptRequest('accept', id, fromUser)}
                >
                  <FormattedMessage id='leagueDetailsAcceptText' defaultMessage='Accept' />
                </Button>
                <Button
                  className='player-card__action__button'
                  onClick={() => handleRefuseRequest('refuse', id, fromUser)}
                >
                  <FormattedMessage id='leagueDetailsRefuseText' defaultMessage='Refuse' />
                </Button>
              </div>
            </div>
          )
        })}
      </div>
    )
  }

  function renderMembers() {
    if (loadingLeague) {
      return
    }

    if (!league.members || league.members.length === 0) {
      return (
        <p>
          <FormattedMessage id='leagueDetailsNoMembers' defaultMessage='No members in this league' />
        </p>
      )
    }

    return (
      <>
        <h4 className='margin-bottom-16'>
          <FormattedMessage id='leagueDetailsMembersTitle' defaultMessage='Members' />
        </h4>
        <div className='members-list'>
          { league.members.map(member => {
            const { id, username, roster } = member
            return (
              <div key={id} className='members-list__card'>
                <div className='members-list__card__content'>
                  <h6>{getDisplayRosterName(roster[0].name, intl)}</h6>
                  <p className='color-white-80'>{getDecodedText(username)}</p>
                </div>
                { isLeagueAdmin ? (
                  <div className='members-list__card__action'>
                    <Button
                      className='player-card__action__icon-button'
                      onClick={() => {
                        setSelectedPlayerId(id)
                        setActionSheetVisible(true)
                      }}
                    >
                      <TrashIcon className='player-card__action__icon-button__icon' />
                    </Button>
                  </div>
                ) : null }
              </div>
            )
          })}
        </div>
      </>
    )
  }

  return (
    <>
      <SEO
        pageTitle={`${league.name} - ${intl.formatMessage({ id: 'navigationMenuBarLabelItemLeagues', defaultMessage: 'Leagues' })}`}
        pageDescription={`${league.name} - ${intl.formatMessage({ id: 'navigationMenuBarLabelItemLeagues', defaultMessage: 'Leagues' })}`}
      />
      <div className='main-container display-grid'>
        <div className='col-span-4 lg-col-span-5'>
          {renderLeague()}
          {renderPendingRequests()}
          {renderMembers()}
        </div>
      </div>
      <ActionSheet
        extra={<FormattedMessage id='leagueDetailsDeleteMemberMsg' defaultMessage='Are you sure you want to remove this player from your league?' />}
        cancelText={<FormattedMessage id='leagueDetailsCancelButton' defaultMessage='Cancel' />}
        visible={actionSheetVisible}
        actions={actions}
        onClose={() => setActionSheetVisible(false)}
        maskClassName='event-header-popup__mask'
      />
    </>
  )
}

export default LeagueDetails
