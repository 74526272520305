import React, { useState } from 'react'
import { Popup, SearchBar } from 'antd-mobile'
import { FormattedMessage, useIntl } from 'react-intl'
import PlayerCardSearch from './PlayerCardSearch'
import './PlayerSelectionPopup.css'

const PlayerSelectionPopup = props => {
  const {
    openPlayersListPopup,
    setOpenPlayersListPopup,
    playersByRole,
    roleName,
    updateNewPlayerBySlot,
    slot,
    balance,
  } = props

  const intl = useIntl()

  const [searchValue, setSearchValue] = useState('')
  const filteredPlayers = playersByRole.filter(
    p => p.name.toLowerCase().includes(searchValue.toLowerCase()),
  )

  const handleClick = (selectedPlayer, selectedSlot) => {
    setOpenPlayersListPopup(false)
    updateNewPlayerBySlot(selectedPlayer, selectedSlot)
  }

  return (
    <Popup
      bodyClassName='width-100 width-l-40'
      position='right'
      visible={openPlayersListPopup}
      showCloseButton
      closeOnMaskClick
      onClose={() => {
        setOpenPlayersListPopup(false)
      }}
    >
      <div
        className='player-selection-container'
        style={{ height: '100%', overflowY: 'scroll' }}
      >
        <h3 className='player-selection-container__title'>
          <FormattedMessage
            id='playerSelectionPopupTitle'
            defaultMessage={`Select your ${roleName}`}
            values={{ roleName }}
          />
        </h3>
        <SearchBar
          placeholder={intl.formatMessage({ id: 'playerSelectionPopupInputPlaceholder', defaultMessage: 'Player name' })}
          onChange={value => setSearchValue(value)}
        />

        {playersByRole.length ? filteredPlayers.map(player => (
          <div key={player.id}>
            <PlayerCardSearch
              player={player}
              balance={balance}
              action={handleClick}
              slot={slot}
            />
          </div>
        )) : null }
      </div>
    </Popup>
  )
}

export default PlayerSelectionPopup
