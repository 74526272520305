import React, { useEffect, useState } from 'react'
import { Toast, Skeleton } from 'antd-mobile'
import { useIntl, FormattedMessage } from 'react-intl'
import { getDecodedText } from '../../utils/stringsGeneralHelper'
import { getDisplayRosterName } from '../../utils/userHelper'
import EventsService from '../../services/EventsService'

const LeagueRanking = props => {
  const { event, league, setSelectedLeague, setLeftAction, setTitle } = props
  const [loading, setLoading] = useState(true)
  const [ranking, setRanking] = useState([])
  const intl = useIntl()

  useEffect(() => {
    setLeftAction(() => () => {
      setSelectedLeague(null)
      setTitle(null)
      setLeftAction(null)
    })

    setTitle(intl.formatMessage({
      id: 'eventHeaderPopupTitleBackToLeagues',
      defaultMessage: 'Back to leagues',
    }))

    const fetchLeagueRanking = async () => {
      try {
        const { data } = await EventsService.getLeagueRanking(event.id, league.id)
        setRanking(data)
      } catch (error) {
        Toast.show({
          icon: 'fail',
          content: error.response?.data?.message || error.message,
        })
      } finally {
        setLoading(false)
      }
    }

    fetchLeagueRanking()
  }, [event.id, league.id, setLeftAction, setTitle, setSelectedLeague, intl])

  function renderRanking() {
    if (loading) {
      return (
        <>
          <Skeleton animated style={{ height: '88px', marginBottom: '4px' }} />
          <Skeleton animated style={{ height: '88px', marginBottom: '4px' }} />
          <Skeleton animated style={{ height: '88px', marginBottom: '4px' }} />
          <Skeleton animated style={{ height: '88px' }} />
        </>
      )
    }

    return (
      <table className='ranking-table'>
        <thead className='ranking-table__header'>
          <tr>
            <th align='left'>#</th>
            <th align='left'>
              <FormattedMessage id='EventRankingTeamHeader' defaultMessage='Team' />
            </th>
            <th align='right'>
              {(() => {
                switch (league.rankingCriteria) {
                case 'points':
                  return <FormattedMessage id='EventRankingPointsHeader' defaultMessage='Points' />
                case 'kills':
                  return 'Kills'
                case 'deaths':
                  return 'Deaths'
                default:
                  return null
                }
              })()}
            </th>
          </tr>
        </thead>
        <tbody>
          {ranking.map(user => (
            <tr key={user.rosterId}>
              <td>{user.ranking}</td>
              <td>
                <p className='text-bold'>{getDisplayRosterName(user.rosterName, intl)}</p>
                <p className='color-gray-1'>{getDecodedText(user.username)}</p>
              </td>
              <td align='right'>{user.rankingMetric}</td>
            </tr>
          ))}
        </tbody>
      </table>
    )
  }

  return (
    renderRanking()
  )
}

export default LeagueRanking
