import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { connect, useDispatch } from 'react-redux'
import { Button, Input, Toast } from 'antd-mobile'
import { FormattedMessage, useIntl } from 'react-intl'
import { setUser } from '../../redux/actions/userAction'
import UserService from '../../services/UserService'
import SEO from '../SEO'
import Message from '../message/Message'
import './EditUsername.css'

const mapStateToProps = state => ({ user: state.user })

const EditUsername = ({ user }) => {
  const intl = useIntl()
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const [newUsername, setNewUsername] = useState(user.username)
  const [isLoading, setIsLoading] = useState(false)
  const [errorMsg, setErrorMsg] = useState('')
  const [isError, setIsError] = useState(false)

  const toastContent = intl.formatMessage({
    id: 'editUsernameToastContent',
    defaultMessage: 'Username saved',
  })

  const updateUsername = async () => {
    setIsLoading(true)
    try {
      const res = await UserService.updateUsername({ username: newUsername })

      if (res.status === 200) {
        const updatedUser = {
          ...user,
          username: newUsername,
        }

        dispatch(setUser(updatedUser))
        setErrorMsg('')
        setIsError(false)

        Toast.show({
          icon: 'success',
          content: toastContent,
        })

        setTimeout(() => {
          navigate('/events')
        }, 1000)
      }
    } catch ({ response }) {
      setIsError(true)
      setErrorMsg(response?.data?.message || response.message || 'Error, sorry, try again later')
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <div>
      <SEO
        pageTitle={intl.formatMessage({ id: 'menuSettingsEditUsernameItem', defaultMessage: 'Edit your username' })}
        pageDescription={intl.formatMessage({ id: 'menuSettingsEditUsernameItem', defaultMessage: 'Edit your username' })}
      />
      <div className='main-container'>
        <h3 className='margin-bottom-16'>
          <FormattedMessage id='editUsernameTitle' defaultMessage='Edit your username' />
        </h3>
        <div className={`input-group ${isError ? 'input-group-error' : ''}`}>
          <label htmlFor='username'>
            <FormattedMessage id='editUsernameField' defaultMessage='Username' />
          </label>
          <Input
            type='text'
            name='username'
            placeholder={intl.formatMessage({ id: 'editUsernameFieldPlaceholder', defaultMessage: 'My username' })}
            className='username-input'
            value={newUsername}
            onChange={value => setNewUsername(value)}
          />
          {!isError ? (
            <p className='form-supporting-text'>
              <FormattedMessage
                id='createAccountNoteWarning'
                defaultMessage='Note: Your username is public and visible to others.'
              />
            </p>
          ) : (
            <Message
              msgType='support'
              message={errorMsg}
            />
          )}
        </div>
        <Button
          className='button-primary'
          onClick={() => updateUsername()}
          block
          loading={isLoading}
          disabled={isLoading}
        >
          <FormattedMessage id='editUsernameButton' defaultMessage='Save username' />
        </Button>
      </div>
    </div>
  )
}

export default connect(mapStateToProps)(EditUsername)
