import React, { useState, useEffect } from 'react'
import { Skeleton } from 'antd-mobile'
import { FormattedMessage } from 'react-intl'
import dateUtils from '../../utils/dateUtils'
import UserService from '../../services/UserService'
import './ReferralsPage.css'

const ReferralsPage = () => {
  const [referrals, setReferrals] = useState([])
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    const fetchReferrals = async () => {
      try {
        const response = await UserService.getReferrals()
        setReferrals(response.data)
      } catch (error) {
        console.error('Error fetching referrals:', error)
      } finally {
        setLoading(false)
      }
    }

    fetchReferrals()
  }, [])

  const renderReferrals = () => {
    if (loading) {
      return <Skeleton animated style={{ height: '300px', marginBottom: '24px' }} />
    }

    if (!referrals || referrals.length === 0) {
      return <p>No referrals yet</p>
    }

    return (
      <>
        <h3 className='margin-bottom-24 margin-top-24'>
          Your referrals
        </h3>
        <table className='referrals-table'>
          <thead>
            <tr>
              <th align='left'>Username</th>
              <th align='left'>Created with</th>
              <th align='left'>Created on</th>
            </tr>
          </thead>
          <tbody>
            {referrals.map(referral => (
              <tr key={referral.id}>
                <td>{referral.username}</td>
                <td>
                  {referral.authMethod}
                  {referral.authMethod === 'Email' && referral.isEmailConfirmed ? ' (Confirmed)' : null }
                </td>
                <td>{dateUtils.formatDate(referral.createdAt)}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </>
    )
  }

  return (
    <div className='main-container'>
      {renderReferrals()}
    </div>
  )
}

export default ReferralsPage
