import React, { useState, useEffect, useCallback } from 'react'
import { Button, Checkbox, Form, Toast } from 'antd-mobile'
import { FormattedMessage, useIntl } from 'react-intl'
import UserService from '../../services/UserService'
import SEO from '../SEO'
import './EmailSettings.css'

const EmailSettings = () => {
  const intl = useIntl()
  const [form] = Form.useForm()
  const [isButtonLoading, setIsButtonLoading] = useState(false)
  const [isInitialDataLoading, setIsInitialDataLoading] = useState(true)

  const toastContent = intl.formatMessage({
    id: 'emailSettingsToastContent',
    defaultMessage: 'Preferences saved',
  })

  const handleEmailSettingsUpdate = async () => {
    try {
      setIsButtonLoading(true)
      const values = form.getFieldsValue()

      const response = await UserService.updateEmailPreferences(values)
      if (response.status === 200) {
        Toast.show({
          icon: 'success',
          content: toastContent,
        })
      } else {
        throw new Error('Failed to update email preferences')
      }
    } catch (error) {
      // to do handle error
    } finally {
      setIsButtonLoading(false)
    }
  }

  const loadEmailPreferences = useCallback(async () => {
    try {
      const response = await UserService.getEmailPreferences()
      if (response.status !== 200) {
        throw new Error(response.data.message)
      }

      const { user } = response.data
      form.setFieldsValue(user)

      setIsInitialDataLoading(false)
    } catch (error) {
      // to do handle error
    }
  }, [form])

  useEffect(() => {
    loadEmailPreferences()
  }, [loadEmailPreferences])

  const emailOptions = [
    {
      name: 'isSubscribedToNewEvents',
      intLIdEmailOption: 'emailSettingsCheckboxItem1',
      defaultIntLText: 'A new event is available to play',
      index: 1,
    },
    {
      name: 'isSubscribedToNewEventStages',
      intLIdEmailOption: 'emailSettingsCheckboxItem2',
      defaultIntLText: 'The market opens for transfers',
      index: 2,
    },
    {
      name: 'isSubscribedToEventResults',
      intLIdEmailOption: 'emailSettingsCheckboxItem3',
      defaultIntLText: 'An event you are playing ends',
      index: 3,
    },
    {
      name: 'isCommsActive',
      intLIdEmailOption: 'emailSettingsCheckboxItem4',
      defaultIntLText: 'We have a new Marketing message',
      index: 4,
    },
  ]

  return (
    <>
      <SEO
        pageTitle={intl.formatMessage({ id: 'emailSettingsTitle', defaultMessage: 'Email Settings' })}
        pageDescription={intl.formatMessage({ id: 'emailSettingsTitle', defaultMessage: 'Email Settings' })}
      />
      {!isInitialDataLoading && (
        <div className='main-container'>
          <div className='email-settings'>
            <h3 className='email-settings__title margin-bottom-16'>
              <FormattedMessage id='emailSettingsTitle' defaultMessage='Email Settings' />
            </h3>
            <p className='email-settings__description'>
              <FormattedMessage id='emailSettingsIntro' defaultMessage='We will send you an email every time:' />
            </p>
            <Form
              form={form}
              footer={(
                <Button
                  className='button-primary'
                  onClick={handleEmailSettingsUpdate}
                  block
                  loading={isButtonLoading}
                >
                  <FormattedMessage
                    id='emailSettingsSaveButton'
                    defaultMessage='Save preferences'
                  />
                </Button>
              )}
            >
              <div className='email-settings__list'>
                {emailOptions.map(option => (
                  <div key={option.index} className='email-settings__list__item'>
                    <p className='email-settings__list__item__label'>
                      <FormattedMessage
                        id={option.intLIdEmailOption}
                        defaultMessage={option.defaultIntLText}
                      />
                    </p>
                    <Form.Item name={option.name} valuePropName='checked'>
                      <Checkbox className='email-settings__list__item__switch' />
                    </Form.Item>
                  </div>
                ))}
              </div>
            </Form>
          </div>
        </div>
      )}
    </>
  )
}

export default EmailSettings
