/* eslint-disable no-param-reassign */
import axios from 'axios'
import store from './redux/store'
import { setUser, logoutUser } from './redux/actions/userAction'

// initialize axios with url and headers
const instance = axios.create({
  baseURL: process.env.NODE_ENV === 'development' ? 'http://localhost:4000/api' : 'https://api.clutchking.gg',
  headers: { 'Content-type': 'application/json' },
  timeout: 5000,
})

const addAuthToken = config => {
  const { user } = store.getState()

  if (user && user.accessToken) {
    config.headers.Authorization = `Bearer ${user.accessToken}`
  } else {
    store.dispatch(logoutUser())
  }

  return config
}

instance.interceptors.request.use(addAuthToken)

instance.interceptors.response.use(
  response => response,
  async error => {
    const originalRequest = error.config
    const { user } = store.getState()

    if (error.response && error.response.status === 403 && !originalRequest._retry) {
      originalRequest._retry = true

      if (user && user.refreshToken) {
        try {
          const response = await instance.post('/users/refresh-token', { refreshToken: user.refreshToken })
          const { accessToken, refreshToken } = response.data

          const updatedUser = {
            ...user,
            accessToken,
            refreshToken,
          }

          store.dispatch(setUser(updatedUser))

          instance.defaults.headers.common.Authorization = `Bearer ${accessToken}`
          originalRequest.headers.Authorization = `Bearer ${accessToken}`

          // Retry the original request with the new token
          return instance(originalRequest)
        } catch (refreshError) {
          store.dispatch(logoutUser())
          window.location.href = '/login'
          return Promise.reject(refreshError)
        }
      } else {
        store.dispatch(logoutUser())
      }
    } else {
      return Promise.reject(error)
    }
  },
)

export default instance
