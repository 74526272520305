import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { logoutUser } from '../../redux/actions/userAction'

const Logout = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  useEffect(() => {
    const handleLogout = async () => {
      await dispatch(logoutUser())
      navigate('/')
    }

    handleLogout()
  }, [dispatch, navigate])

  return null
}

export default Logout
