import { useLocation } from 'react-router-dom'
import { useEffect, useRef } from 'react'

const usePreviousLocation = () => {
  const location = useLocation()
  const previousLocationRef = useRef()

  useEffect(() => {
    if (location.state && location.state.from) {
      const { from } = location.state
      previousLocationRef.current = from
    }
  }, [location])

  return previousLocationRef.current
}

export default usePreviousLocation
