import EventsService from '../../services/EventsService'

export const fetchEventsRequest = () => ({ type: 'FETCH_EVENTS_REQUEST' })

export const fetchEventsSuccess = events => ({
  type: 'FETCH_EVENTS_SUCCESS',
  payload: events,
})

export const fetchEventsFailure = error => ({
  type: 'FETCH_EVENTS_FAILURE',
  payload: error,
})

export const fetchEvents = () => async dispatch => {
  dispatch(fetchEventsRequest())
  try {
    const response = await EventsService.getAllEvents()
    dispatch(fetchEventsSuccess(response.data))
  } catch (error) {
    dispatch(fetchEventsFailure(error.message))
  }
}
