import React, { useState, useEffect } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { connect } from 'react-redux'
import { Button, Popup } from 'antd-mobile'
import { FormattedMessage } from 'react-intl'
import {
  Bars3Icon,
  BellIcon,
  HomeIcon,
  ArrowRightEndOnRectangleIcon,
  UserPlusIcon,
  LanguageIcon,
  TrophyIcon,
  AdjustmentsHorizontalIcon,
  ArrowLeftEndOnRectangleIcon,
  UserGroupIcon,
  MegaphoneIcon,
} from '@heroicons/react/24/outline'
import Logo from './Logo'
import Notifications from './Notifications'
import NotificationService from '../services/NotificationService'
import { useLanguage } from '../LanguageContext'
import './NavigationMenuBar.css'

const mapStateToProps = state => ({ user: state.user })

const NavigationMenuBar = props => {
  const { user } = props
  const {
    className = '',
    isLogoVisible = true,
  } = props
  const location = useLocation()
  const { pathname: currentPath } = location
  const isAuthenticated = user?.isAuthenticated

  const loggedOutMenuItems = [
    {
      type: 'link',
      icon: HomeIcon,
      localeLabelId: 'navigationMenuBarLabelItemHome',
      defaultLabelMessage: 'Home',
      path: '/',
    },
    {
      type: 'link',
      icon: TrophyIcon,
      localeLabelId: 'navigationMenuBarLabelItemEvents',
      defaultLabelMessage: 'Events',
      path: '/events',
    },
    {
      type: 'link',
      icon: ArrowRightEndOnRectangleIcon,
      localeLabelId: 'navigationMenuBarLabelItemLogin',
      defaultLabelMessage: 'Login',
      path: '/login',
    },
    {
      type: 'link',
      icon: UserPlusIcon,
      localeLabelId: 'navigationMenuBarLabelItemNewAccount',
      defaultLabelMessage: 'Create account',
      path: '/create-account',
    },
    {
      type: 'text',
      icon: LanguageIcon,
      localeLabelId: 'footerLanguage',
      defaultLabelMessage: 'Language',
      children: [
        {
          type: 'link',
          icon: null,
          localeLabelId: 'navigationMenuBarLabelItemEnglish',
          defaultLabelMessage: 'English',
          path: '/language/en',
          currentPath,
        },
        {
          type: 'link',
          icon: null,
          localeLabelId: 'navigationMenuBarLabelItemPortugues',
          defaultLabelMessage: 'Português',
          path: '/language/pt',
          currentPath,
        },
        {
          type: 'link',
          icon: null,
          localeLabelId: 'navigationMenuBarLabelItemEspanol',
          defaultLabelMessage: 'Español',
          path: '/language/es',
          currentPath,
        },
      ],
    },
  ]

  const loggedInMenuItems = [
    {
      type: 'link',
      icon: MegaphoneIcon,
      localeLabelId: 'navigationMenuBarLabelItemAnnouncements',
      defaultLabelMessage: 'Announcements',
      path: '/announcements',
    },
    {
      type: 'link',
      icon: TrophyIcon,
      localeLabelId: 'navigationMenuBarLabelItemEvents',
      defaultLabelMessage: 'Events',
      path: '/events',
    },
    {
      type: 'link',
      icon: UserGroupIcon,
      localeLabelId: 'navigationMenuBarLabelItemLeagues',
      defaultLabelMessage: 'Leagues',
      path: '/leagues',
    },
    {
      type: 'link',
      icon: AdjustmentsHorizontalIcon,
      localeLabelId: 'navigationMenuBarLabelItemSettings',
      defaultLabelMessage: 'Settings',
      path: '/settings',
    },
    {
      type: 'text',
      icon: LanguageIcon,
      localeLabelId: 'footerLanguage',
      defaultLabelMessage: 'Create account',
      children: [
        {
          type: 'link',
          icon: null,
          localeLabelId: 'navigationMenuBarLabelItemEnglish',
          defaultLabelMessage: 'English',
          path: '/language/en',
          currentPath,
        },
        {
          type: 'link',
          icon: null,
          localeLabelId: 'navigationMenuBarLabelItemPortugues',
          defaultLabelMessage: 'Português',
          path: '/language/pt',
          currentPath,
        },
        {
          type: 'link',
          icon: null,
          localeLabelId: 'navigationMenuBarLabelItemEspanol',
          defaultLabelMessage: 'Español',
          path: '/language/es',
          currentPath,
        },
      ],
    },
    {
      type: 'spacer',
      icon: ArrowLeftEndOnRectangleIcon,
      localeLabelId: 'navigationMenuBarLabelItemSeparator',
      defaultLabelMessage: '',
      path: '/',
    },
    {
      type: 'link',
      icon: ArrowLeftEndOnRectangleIcon,
      localeLabelId: 'navigationMenuBarLabelItemLogout',
      defaultLabelMessage: 'Logout',
      path: '/logout',
    },
  ]

  const menuItems = isAuthenticated ? loggedInMenuItems : loggedOutMenuItems

  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const [isNotificationsOpen, setIsNotificationsOpen] = useState(false)
  const [notifications, setNotifications] = useState([])
  const [isLoadingNotifications, setIsLoadingNotifications] = useState(true)
  const [hasUnreadNotifications, setHasUnreadNotifications] = useState(false)
  const { locale } = useLanguage()

  const checkUnreadNotifications = allNotifications => {
    const lastChecked = new Date(localStorage.getItem('lastChecked') || 0).toISOString()
    const hasUnread = allNotifications.some(
      notification => new Date(notification.createdAt).toISOString() > lastChecked,
    )
    setHasUnreadNotifications(hasUnread)
  }

  const handleNotificationsOpen = () => {
    setIsNotificationsOpen(true)
    localStorage.setItem('lastChecked', new Date().toISOString())
    setHasUnreadNotifications(false)
  }

  useEffect(() => {
    if (!user?.id) return

    const fetchNotifications = async () => {
      try {
        const response = await NotificationService.getNotifications(user?.id)
        setNotifications(response.data)
        checkUnreadNotifications(response.data)
      } catch (error) {
        console.error('Error fetching notifications:', error)
      } finally {
        setIsLoadingNotifications(false)
      }
    }

    const timeout = setTimeout(fetchNotifications, 5000)
    const interval = setInterval(fetchNotifications, 300000)

    return () => {
      clearTimeout(timeout)
      clearInterval(interval)
    }
  }, [user, user?.id])

  useEffect(() => {
    setIsMenuOpen(false)
    setIsNotificationsOpen(false)
  }, [currentPath])

  const handleMenu = () => {
    setIsMenuOpen(true)
  }

  function createMenuItem(item) {
    const itemClassName = item.icon
      ? 'main-menu__menu-list__menu-item'
      : 'main-menu__menu-list__menu-item main-menu__menu-list__submenu-item'

    if (item.type === 'link') {
      const state = item.currentPath ? { previousPath: item.currentPath } : undefined
      return (
        <div key={item.localeLabelId} className={item.className}>
          <Link
            to={item.path}
            className={itemClassName}
            state={state}
          >
            { item.icon
              ? <item.icon className='main-menu__menu-list__menu-item__icon' />
              : null }
            <h6 className='main-menu__menu-list__menu-item__label'>
              <FormattedMessage
                id={item.localeLabelId}
                defaultMessage={item.defaultLabelMessage}
              />
            </h6>
          </Link>
        </div>
      )
    }

    if (item.type === 'text') {
      return (
        <div key={item.localeLabelId} className={item.className}>
          <div className={itemClassName}>
            <item.icon className='main-menu__menu-list__menu-item__icon' />
            <h6 className='main-menu__menu-list__menu-item__label'>
              <FormattedMessage
                id={item.localeLabelId}
                defaultMessage={item.defaultLabelMessage}
              />
            </h6>
          </div>
          {item.children.map(child => createMenuItem(child))}
        </div>
      )
    }

    return (<div key={item.localeLabelId} className='flex-grow-1' />)
  }

  return (
    <>
      <div className={`navigation ${className}`}>
        { isLogoVisible ? (
          <Link to={user ? '/events' : '/'}>
            <Logo className='navigation__header-logo' />
          </Link>
        ) : null }

        <div className='navigation__actions'>
          { isAuthenticated ? (
            <Button
              className={`navigation__button ${hasUnreadNotifications ? 'notification-active' : ''}`}
              onClick={handleNotificationsOpen}
            >
              <BellIcon />
            </Button>
          ) : null }

          <Button
            className='navigation__button'
            onClick={handleMenu}
          >
            <Bars3Icon />
          </Button>
        </div>
      </div>

      <Popup
        bodyClassName='main-menu main-menu__scroll width-100 width-l-40 xg-max-width-25'
        position='right'
        visible={isNotificationsOpen}
        showCloseButton
        closeOnMaskClick
        onClose={() => {
          setIsNotificationsOpen(false)
        }}
      >
        <Notifications
          notifications={notifications}
          loadingNotifications={isLoadingNotifications}
          setIsNotificationsOpen={setIsNotificationsOpen}
          locale={locale}
        />
      </Popup>

      <Popup
        bodyClassName='main-menu width-100 width-l-40 xg-max-width-25'
        position='right'
        visible={isMenuOpen}
        showCloseButton
        closeOnMaskClick
        onClose={() => {
          setIsMenuOpen(false)
        }}
      >
        <div
          style={{ overflowY: 'auto', height: '100%' }}
        >
          <div className='main-menu__menu-list'>
            {menuItems.map(item => (
              createMenuItem(item)
            ))}
          </div>
        </div>
      </Popup>
    </>
  )
}

export default connect(mapStateToProps)(NavigationMenuBar)
