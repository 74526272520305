import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { Image, Skeleton } from 'antd-mobile'
import { FormattedMessage } from 'react-intl'
import MatchService from '../../services/MatchService'
import './MatchCard.css'

const getRelativeDayOrDayMonth = (
  differenceInDays,
  formattedDayMonth,
  matchDateTime,
  currentDateTime,
) => {
  const isUpcomingMatch = matchDateTime >= currentDateTime

  if (differenceInDays === 0 && isUpcomingMatch) {
    return <FormattedMessage id='matchCardFormattedDate_today' defaultMessage='today' />
  }

  if (differenceInDays === 1 && isUpcomingMatch) {
    return <FormattedMessage id='matchCardFormattedDate_tomorrow' defaultMessage='tomorrow' />
  }

  if (differenceInDays > 1 && differenceInDays <= 5 && isUpcomingMatch) {
    return (
      <FormattedMessage
        id='matchCardFormattedDate_in_x_days'
        defaultMessage={`in ${differenceInDays} days`}
        values={{ daysNumber: differenceInDays }}
      />
    )
  }

  return formattedDayMonth
}

const mapStateToProps = state => ({ user: state.user })

const MatchCard = props => {
  const { user } = props
  const { match } = props

  const [isExpanded, setIsExpanded] = useState(false)
  const [isLoadingAdditionalData, setIsLoadingAdditionalData] = useState(true)
  const [additionalData, setAdditionalData] = useState(null)
  const locale = user?.language || navigator.language

  const formatDateAndTime = startDate => {
    const matchDateTime = new Date(startDate)
    const currentDateTime = new Date()

    const dayMonthFormatOptions = {
      day: '2-digit',
      month: 'short',
    }

    const timeFormatOptions = {
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
    }

    const dayMonthFormatter = new Intl.DateTimeFormat(locale, dayMonthFormatOptions)
    const timeFormatter = new Intl.DateTimeFormat(locale, timeFormatOptions)

    const formattedDayMonth = dayMonthFormatter.format(matchDateTime)
    const formattedTime = timeFormatter.format(matchDateTime)

    // calculate the difference in days by normalizing both dates to midnight
    const msPerDay = 1000 * 60 * 60 * 24
    const currentDateMidnight = new Date(
      currentDateTime.getFullYear(),
      currentDateTime.getMonth(),
      currentDateTime.getDate(),
    )

    const matchDateMidnight = new Date(
      matchDateTime.getFullYear(),
      matchDateTime.getMonth(),
      matchDateTime.getDate(),
    )

    const differenceInDays = Math.round((matchDateMidnight - currentDateMidnight) / msPerDay)

    const updatedFormattedDay = getRelativeDayOrDayMonth(
      differenceInDays,
      formattedDayMonth,
      matchDateTime,
      currentDateTime,
    )

    return {
      date: updatedFormattedDay,
      time: formattedTime,
      both: `${updatedFormattedDay} - ${formattedTime}`,
    }
  }

  const getStatusText = (status, startDate) => {
    if (status === 'ENDED') {
      return <FormattedMessage id='matchStatusEnded' defaultMessage='Ended' />
    }
    if (status === 'LIVE') {
      return <FormattedMessage id='matchStatusLive' defaultMessage='Live' />
    }
    return formatDateAndTime(startDate).time
  }

  const isMatchUpcoming = match.status === 'UPCOMING'

  const handleToggleExpand = () => {
    if (match.status === 'ENDED') {
      setIsExpanded(prevState => !prevState)
    }
  }

  const handleKeyDown = event => {
    if (event.key === 'Enter' || event.key === ' ') {
      event.preventDefault()
      handleToggleExpand()
    }
  }

  useEffect(() => {
    const fetchMatchMaps = async () => {
      try {
        if (isExpanded && !additionalData) {
          const response = await MatchService.getMatchMaps(match.id)

          if (response.status !== 200) {
            throw new Error('Failed to fetch match maps')
          }
          setAdditionalData(response.data)
          setIsLoadingAdditionalData(false)
        }
      } catch (error) {
        console.error('Error fetching match maps:', error)
        setIsLoadingAdditionalData(false)
      }
    }

    fetchMatchMaps()
  }, [isExpanded, match.id, match, additionalData])

  return (
    <div
      className={`match-card-container ${match.status === 'ENDED' ? 'expandable' : ''}`}
      role={match.status === 'ENDED' ? 'button' : 'presentation'}
      tabIndex={match.status === 'ENDED' ? 0 : -1}
      onClick={match.status === 'ENDED' ? handleToggleExpand : undefined}
      onKeyDown={match.status === 'ENDED' ? handleKeyDown : undefined}
    >
      <div className='match-card'>
        <div className='match-card__col-1'>
          <p className='no-wrap'>{formatDateAndTime(match.startDate).date}</p>
          <p className='no-wrap'>
            {getStatusText(match.status, match.startDate)}
          </p>
        </div>
        <div className='match-card__divider' />
        <div className='match-card__col-2'>
          <div
            className={`match-card__team-row ${
              match.teamAScore < match.teamBScore ? 'losing-team' : ''
            }`}
          >
            <div className='match-card__team-row__team'>
              <Image src={match.teamALogoPath} className='match-card__team__logo' />
              <p>{match.teamAName}</p>
            </div>
            <p>{isMatchUpcoming ? null : match.teamAScore}</p>
          </div>
          <div
            className={`match-card__team-row ${
              match.teamBScore < match.teamAScore ? 'losing-team' : ''
            }`}
          >
            <div className='match-card__team-row__team'>
              <Image src={match.teamBLogoPath} className='match-card__team__logo' />
              <p>{match.teamBName}</p>
            </div>
            <p>{isMatchUpcoming ? null : match.teamBScore}</p>
          </div>
        </div>
      </div>
      <div className={`match-card__expanded-content ${isExpanded ? 'expanded' : ''}`}>
        { isLoadingAdditionalData
          ? (
            <>
              <Skeleton animated style={{ height: '44px', marginBottom: '4px' }} />
              <Skeleton animated style={{ height: '44px', marginBottom: '4px' }} />
              <Skeleton animated style={{ height: '44px', marginBottom: '4px' }} />
            </>
          ) : (
            additionalData?.mapsSummary.map(map => {
              const backgroundImage = `url('https://cdnz.clutchking.gg/maps/${map.mapName.toLowerCase()}.webp')`
              return (
                <div key={map.id} className='match-card__map-summary'>
                  <div className='match-card__map-summary__map'>
                    <div className='match-card__map-summary__map__team'>
                      <Image className='match-card__map-summary__map__team__logo' src={additionalData.teamALogoPath} />
                      <span>{map.teamAScore}</span>
                    </div>
                    <div
                      className='match-card__map-summary__map__map-name'
                      style={{
                        backgroundImage: `
                          linear-gradient(
                            to right,
                            rgba(19, 20, 20, 1) 0%,
                            rgba(11, 14, 14, 0.75) 50%,
                            rgba(19, 20, 20, 1) 100%
                          ),
                          ${backgroundImage}
                        `,
                      }}
                    >
                      <p>{map.mapName}</p>
                    </div>
                    <div className='match-card__map-summary__map__team'>
                      <span>{map.teamBScore}</span>
                      <Image className='match-card__map-summary__map__team__logo' src={additionalData.teamBLogoPath} />
                    </div>
                  </div>
                </div>
              )
            })
          )}
      </div>
    </div>
  )
}

export default connect(mapStateToProps)(MatchCard)
