/* eslint-disable react/destructuring-assignment */
import React from 'react'
import { FormattedMessage } from 'react-intl'
import Countdown from '../Countdown'

const EventStatusText = ({ event }) => {
  switch (event.status) {
  case 'PUBLISHED':
    return <Countdown targetDate={event.startDate} />
  case 'MARKET OPEN':
    return <FormattedMessage id='eventStatusMarketOpen' defaultMessage='Market open' />
  case 'MARKET CLOSED':
    return <FormattedMessage id='eventStatusMarketClosed' defaultMessage='Live now' />
  case 'DRAFT':
    return <FormattedMessage id='eventStatusDraft' defaultMessage='Draft' />
  default:
    return <FormattedMessage id='eventStatusEnded' defaultMessage='Ended' />
  }
}

export default EventStatusText
