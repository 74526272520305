import React, { useState } from 'react'
import { Popup } from 'antd-mobile'
import { ChevronLeftIcon, XMarkIcon } from '@heroicons/react/20/solid'
import { useIntl } from 'react-intl'
import EventPrizes from './EventPrizes'
import EventRanking from './EventRanking'
import EventRules from './EventRules'
import MatchList from './MatchList'
import MyLeagues from './MyLeagues'
import SEO from '../SEO'
import PointsPerLineup from './PointsPerLineup'
import './EventHeaderPopup.css'

const EventHeader = props => {
  const { contentType, popupVisible, setPopupVisible, event, onClose } = props
  const intl = useIntl()
  const [leftAction, setLeftAction] = useState(null)
  const [title, setTitle] = useState(null)

  function getPopupContent() {
    if (contentType === 'prizes') {
      return (
        <>
          <SEO
            pageTitle={`${intl.formatMessage({ id: 'eventActionPrizes', defaultMessage: 'Prizes' })} - ${event.name}`}
            pageDescription={event.name}
          />
          <EventPrizes prizes={event.description} />
        </>
      )
    }

    if (contentType === 'rules') {
      return (
        <>
          <SEO
            pageTitle={`${intl.formatMessage({ id: 'eventActionRules', defaultMessage: 'Rules' })} - ${event.name}`}
            pageDescription={event.name}
          />
          <EventRules />
        </>
      )
    }

    if (contentType === 'matches') {
      return (
        <>
          <SEO
            pageTitle={`${intl.formatMessage({ id: 'eventActionMatches', defaultMessage: 'See matches' })} - ${event.name}`}
            pageDescription={event.name}
          />
          <MatchList event={event} />
        </>
      )
    }

    if (contentType === 'leagues') {
      return (
        <>
          <SEO
            pageTitle={`${intl.formatMessage({ id: 'eventActionLeagues', defaultMessage: 'Leagues' })} - ${event.name}`}
            pageDescription={event.name}
          />
          <MyLeagues
            event={event}
            setLeftAction={setLeftAction}
            setTitle={setTitle}
          />
        </>
      )
    }

    if (contentType === 'performance') {
      return (
        <>
          <SEO
            pageTitle={`${intl.formatMessage({ id: 'eventActionPerformance', defaultMessage: 'My performance' })} - ${event.name}`}
            pageDescription={event.name}
          />
          <PointsPerLineup
            event={event}
            popupVisible={popupVisible}
            setPopupVisible={setPopupVisible}
          />
        </>
      )
    }

    if (contentType === 'standings') {
      return (
        <>
          <SEO
            pageTitle={`${intl.formatMessage({ id: 'eventActionStandings', defaultMessage: 'Standings' })} - ${event.name}`}
            pageDescription={event.name}
          />
          <EventRanking
            event={event}
            setLeftAction={setLeftAction}
            setTitle={setTitle}
            popupVisible={popupVisible}
            setPopupVisible={setPopupVisible}
          />
        </>
      )
    }
  }

  return (
    <Popup
      visible={popupVisible}
      bodyClassName='event-header-popup'
      maskClassName='event-header-popup__mask'
      onClose={onClose}
      onMaskClick={() => {
        onClose()
        setTitle(null)
        setLeftAction(null)
      }}
    >
      <div className='event-header-popup__nav-bar'>
        {leftAction && (
          <button onClick={leftAction} className='event-header-popup__nav-bar__left' type='button'>
            <ChevronLeftIcon className='event-header-popup__nav-bar__icon' />
            {title && (
              <p>{title}</p>
            )}
          </button>
        )}
        <XMarkIcon
          onClick={() => {
            onClose()
            setTitle(null)
            setLeftAction(null)
          }}
          className='event-header-popup__nav-bar__icon'
        />
      </div>
      {getPopupContent()}
    </Popup>
  )
}

export default EventHeader
