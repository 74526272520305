import React from 'react'
import { Image } from 'antd-mobile'
import { FormattedMessage, useIntl } from 'react-intl'
import SEO from './SEO'
import './ContactInfo.css'

const SOCIAL_MEDIA_ITEMS = [
  {
    platform: 'x',
    url: 'https://x.com/ClutchKing_GG',
    intLId: 'contactInfoSocialMedia_x',
    altText: 'x logo',
    logoSrc: 'https://cdnz.clutchking.gg/misc/x-logo.svg',
    text: 'Follow us on X',
  },
  {
    platform: 'instagram',
    url: 'https://www.instagram.com/clutchking.gg/',
    intLId: 'contactInfoSocialMedia_instagram',
    altText: 'Instagram logo',
    logoSrc: 'https://cdnz.clutchking.gg/misc/instagram-logo.svg',
    text: 'Follow us on Instagram',
  },
  {
    platform: 'steam',
    url: 'https://steamcommunity.com/id/clutchking_gg/',
    intLId: 'contactInfoSteamPage',
    altText: 'Steam logo',
    logoSrc: 'https://cdnz.clutchking.gg/misc/steam-logo.svg',
    text: 'Our page on Steam',
  },
]

const ContactInfo = () => {
  const intl = useIntl()
  return (
    <>
      <SEO
        pageTitle={intl.formatMessage({ id: 'contactInfoMainTitle', defaultMessage: 'Contact' })}
        pageDescription={intl.formatMessage({ id: 'contactInfoMainTitle', defaultMessage: 'Contact' })}
      />
      <div className='main-container'>
        <h2 className='margin-bottom-12 margin-top-32'>
          <FormattedMessage id='contactInfoMainTitle' defaultMessage='Contact' />
        </h2>
        <p className='margin-bottom-32'>
          <FormattedMessage
            id='contactInfoSubtitle'
            defaultMessage='For any questions, please contact us at '
          />
          <a href='mailto:support@clutchking.com.br' className='contact-info-email-link text-bold'>
            support@clutchking.com.br
          </a>
        </p>
        <h3 className='margin-bottom-12'>
          <FormattedMessage
            id='contactInfoSocialMediaTitle'
            defaultMessage='On social media'
          />
        </h3>
        {SOCIAL_MEDIA_ITEMS.map(item => (
          <div key={item.platform} className='contact-info-social-media-container'>
            <a
              href={item.url}
              target='_blank'
              rel='noopener noreferrer'
              className='contact-info-link'
            >
              <div>
                <Image
                  src={item.logoSrc}
                  className='contact-info-icon-img'
                  alt={item.altText}
                />
              </div>
              <p>
                <FormattedMessage
                  id={item.intLId}
                  defaultMessage={item.text}
                />
              </p>
            </a>
          </div>
        ))}
      </div>
    </>
  )
}

export default ContactInfo
