import React, { useState, useEffect, useRef } from 'react'
import { useParams } from 'react-router-dom'
import { connect } from 'react-redux'
import { Button, Input, Form, Skeleton } from 'antd-mobile'
import { FormattedMessage, useIntl } from 'react-intl'
import { HeartIcon, ChatBubbleOvalLeftIcon } from '@heroicons/react/24/outline'
import { HeartIcon as HeartSolidIcon, PaperAirplaneIcon } from '@heroicons/react/24/solid'
import { useLanguage } from '../../LanguageContext'
import dateUtils from '../../utils/dateUtils'
import AnnouncementService from '../../services/AnnouncementService'
import SEO from '../SEO'
import './AnnouncementDetail.css'
import { getDecodedText } from '../../utils/stringsGeneralHelper'

const mapStateToProps = state => ({ user: state.user })

const AnnouncementDetail = props => {
  const { user } = props
  const { announcementSlug } = useParams()
  const intl = useIntl()
  const { locale } = useLanguage()
  const [form] = Form.useForm()
  const inputRef = useRef(null)
  const [announcement, setAnnouncement] = useState(null)
  const [replies, setReplies] = useState([])
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    const fetchAnnouncement = async () => {
      try {
        const response = await AnnouncementService.getAnnouncementById(announcementSlug)
        setAnnouncement(response.data)
        setReplies(response.data.replies)
        setLoading(false)
      } catch (error) {
        console.error('Error fetching announcement:', error)
        setLoading(false)
      }
    }

    fetchAnnouncement()
  }, [announcementSlug])

  const getTranslation = translations => {
    const translation = translations.find(t => t.language === locale)
    if (translation) {
      return translation
    }
    return translations.find(t => t.language === 'en') || {}
  }

  const handleReply = async values => {
    try {
      const response = await AnnouncementService.replyToAnnouncement(
        announcement.id,
        values,
      )

      const newReply = {
        ...response.data,
        likes: [],
        user: { username: user.username },
      }

      setReplies([...replies, newReply])
      form.resetFields()
    } catch (error) {
      console.error('Error replying to announcement:', error)
    }
  }

  const handleLike = async () => {
    try {
      const hasLikedAnnouncement = user && announcement
        ? announcement.likes?.some(like => like.userId === user.id)
        : null

      await AnnouncementService.likeAnnouncement(announcement.id)
      if (hasLikedAnnouncement) {
        setAnnouncement({
          ...announcement,
          likes: announcement.likes.filter(like => like.userId !== user.id),
        })
      } else {
        setAnnouncement({
          ...announcement,
          likes: [...announcement.likes, { userId: user.id }],
        })
      }
    } catch (error) {
      console.error('Error toggling like on announcement:', error)
    }
  }

  const handleReplyLike = async replyId => {
    try {
      const replyToLike = replies.find(r => r.id === replyId)
      const hasLikedReply = replyToLike.likes?.some(like => like.userId === user.id)
      await AnnouncementService.likeAnnouncementReply(replyId)
      if (hasLikedReply) {
        setReplies(
          replies.map(r => {
            if (r.id === replyId) {
              return { ...r, likes: r.likes.filter(like => like.userId !== user.id) }
            }
            return r
          }),
        )
      } else {
        setReplies(
          replies.map(r => {
            if (r.id === replyId) {
              return { ...r, likes: [...r.likes, { userId: user.id }] }
            }
            return r
          }),
        )
      }
    } catch (error) {
      console.error('Error toggling like on reply:', error)
    }
  }

  const handleCommentIcon = () => {
    inputRef.current?.focus()
  }

  const hasLikedAnnouncement = user && announcement
    ? announcement.likes?.some(like => like.userId === user.id)
    : null

  const renderAnnouncement = () => {
    if (loading) {
      return <Skeleton animated style={{ height: '200px', marginBottom: '24px' }} />
    }

    if (!announcement) {
      return <p>No announcement found</p>
    }

    const translation = getTranslation(announcement.translations)

    return (
      <>
        <SEO
          pageTitle={announcement?.translations.find(t => t.language === 'en')?.title}
          pageDescription={announcement?.translations.find(t => t.language === 'en')?.body}
        />
        <div className='announcement-detail display-grid'>
          <div className='col-span-4 lg-col-span-5'>
            <h3 className='margin-bottom-8'>{translation.title}</h3>
            <p className='text-small color-white-80 margin-bottom-8'>
              {dateUtils.formatDateAndTime(announcement.createdAt, locale).both}
            </p>
            <p>{translation.body}</p>
            <div className='announcement-detail__controls'>
              <Button onClick={handleLike} className='announcement-detail__controls__button'>
                { hasLikedAnnouncement
                  ? <HeartSolidIcon className='announcement-detail__controls__button__icon icon-liked' />
                  : <HeartIcon className='announcement-detail__controls__button__icon' /> }
                {announcement.likes.length}
              </Button>
              <Button onClick={handleCommentIcon} className='announcement-detail__controls__button'>
                <ChatBubbleOvalLeftIcon className='announcement-detail__controls__button__icon' />
                {announcement.replies.length}
              </Button>
            </div>
            <div className='replies-section col-span-4 lg-col-span-5'>
              <Form
                form={form}
                layout='horizontal'
                onFinish={handleReply}
                className='margin-bottom-32'
              >
                <Form.Item
                  name='body'
                  rules={[
                    { required: true, message: <FormattedMessage id='announcementsReplyRequired' defaultMessage='Type a comment before submitting' /> },
                    {
                      max: 500,
                      message: <FormattedMessage id='announcementsReplyMaxLength' defaultMessage='Comment should not exceed 500 characters' />,
                    },
                  ]}
                  className='full-width'
                >
                  <Input
                    ref={inputRef}
                    className='text-input-filled'
                    placeholder={intl.formatMessage({ id: 'announcementsReplyPlaceholder', defaultMessage: 'Type your comment' })}
                    maxLength={500}
                    autoComplete='off'
                  />
                </Form.Item>
                <Form.Item>
                  <Button type='primary' htmlType='submit' className='reply-button'>
                    <PaperAirplaneIcon className='reply-button__icon' />
                  </Button>
                </Form.Item>
              </Form>
            </div>
          </div>
          <div className='lg-col-span-1' />
          <div className='col-span-4 lg-col-span-5'>
            <h4 className='margin-bottom-16'>
              <FormattedMessage id='announcementsComments' defaultMessage='Comments' />
              &nbsp;(
              {replies.length}
              )
            </h4>
            <div className='replies-list'>
              {replies.map(iReply => {
                const hasLikedReply = user
                  ? iReply.likes?.some(like => like.userId === user.id)
                  : null
                return (
                  <div key={iReply.id} className='reply-card'>
                    <p>
                      <span className='text-bold'>
                        {getDecodedText(iReply.user?.username)}
                        :&nbsp;
                      </span>
                      {getDecodedText(iReply.body)}
                    </p>
                    <div className='reply-card__controls'>
                      <Button
                        onClick={() => handleReplyLike(iReply.id)}
                        className='reply-card__controls__button'
                      >
                        { hasLikedReply
                          ? <HeartSolidIcon className='reply-card__controls__button__icon icon-liked' />
                          : <HeartIcon className='reply-card__controls__button__icon' /> }
                        { iReply.likes?.length ? iReply.likes?.length : 0 }
                      </Button>
                    </div>
                  </div>
                )
              })}
            </div>
          </div>
        </div>
      </>
    )
  }

  return (
    <div className='main-container'>
      {renderAnnouncement()}
    </div>
  )
}

export default connect(mapStateToProps)(AnnouncementDetail)
