const useEventTracker = () => {
  const trackEvent = (category, label, value) => {
    window.gtag('event', 'click', {
      event_category: category,
      event_label: label,
      value,
    })
  }

  return { trackEvent }
}

export default useEventTracker
