import React from 'react'
import { Button } from 'antd-mobile'
import { FormattedMessage } from 'react-intl'
import './Confirmation.css'

const Confirmation = props => {

  const COMPONENT_TYPE_FLAGS = {
    resetPassword: {
      message: 'Check your email for a link to reset your password. The link is valid for 24hrs.',
      intLId: 'ResetPasswordConfirmationMessage',
      defaultMessage: 'Check your email for a link to reset your password. The link is valid for 24hrs.',
    },
    changePassword: {
      message: 'Your password has been updated.',
      intLId: 'changePasswordConfirmationMessage',
      defaultMessage: 'Your password has been updated.',
    },
    confirmEmail: {
      message: 'Your email address has been confirmed. You are ready to compete for prizes.',
      intLId: 'confirmEmailConfirmationMessage',
      defaultMessage: 'Your email address has been confirmed. You are ready to compete for prizes.',
    },
    confirmEmailError: {
      message: 'Invalid token, your email address could not be validated.',
      intLId: 'confirmEmailConfirmationMessageError',
      defaultMessage: 'Invalid token, your email address could not be validated',
    },
  }

  const NAVIGATION_LABELS = {
    'Back to login': {
      intLId: 'confirmationNavigationLabelLogin',
      defaultMessage: 'Back to login',
    },
    'Back to events': {
      intLId: 'confirmationNavigationLabelEvents',
      defaultMessage: 'Back to events',
    },
  }

  const {
    icon: Icon,
    title,
    componentFlag,
    buttonLabel,
    handleAction,
    isLoading,
  } = props

  const {
    intLId: componentFlagIntLId,
    defaultMessage: componentFlagDefaultMessage,
  } = COMPONENT_TYPE_FLAGS[componentFlag]

  const getBtnLabelId = () => (
    !NAVIGATION_LABELS[buttonLabel] ? 'confirmationFallBackBtn' : NAVIGATION_LABELS[buttonLabel].intLId
  )

  const getBtnLabelDefaultMsg = () => (
    !NAVIGATION_LABELS[buttonLabel] ? 'Back' : NAVIGATION_LABELS[buttonLabel].defaultMessage
  )

  return (
    <>
      <div className='confirmation-component'>
        <Icon className='confirmation-component__icon' />
        {title && <h3 className='confirmation-component__title'>{title}</h3>}
        <p>
          <FormattedMessage id={componentFlagIntLId} defaultMessage={componentFlagDefaultMessage} />
        </p>
      </div>
      <Button
        className='button-primary button-login'
        block
        loading={isLoading}
        onClick={handleAction}
      >
        <FormattedMessage id={getBtnLabelId()} defaultMessage={getBtnLabelDefaultMsg()} />
      </Button>
    </>
  )
}

export default Confirmation
