import React, { useState, useEffect } from 'react'
import { useNavigate, Link, useLocation } from 'react-router-dom'
import { useDispatch, connect } from 'react-redux'
import { Button, Image } from 'antd-mobile'
import { FormattedMessage, useIntl } from 'react-intl'
import { setUser } from '../../redux/actions/userAction'
import { fetchEvents } from '../../redux/actions/eventsAction'
import UserService from '../../services/UserService'
import usePreviousLocation from '../../hooks/usePreviousLocation'
import SEO from '../SEO'
import LoginForm from './LoginForm'
import Message from '../message/Message'
import RostersService from '../../services/RostersService'
import './Login.css'

const mapStateToProps = state => ({ events: state.events.events })

const mapDispatchToProps = dispatch => ({ setEvents: () => dispatch(fetchEvents()) })

const Login = ({ setEvents, events }) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const intl = useIntl()
  const location = useLocation()
  const previousLocation = usePreviousLocation()
  const [isLoading, setIsLoading] = useState(false)
  const [userEmail, setUserEmail] = useState('')
  const [password, setPassword] = useState('')
  const [success, setSuccess] = useState(false)
  const [error, setError] = useState(false)
  const [formErrorState, setFormErrorState] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const googleAuthUrl = `${process.env.REACT_APP_API_URL}/auth/google`
  const facebookAuthUrl = `${process.env.REACT_APP_API_URL}/auth/facebook`
  const twitchAuthUrl = `${process.env.REACT_APP_API_URL}/auth/twitch`

  const handleLogin = async () => {
    setIsLoading(true)
    try {
      if (!userEmail) return

      const response = await UserService.login({ email: userEmail, password })

      if (response.status !== 200) {
        setError(true)
        setErrorMessage('Invalid email address or password.')
        setSuccess(false)

        return
      }

      const { user } = response.data

      if (!user || !user.isAuthenticated || !user.accessToken) {
        setError(true)
        setErrorMessage(response.data.error || 'There was an error while trying to login.')
        setSuccess(false)

        return
      }

      dispatch(setUser(user))
      setSuccess(true)
      setError(false)
      setErrorMessage('')

    } catch ({ response }) {
      setError(true)
      setErrorMessage(response.data.message || 'Login failed')
      setSuccess(false)
      if (response.status === 401) {
        setFormErrorState(true)
      }
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    const fetchRedirectAuthError = async () => {
      const searchParams = new URLSearchParams(location.search)
      const redirectAuthError = searchParams.get('error')

      if (!redirectAuthError) {
        return
      }

      setError(true)
      setErrorMessage('Authentication cancelled by the provider.')
    }
    fetchRedirectAuthError()
  }, [location.search])

  useEffect(() => {
    const fetchData = async () => {
      if (success) {
        if (previousLocation && previousLocation.pathname !== '/login' && previousLocation.pathname !== '/create-account') {
          navigate(previousLocation.pathname)
        } else {
          navigate('/events')
        }
      }
    }
    fetchData()
  }, [success, navigate, setEvents, events, previousLocation])

  return (
    <>
      <SEO
        pageTitle={intl.formatMessage({ id: 'loginPageTitle', defaultMessage: 'Log into Clutch King' })}
        pageDescription={intl.formatMessage({ id: 'loginPageTitle', defaultMessage: 'Log into Clutch King' })}
      />
      <div className='login-container margin-bottom-48'>
        <div className='login-container__content'>
          <h2 className='margin-top-24 margin-bottom-24'>
            <FormattedMessage
              id='loginPageTitle'
              defaultMessage='Log into Clutch King'
            />
          </h2>

          { error ? <Message className='margin-bottom-16' message={errorMessage} msgType='error' /> : null }

          <Link to={googleAuthUrl}>
            <Button className='button-primary button-login button-icon' block>
              <Image className='button-icon__logo' src='https://cdnz.clutchking.gg/misc/google-logo.svg' />
              <span className='button-icon__label'>
                <FormattedMessage
                  id='loginWithGoogle'
                  defaultMessage='Login with Google'
                />
              </span>
            </Button>
          </Link>

          <Link to={facebookAuthUrl}>
            <Button className='button-primary button-login button-icon' block>
              <Image className='button-icon__logo' src='https://cdnz.clutchking.gg/misc/facebook-logo.svg' />
              <span className='button-icon__label'>
                <FormattedMessage
                  id='loginWithFacebook'
                  defaultMessage='Login with Facebook'
                />
              </span>
            </Button>
          </Link>

          <Link to={twitchAuthUrl}>
            <Button className='button-primary button-login button-icon' block>
              <Image className='button-icon__logo' src='https://cdnz.clutchking.gg/misc/twitch-logo.svg' />
              <span className='button-icon__label'>
                <FormattedMessage
                  id='loginWithTwitch'
                  defaultMessage='Login with Twitch'
                />
              </span>
            </Button>
          </Link>

          <LoginForm
            setUserEmail={setUserEmail}
            setPassword={setPassword}
            handleLogin={handleLogin}
            isLoading={isLoading}
            password={password}
            formErrorState={formErrorState}
          />
          <Link to='/reset-password'>
            <FormattedMessage
              id='loginPageForgotPassword'
              defaultMessage='Forgot your password?'
            />
          </Link>

          <h4 className='margin-top-48'>
            <FormattedMessage
              id='orCreateAccountTitle'
              defaultMessage='Or create an account'
            />
          </h4>
          <p>
            <FormattedMessage
              id='orCreateAccountDescription'
              defaultMessage='It is free, and it only takes a minute'
            />
          </p>
          <Link to='/create-account'>
            <Button className='button-secondary full-width'>
              <FormattedMessage
                id='orCreateAccountButton'
                defaultMessage='Create account'
              />
            </Button>
          </Link>
        </div>
      </div>
    </>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(Login)
