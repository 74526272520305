import React from 'react'
import { Button, Input } from 'antd-mobile'
import { useIntl, FormattedMessage } from 'react-intl'
import Message from '../message/Message'

const ChangePasswordForm = props => {
  const intl = useIntl()

  const {
    errorMessage,
    formData,
    handleChange,
    handleSubmit,
    hasError,
    isLoading,
    isPasswordReset,
  } = props

  return (
    <>
      { hasError && errorMessage.global ? <Message message={errorMessage.global} /> : null }

      { !isPasswordReset
        ? (
          <div className={`input-group ${hasError && errorMessage.currentPassword ? 'input-group-error' : ''}`}>
            <label htmlFor='password'>
              <FormattedMessage id='changePasswordCurrentField' defaultMessage='Current Password' />
            </label>
            <Input
              id='password'
              name='password'
              type='password'
              placeholder={intl.formatMessage({ id: 'changePasswordPlaceholder', defaultMessage: 'Password' })}
              onChange={value => handleChange(value, 'currentPassword')}
            />
            { hasError && errorMessage.currentPassword
              ? <p className='form-supporting-text'>{errorMessage.currentPassword}</p>
              : null }
          </div>
        )
        : null }

      <div className={`input-group ${hasError && errorMessage.newPassword ? 'input-group-error' : ''}`}>
        <label htmlFor='password'>
          <FormattedMessage id='changePasswordNewPassFieldLabel' defaultMessage='New Password' />
        </label>
        <Input
          id='new-password'
          name='new-password'
          type='password'
          placeholder={intl.formatMessage({ id: 'changePasswordNewPassPlaceholder', defaultMessage: 'New password' })}
          onChange={value => handleChange(value, 'newPassword')}
        />
        { hasError && errorMessage.newPassword
          ? <p className='form-supporting-text'>{ errorMessage.newPassword }</p>
          : (
            <p className='form-supporting-text'>
              <FormattedMessage
                id='changePasswordValidation'
                defaultMessage='Minimum 8 characters including letters and numbers.'
              />
            </p>
          )}
      </div>

      <div className={`input-group ${hasError && errorMessage.confirmPassword ? 'input-group-error' : ''}`}>
        <label htmlFor='password'>
          <FormattedMessage id='changePasswordConfirmPassField' defaultMessage='Confirm New Password' />
        </label>
        <Input
          id='confirm-password'
          name='confirm-password'
          type='password'
          placeholder={intl.formatMessage({ id: 'changePasswordNewPassPlaceholder', defaultMessage: 'Confirm password' })}
          onChange={value => handleChange(value, 'confirmPassword')}
        />
        { hasError && errorMessage.confirmPassword
          ? <p className='form-supporting-text'>{ errorMessage.confirmPassword }</p>
          : null }
      </div>

      <Button
        className='button-primary margin-top-16'
        block
        loading={isLoading}
        disabled={formData.newPassword === ''}
        onClick={handleSubmit}
      >
        <FormattedMessage id='changePasswordConfirmButton' defaultMessage='Confirm change' />
      </Button>
    </>
  )
}

export default ChangePasswordForm
