import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Skeleton, Toast, Image } from 'antd-mobile'
import { FormattedMessage } from 'react-intl'
import EventsService from '../../services/EventsService'
import PlayerCardSelected from './PlayerCardSelected'
import PlayerCardPoints from './PlayerCardPoints'
import PlayerCardEmpty from './PlayerCardEmpty'
import './PlayerList.css'

const PlayerList = props => {
  const {
    stage,
    setSelectedPlayersData,
    selectedPlayersData,
    setBalance,
    initialBalance,
    setOpenPlayersListPopup,
    setSelectedSlotAndRoleId,
    eventStatus,
    removePlayerFromSlot,
    setIsBalanceLoading,
    dummyList = false,
  } = props

  const [isLoading, setIsLoading] = useState(!dummyList)

  useEffect(() => {
    if (dummyList) return

    const fetchLineup = async () => {
      try {
        if (stage.id !== null) {
          const response = await EventsService.getMyLastLineup(stage.id)

          if (response.status === 204) {
            // No lineup
            return
          }

          setSelectedPlayersData(response.data)

          const totalPlayerCost = response.data.reduce((acc, player) => {
            const price = Number(player.price)
            return acc + (Number.isNaN(price) ? 0 : price)
          }, 0)
          setBalance(initialBalance - totalPlayerCost)
        }
      } catch (error) {
        Toast.show({
          icon: 'fail',
          content: error.response?.data?.message || error.message,
        })
      } finally {
        setIsLoading(false)
        setIsBalanceLoading(false)
      }
    }
    fetchLineup()
  }, [
    stage?.id,
    dummyList,
    setBalance,
    setIsBalanceLoading,
    initialBalance,
    setSelectedPlayersData,
  ])

  const rosterName = useSelector(state => state.user?.rosterName)

  function getPlayerCard(player) {
    if (!player.id) {
      return (
        <PlayerCardEmpty
          player={player}
          setOpenPlayersListPopup={setOpenPlayersListPopup}
          setSelectedSlotAndRoleId={setSelectedSlotAndRoleId}
          disabledButton={eventStatus === 'MARKET CLOSED'}
          dummyButton={dummyList}
        />
      )
    }

    if (eventStatus === 'MARKET OPEN') {
      return <PlayerCardSelected player={player} action={removePlayerFromSlot} />
    }

    return <PlayerCardPoints player={player} />
  }

  if (isLoading) {
    return (
      <Skeleton animated style={{ height: '600px', marginBottom: '16px' }} />
    )
  }

  if (eventStatus === 'PUBLISHED') {
    return (
      <div className='empty-state'>
        <Image className='empty-state__image' src='https://cdnz.clutchking.gg/misc/grey-helmet.webp' />
        <h4 className='empty-state__title'>
          <FormattedMessage id='PlayerListPublishedEventStatusTitle' defaultMessage='This tournament will open soon' />
        </h4>
        <p className='empty-state__text'>
          <FormattedMessage id='PlayerListPublishedEventStatusContent' defaultMessage='You will be able to create your lineup as soon as the organiser publishes the matches.' />
        </p>
      </div>
    )
  }

  return (
    <div className='col-span-4'>
      {selectedPlayersData.map(player => {
        const { slot } = player
        return (
          <div key={`player-card-slot-${slot}`}>
            {getPlayerCard(player)}
          </div>
        )
      })}
    </div>
  )
}

export default PlayerList
