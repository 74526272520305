import React from 'react'
import { Link } from 'react-router-dom'
import { Button, Image } from 'antd-mobile'
import { FormattedMessage } from 'react-intl'
import useAuthUrls from '../hooks/useAuthUrls'

const SocialMediaButtons = ({ baseUrls, language }) => {
  const { googleAuthUrl, facebookAuthUrl, twitchAuthUrl } = useAuthUrls(baseUrls, language)

  return (
    <div>
      <Link to={googleAuthUrl}>
        <Button className='button-primary button-login button-icon' block>
          <Image className='button-icon__logo' src='https://cdnz.clutchking.gg/misc/google-logo.svg' />
          <span className='button-icon__label'>
            <FormattedMessage id='joinWithGoogle' defaultMessage='Join with Google' />
          </span>
        </Button>
      </Link>

      <Link to={facebookAuthUrl}>
        <Button className='button-primary button-login button-icon' block>
          <Image className='button-icon__logo' src='https://cdnz.clutchking.gg/misc/facebook-logo.svg' />
          <span className='button-icon__label'>
            <FormattedMessage id='joinWithFacebook' defaultMessage='Join with Facebook' />
          </span>
        </Button>
      </Link>

      <Link to={twitchAuthUrl}>
        <Button className='button-primary button-login button-icon' block>
          <Image className='button-icon__logo' src='https://cdnz.clutchking.gg/misc/twitch-logo.svg' />
          <span className='button-icon__label'>
            <FormattedMessage id='joinWithTwitch' defaultMessage='Join with Twitch' />
          </span>
        </Button>
      </Link>
    </div>
  )
}

export default SocialMediaButtons
