import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { Button, Skeleton } from 'antd-mobile'
import { FireIcon } from '@heroicons/react/16/solid'
import { useIntl, FormattedMessage } from 'react-intl'
import EventsService from '../../services/EventsService'
import SEO from '../SEO'
import PointsPerLineupPlayerCard from './PointsPerLineupPlayerCard'
import './PointsPerLineup.css'

const PointsPerLineup = props => {
  const {
    event,
    userId,
    popupVisible,
    setPopupVisible,
    setUsername,
    setRosterName,
    setLeftAction,
    setTitle,
    setSelectedRoster,
  } = props
  const user = useSelector(state => state.user)
  const selectedUserId = userId || user.id
  const navigate = useNavigate()
  const intl = useIntl()
  const [rosterLineUps, setRosterLineUps] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const [pageTitle, setPageTitle] = useState()

  useEffect(() => {
    const setupBackButtonAction = () => {
      if (setLeftAction) {
        setLeftAction(() => () => {
          setSelectedRoster(null)
          setTitle(null)
          setLeftAction(null)
          setUsername(null)
          setRosterName(null)

          const queryParams = new URLSearchParams(window.location.search)
          queryParams.delete('roster')
          navigate({ search: queryParams.toString() })
        })
        setTitle(intl.formatMessage({
          id: 'eventHeaderPopupTitleBack',
          defaultMessage: 'Back',
        }))
      }
    }
    setupBackButtonAction()
  }, [setLeftAction, setSelectedRoster, setTitle, setUsername, setRosterName, navigate, intl])

  useEffect(() => {
    const fetchPointsPerLineup = async () => {
      try {
        setIsLoading(true)
        const { data } = await EventsService.getAllLineupsByUser(event.id, selectedUserId)
        const { roster, stages } = data
        const filteredLineups = event.status === 'MARKET OPEN'
          ? stages.filter(lineup => (lineup.status === 'ENDED'))
          : stages
        setRosterLineUps(filteredLineups)

        if (setUsername && setRosterName) {
          setUsername(roster.user.username)
          setRosterName(roster.name)
          setPageTitle(`${roster.name} - ${event.name}`)
        }
      } catch (error) {
        // to do: error handling
      } finally {
        setIsLoading(false)
      }
    }

    fetchPointsPerLineup()
  }, [
    event.id,
    event.status,
    event.name,
    selectedUserId,
    setUsername,
    setRosterName,
  ])

  const navigateToEvents = () => {
    navigate('/events')
  }

  if (isLoading) {
    return (
      <>
        <Skeleton.Title animated />
        <Skeleton animated style={{ height: '600px', margin: '16px 0' }} />
      </>
    )
  }

  const calculateTotalPoints = players => (
    players.reduce((acc, player) => {
      if (player.playerPoints !== null) {
        return acc + Number(player.playerPoints)
      }
      return acc
    }, 0)
  )

  return (
    <div className='col-span-4'>
      { pageTitle ? (
        <SEO
          pageTitle={pageTitle}
          pageDescription={event.name}
        />
      ) : null }
      {rosterLineUps.length > 0 ? (
        <div>
          {rosterLineUps.map(stage => {
            const lineups = stage?.lineups || []
            const players = lineups?.[0]?.players || []
            const stageTotalPoints = calculateTotalPoints(players).toFixed(2)

            return (
              <div className='points_per_lineup_stage_container' key={stage.id}>
                <div className='points_per_lineup_stage_header'>
                  <h6>
                    {stage.name}
                  </h6>
                  <div className='points_per_lineup_stage_total_points'>
                    <FireIcon className='points_per_lineup_fire_icon' />
                    <h6>{stageTotalPoints || '-'}</h6>
                  </div>
                </div>
                {players.length > 0 ? (
                  <div className='points_per_lineup_cards_container'>
                    {players.map(player => (
                      <div key={player.slot}>
                        <PointsPerLineupPlayerCard
                          key={player.slot}
                          player={player}
                        />
                      </div>
                    ))}
                  </div>
                ) : (
                  <p className='points_per_lineup_no_lineup text-small'>
                    <FormattedMessage
                      id='pointsPerLineupPlaceholder'
                      defaultMessage='No lineup created for this stage'
                    />
                  </p>
                )}
              </div>
            )
          })}
        </div>
      ) : null}
      <div className='points_per_lineup-navigate-btn-container'>
        {popupVisible ? (
          <Button
            className='points_per_lineup-navigate-btn button-primary'
            onClick={() => setPopupVisible(false)}
          >
            <FormattedMessage
              id='pointsPerLineupPopupCloseBtn'
              defaultMessage='Close'
            />
          </Button>
        ) : (
          <Button
            className='points_per_lineup-navigate-btn button-primary'
            onClick={() => navigateToEvents()}
          >
            <FormattedMessage
              id='pointsPerLineupBackEventsBtn'
              defaultMessage='Back to tournaments'
            />
          </Button>
        )}
      </div>
    </div>
  )
}

export default PointsPerLineup
