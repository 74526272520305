import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Button, Image, Skeleton } from 'antd-mobile'
import { UserIcon } from '@heroicons/react/16/solid'
import { FormattedMessage, useIntl } from 'react-intl'
import LeagueService from '../../services/LeagueService'
import SEO from '../SEO'
import './Leagues.css'

const Leagues = () => {
  const intl = useIntl()
  const [leagues, setLeagues] = useState([])
  const [myLeagues, setMyLeagues] = useState([])
  const [loadingLeagues, setLoadingLeagues] = useState(true)
  const [loadingMyLeagues, setLoadingMyLeagues] = useState(true)
  const [leaguesError, setLeaguesError] = useState(null)
  const [myLeaguesError, setMyLeaguesError] = useState(null)

  useEffect(() => {
    const fetchLeagues = async () => {
      try {
        const response = await LeagueService.getAllLeagues()
        setLeagues(response.data)
        setLoadingLeagues(false)
      } catch (error) {
        console.error('Error fetching leagues:', error)
        setLeaguesError('Error fetching leagues. Please try again later.')
        setLoadingLeagues(false)
      }
    }

    const fetchMyLeagues = async () => {
      try {
        const response = await LeagueService.getMyLeagues()
        setMyLeagues(response.data)
        setLoadingMyLeagues(false)
      } catch (error) {
        console.error('Error fetching my leagues:', error)
        setMyLeaguesError('Error fetching my leagues. Please try again later.')
        setLoadingMyLeagues(false)
      }
    }

    fetchLeagues()
    fetchMyLeagues()
  }, [])

  const renderLeagues = () => {
    if (loadingLeagues) {
      return <Skeleton className='col-span-4' animated style={{ height: '398px', marginBottom: '24px' }} />
    }

    if (leaguesError) {
      return <p>{leaguesError}</p>
    }

    if (!leagues || leagues.length === 0) {
      return <p>No leagues</p>
    }

    return (
      <div className='leagues-list col-span-4'>
        <h4 className='margin-bottom-16'>
          <FormattedMessage id='leaguesPageAllLeagues' defaultMessage='All leagues' />
        </h4>
        {leagues.map(league => (
          <Link to={`/leagues/${league.id}`} key={league.id} className='leagues-list__link'>
            <div className='leagues-list__league-card'>
              <div className='leagues-list__league-card__left-column'>
                <h6>{league.name}</h6>
                <p className='color-white-80'>
                  {league.description ? league.description : (
                    <FormattedMessage id='leaguesPageNoDescription' defaultMessage='No description' />
                  )}
                </p>
              </div>
              <div className='leagues-list__league-card__right-column'>
                <p className='text-small color-white-80'>
                  { league.isPrivate
                    ? <FormattedMessage id='leaguesPagePrivate' defaultMessage='Private' />
                    : <FormattedMessage id='leaguesPagePublic' defaultMessage='Public' /> }
                </p>
                <div className='leagues-list__league-card__member-count'>
                  <UserIcon className='leagues-list__league-card__icon' />
                  <span className='text-small bold'>
                    {league.memberCount}
                  </span>
                </div>
              </div>
            </div>
          </Link>
        ))}
      </div>
    )
  }

  const renderMyLeagues = () => {
    if (loadingMyLeagues) {
      return <Skeleton className='col-span-4' animated style={{ height: '398px', marginBottom: '24px' }} />
    }

    if (myLeaguesError) {
      return <p>{myLeaguesError}</p>
    }

    if (!myLeagues || myLeagues.length === 0) {
      return
    }

    return (
      <div className='leagues-list my-leagues col-span-4'>
        <h4 className='margin-bottom-16'>
          <FormattedMessage id='leaguesPageMyLeagues' defaultMessage='My leagues' />
        </h4>
        {myLeagues.map(league => (
          <Link to={`/leagues/${league.id}`} key={league.id} className='leagues-list__link'>
            <div className='leagues-list__league-card'>
              <div className='leagues-list__league-card__left-column'>
                <h6 className=''>{league.name}</h6>
                <p className='color-white-80'>
                  {league.description ? league.description : (
                    <FormattedMessage id='leaguesPageNoDescription' defaultMessage='No description' />
                  )}
                </p>
              </div>
              <div className='leagues-list__league-card__right-column'>
                <p className='text-small color-white-80'>
                  { league.isPrivate
                    ? <FormattedMessage id='leaguesPagePrivate' defaultMessage='Private' />
                    : <FormattedMessage id='leaguesPagePublic' defaultMessage='Public' /> }
                </p>
                <div className='leagues-list__league-card__member-count'>
                  <UserIcon className='leagues-list__league-card__icon' />
                  <span className='text-small bold'>
                    {league.memberCount}
                  </span>
                </div>
              </div>
            </div>
          </Link>
        ))}
      </div>
    )
  }

  return (
    <>
      <SEO
        pageTitle={intl.formatMessage({ id: 'navigationMenuBarLabelItemLeagues', defaultMessage: 'Leagues' })}
        pageDescription={intl.formatMessage({ id: 'navigationMenuBarLabelItemLeagues', defaultMessage: 'Leagues' })}
      />
      <div className='main-container display-grid'>
        <div className='card margin-bottom-8 col-span-4 height-fit-content'>
          <h4>
            <FormattedMessage id='leaguesPageCreateLeagueTitle' defaultMessage='Create a league' />
          </h4>
          <Image
            alt='Create League'
            className='card__image'
            src='https://cdnz.clutchking.gg/misc/leagues-hero.webp'
          />
          <p className='color-white-80'>
            <FormattedMessage
              id='leaguesPageCreateLeagueDescription'
              defaultMessage='Start a public or private league and play with your friends.'
            />
          </p>
          <Link to='/create-league'>
            <Button className='button-primary full-width'>
              <FormattedMessage id='leaguesPageCreateLeagueButton' defaultMessage='Start a new league' />
            </Button>
          </Link>
        </div>
        {renderMyLeagues()}
        {renderLeagues()}
      </div>
    </>
  )
}

export default Leagues
