/* eslint-disable no-underscore-dangle */
import { createStore, applyMiddleware, combineReducers, compose } from 'redux'
import { thunk } from 'redux-thunk'
import userReducer from './reducers/userReducer'
import eventsReducer from './reducers/eventsReducer'

const rootReducer = combineReducers({
  user: userReducer,
  events: eventsReducer,
})

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

const store = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(thunk)),
)

export default store
