import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Button, Toast, Skeleton } from 'antd-mobile'
import { FormattedMessage, useIntl } from 'react-intl'
import { useMediaQuery } from 'react-responsive'
import PlayerList from './PlayerList'
import EventsService from '../../services/EventsService'
import EventHeader from './EventHeader'
import MatchList from './MatchList'
import PlayerSelectionPopup from './PlayerSelectionPopup'
import PointsPerLineup from './PointsPerLineup'
import SEO from '../SEO'
import inGameLeadSvg from '../../images/roster-role-in-game-lead.svg'
import awperSvg from '../../images/roster-role-awper.svg'
import riflerSvg from '../../images/roster-role-rifler.svg'
import coachSvg from '../../images/roster-role-coach.svg'
import { getRoleBySlot } from '../../utils/playersMapping'
import ErrorPage404 from '../ErrorPage404'
import './EventPage.css'

const getPlayerPhotoPathByRoleId = roleId => {
  const rolePhotoPaths = {
    1: inGameLeadSvg,
    2: riflerSvg,
    3: awperSvg,
    4: coachSvg,
  }
  return rolePhotoPaths[roleId] || '' // to do: add default value if roleId is not found
}

const EventController = () => {
  const intl = useIntl()

  const [selectedPlayersData, setSelectedPlayersData] = useState([
    {
      id: null,
      name: null,
      playerPhotoPath: inGameLeadSvg,
      role: { id: 1, name: 'In-game lead' },
      team: { id: null, logoPath: null, name: null },
      matchData: [],
      slot: 0,
    },
    {
      id: null,
      name: null,
      playerPhotoPath: awperSvg,
      role: { id: 3, name: 'AWPer' },
      team: { id: null, logoPath: null, name: null },
      matchData: [],
      slot: 1,
    },
    {
      id: null,
      name: null,
      playerPhotoPath: riflerSvg,
      role: { id: 2, name: 'Rifler' },
      team: { id: null, logoPath: null, name: null },
      matchData: [],
      slot: 2,
    },
    {
      id: null,
      name: null,
      playerPhotoPath: riflerSvg,
      role: { id: 2, name: 'Rifler' },
      team: { id: null, logoPath: null, name: null },
      matchData: [],
      slot: 3,
    },
    {
      id: null,
      name: null,
      playerPhotoPath: riflerSvg,
      role: { id: 2, name: 'Rifler' },
      team: { id: null, logoPath: null, name: null },
      matchData: [],
      slot: 4,
    },
    {
      id: null,
      name: null,
      playerPhotoPath: coachSvg,
      role: { id: 4, name: 'Coach' },
      team: { id: null, logoPath: null, name: null },
      matchData: [],
      slot: 5,
    },
  ])

  const [event, setEvent] = useState([])
  const [stageData, setStageData] = useState({ id: null, name: '' })
  const [performanceStats, setPerformanceStats] = useState({})
  const [allPlayersListOnEvent, setAllPlayersListOnEvent] = useState([])
  const [balance, setBalance] = useState(0)
  const [isBalanceLoading, setIsBalanceLoading] = useState(true)
  const [openPlayersListPopup, setOpenPlayersListPopup] = useState(false)
  const [selectedSlotAndRoleId, setSelectedSlotAndRoleId] = useState({ roleId: null, slot: null })
  const [isLoading, setIsLoading] = useState(false)
  const [isLoadingEvent, setIsLoadingEvent] = useState(true)
  const [is404Error, setIs404Error] = useState(false)
  const [popupVisible, setPopupVisible] = useState(false)

  const isMobile = useMediaQuery({ maxWidth: 1024 })
  const queryParams = useParams()
  const { id: eventId } = queryParams

  useEffect(() => {
    const fetchEventDetails = async () => {
      try {
        setIsLoadingEvent(true)
        const { data } = await EventsService.getEventDetails(eventId)
        const { initialBudget, summary, stages } = data
        setEvent(data)
        setBalance(initialBudget)
        setPerformanceStats(prevPerformanceStats => ({
          ...prevPerformanceStats,
          playerRank: summary?.playerRank,
          totalPlayers: summary?.totalPlayers,
          totalPoints: summary?.totalPoints,
        }))

        if (stages.length > 0) {
          const stageToSet = stages.find(stage => stage.status === 'LIVE' || stage.status === 'UPCOMING') || stages[stages.length - 1]
          setStageData(stageToSet)
        }
        setIsLoadingEvent(false)
      } catch (error) {
        if (error.response.status === 404) {
          setIs404Error(true)
          setIsLoadingEvent(false)
        } else {
          Toast.show({
            icon: 'fail',
            content: error.response?.data?.message || error.message,
          })
        }
      }
    }
    fetchEventDetails()
  }, [eventId])

  useEffect(() => {
    const fetchAllPlayersByEvent = async () => {
      if (!event.id) return
      try {
        const { data } = await EventsService.getPlayersByEvent(event.id, stageData.id)
        setAllPlayersListOnEvent(data)
      } catch (error) {
        Toast.show({
          icon: 'fail',
          content: error.response?.data?.message || error.message,
        })
      }
    }
    fetchAllPlayersByEvent()
  }, [event.id, stageData.id])

  const findRoleName = playersList => {
    const selectedPlayerByRole = playersList.find(
      player => player.role.id === selectedSlotAndRoleId.roleId,
    )
    return selectedPlayerByRole ? selectedPlayerByRole.role.name : null
  }

  const updateNewPlayerBySlot = (selectedPlayer, selectedSlot) => {
    setSelectedPlayersData(prevPlayers => (
      prevPlayers.map(player => (
        player.slot === selectedSlot ? {
          ...player,
          ...selectedPlayer,
          playerPhotoPath: selectedPlayer.photoPath,
        } : player
      ))
    ))

    setBalance(prevBalance => prevBalance - parseFloat(selectedPlayer.price))
  }

  // move this maybe directly to playerList?
  const removePlayerFromSlot = (selectedPlayer, selectedSlot) => {
    setSelectedPlayersData(prevPlayersData => (
      prevPlayersData.map((player, index) => {
        if (index === selectedSlot) {
          return {
            id: null,
            name: null,
            playerPhotoPath: getPlayerPhotoPathByRoleId(player.role.id),
            role: getRoleBySlot(selectedSlot),
            team: { id: null, logoPath: null, name: null },
            matchData: null,
            slot: index,
          }
        }
        return player
      })
    ))

    setBalance(prevBalance => prevBalance + parseFloat(selectedPlayer.price))
  }

  const isTeamComplete = selectedPlayersData.every(player => player.id !== null)

  const lineupSavedMessage = intl.formatMessage({
    id: 'eventControllerToastContent',
    defaultMessage: 'Lineup saved',
  })

  const saveLineUp = async () => {
    setIsLoading(true)

    try {
      const selectedLineUpData = {
        eventId: event.id,
        players: selectedPlayersData,
      }

      await EventsService.updateLineUp(selectedLineUpData)
      Toast.show({
        icon: 'success',
        content: lineupSavedMessage,
      })
    } catch (error) {
      Toast.show({
        icon: 'fail',
        content: error.response?.data?.message || error.message,
      })
    } finally {
      setIsLoading(false)
    }
  }

  const filterPlayers = () => {
    const availablePlayers = allPlayersListOnEvent.filter(
      player => !selectedPlayersData
        .some(selectedPlayer => (selectedPlayer.name === player.name)),
    )

    return availablePlayers.filter(player => player.role.id === selectedSlotAndRoleId.roleId)
  }

  const renderLineupAndStageLayout = () => {
    if (isMobile) {
      if (event.status === 'ENDED') {
        return null
      }

      return (
        <div className='col-span-4'>
          <PlayerList
            stage={stageData}
            initialBalance={event.initialBudget}
            setBalance={setBalance}
            setIsBalanceLoading={setIsBalanceLoading}
            selectedPlayersData={selectedPlayersData}
            setSelectedPlayersData={setSelectedPlayersData}
            setOpenPlayersListPopup={setOpenPlayersListPopup}
            setSelectedSlotAndRoleId={setSelectedSlotAndRoleId}
            removePlayerFromSlot={removePlayerFromSlot}
            eventStatus={event.status}
          />
          <Button
            className='button-primary margin-top-16 margin-bottom-24'
            block
            loading={isLoading}
            onClick={() => saveLineUp()}
            disabled={!isTeamComplete || (event.status === 'MARKET CLOSED' || event.status === 'ENDED')}
          >
            {event.status === 'MARKET CLOSED'
              ? <FormattedMessage id='eventControllerDisabledMarketClosedBtn' defaultMessage='Market Closed' />
              : <FormattedMessage id='eventControllerEnabledSaveLineUpBtn' defaultMessage='Save Line-up' />}
          </Button>
        </div>
      )
    }

    return (
      // Desktop
      <>
        <div className='col-span-4 lg-col-span-6'>
          { event.status === 'ENDED' ? (
            <PointsPerLineup event={event} />
          ) : (
            <>
              <PlayerList
                stage={stageData}
                initialBalance={event.initialBudget}
                balance={balance}
                setBalance={setBalance}
                setIsBalanceLoading={setIsBalanceLoading}
                selectedPlayersData={selectedPlayersData}
                setSelectedPlayersData={setSelectedPlayersData}
                setOpenPlayersListPopup={setOpenPlayersListPopup}
                setSelectedSlotAndRoleId={setSelectedSlotAndRoleId}
                removePlayerFromSlot={removePlayerFromSlot}
                eventStatus={event.status}
              />
              <Button
                className='button-primary margin-top-16 margin-bottom-48'
                block
                loading={isLoading}
                onClick={() => saveLineUp()}
                disabled={!isTeamComplete || (event.status === 'MARKET CLOSED' || event.status === 'ENDED')}
              >
                {event.status === 'MARKET CLOSED'
                  ? <FormattedMessage id='eventControllerDisabledMarketClosedBtn' defaultMessage='Market Closed' />
                  : <FormattedMessage id='eventControllerEnabledSaveLineUpBtn' defaultMessage='Save Line-up' />}
              </Button>
            </>
          )}
        </div>
        <div className='lg-col-span-6'>
          <MatchList event={event} />
        </div>
      </>
    )
  }

  if (is404Error) return <ErrorPage404 />

  return (
    <div>
      {isLoadingEvent ? (
        <>
          <Skeleton animated style={{ height: '330px' }} />
          <div style={{ padding: '0 16px' }}>
            <Skeleton animated style={{ height: '140px', marginTop: '16px' }} />
            <div className='display-grid'>
              <Skeleton className='col-span-4 lg-col-span-6' animated style={{ height: '600px', margin: '16px 0' }} />
              {!isMobile ? (
                <Skeleton className='col-span-4 lg-col-span-6' animated style={{ height: '600px', margin: '16px 0' }} />
              ) : null }
            </div>
          </div>
        </>
      ) : (
        <>
          <SEO
            pageTitle={`${intl.formatMessage({ id: 'pageTitleMyTeam', defaultMessage: 'My team' })} - ${event.name}`}
            pageDescription={event.name}
          />

          <EventHeader
            event={event}
            stage={stageData}
            performanceStats={performanceStats}
            isBalanceLoading={isBalanceLoading}
            lineupStats={{
              balance,
              playerCounter: selectedPlayersData.filter(player => player.id !== null).length,
            }}
            setPopupVisible={setPopupVisible}
            popupVisible={popupVisible}
          />
          <div className='main-container display-grid'>
            {renderLineupAndStageLayout()}
            <PlayerSelectionPopup
              openPlayersListPopup={openPlayersListPopup}
              setOpenPlayersListPopup={setOpenPlayersListPopup}
              playersByRole={filterPlayers()}
              roleName={findRoleName(allPlayersListOnEvent)}
              updateNewPlayerBySlot={updateNewPlayerBySlot}
              slot={selectedSlotAndRoleId.slot}
              balance={balance}
            />
            {isMobile && event.status === 'ENDED' ? (
              <PointsPerLineup event={event} />
            ) : null}
          </div>
        </>
      )}

    </div>
  )
}

export default EventController
