import React, { useState, useEffect } from 'react'
import { FormattedMessage } from 'react-intl'
import { intervalToDuration } from 'date-fns'

const Countdown = ({ targetDate }) => {
  const [timeRemaining, setTimeRemaining] = useState(
    {
      days: 0,
      hours: 0,
      minutes: 0,
      seconds: 0,
    },
  )

  useEffect(() => {
    if (!targetDate) return

    const calculateTimeRemaining = () => {
      const now = new Date()
      const target = new Date(targetDate)
      const duration = intervalToDuration({ start: now, end: target })

      if (now >= target) {
        setTimeRemaining({ expired: true })
        return
      }

      setTimeRemaining({
        days: duration.days || 0,
        hours: duration.hours || 0,
        minutes: duration.minutes || 0,
        seconds: duration.seconds || 0,
      })
    }

    const interval = setInterval(calculateTimeRemaining, 1000)
    calculateTimeRemaining()

    return () => clearInterval(interval)
  }, [targetDate])

  if (!targetDate || !timeRemaining) {
    return null
  }

  if (timeRemaining.expired) {
    return <FormattedMessage id='eventStarted' defaultMessage='Event has started' />
  }

  const { days, hours, minutes, seconds } = timeRemaining
  const formattedTime = `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`

  return (
    <span>
      {timeRemaining.days > 0 ? (
        <FormattedMessage
          id='eventStartsInDays'
          defaultMessage='Starts in {days} days {time}'
          values={{
            days,
            time: formattedTime,
          }}
        />
      ) : (
        <FormattedMessage
          id='eventStartsInTime'
          defaultMessage='Starts in {time}'
          values={{ time: formattedTime }}
        />
      )}
    </span>
  )
}

export default Countdown
