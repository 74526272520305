import React, {
  createContext,
  useContext,
  useEffect,
  useState,
  useMemo,
  useCallback,
  useRef,
} from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { IntlProvider } from 'react-intl'
import UserService from './services/UserService'
import { setUser } from './redux/actions/userAction'

const LanguageContext = createContext()

export const useLanguage = () => useContext(LanguageContext)

const loadLocaleData = locale => {
  switch (locale) {
  case 'en':
    return import('./locales/en.json')
  case 'pt':
    return import('./locales/pt.json')
  case 'es':
    return import('./locales/es.json')
  default:
    return import('./locales/en.json')
  }
}

export const LanguageProvider = ({ children, initialLocale }) => {
  const user = useSelector(state => state.user)
  const dispatch = useDispatch()

  const [locale, setLocale] = useState(initialLocale)
  const [messages, setMessages] = useState({})
  const [isLoading, setIsLoading] = useState(true)

  const currentLocaleRef = useRef(locale)

  useEffect(() => {
    loadLocaleData(locale)
      .then(data => {
        setMessages(data.default)
        setIsLoading(false)
      })
      .catch(error => console.error(`Error loading locale data: ${error}`))

    document.documentElement.lang = locale
  }, [locale])

  const handleLocaleChange = useCallback(
    async newLocale => {
      if (newLocale === currentLocaleRef.current) {
        return
      }

      currentLocaleRef.current = newLocale

      try {
        if (user) {
          await UserService.updateLanguage({ language: newLocale })

          const updatedUser = {
            ...user,
            language: newLocale,
          }
          dispatch(setUser(updatedUser))
        }

        localStorage.setItem('appLocale', newLocale)
        setLocale(newLocale)

        return true
      } catch (error) {
        console.error('Error updating language:', error)
      }
    },
    [user, dispatch],
  )

  const value = useMemo(
    () => ({
      locale,
      handleLocaleChange,
    }),
    [locale, handleLocaleChange],
  )

  if (isLoading) {
    return null
  }

  return (
    <LanguageContext.Provider value={value}>
      <IntlProvider locale={locale} messages={messages} defaultLocale='en'>
        {children}
      </IntlProvider>
    </LanguageContext.Provider>
  )
}
