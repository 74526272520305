import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { Button, Image, Skeleton, Toast } from 'antd-mobile'
import { FormattedMessage, useIntl } from 'react-intl'
import { setUser } from '../../redux/actions/userAction'
import SEO from '../SEO'
import Message from '../message/Message'
import UserService from '../../services/UserService'
import TeamService from '../../services/TeamService'
import './SelectFavouriteTeam.css'

function SelectFavouriteTeam() {
  const user = useSelector(state => state.user)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const intl = useIntl()

  const [favouriteTeam, setFavouriteTeam] = useState(localStorage.getItem('favouriteTeamId') || user.favouriteTeamId || null)
  const [isLoading, setIsLoading] = useState(false)
  const [isLoadingTeams, setIsLoadingTeams] = useState(true)
  const [hasError, setError] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [teams, setTeams] = useState([])

  const toastContent = intl.formatMessage({
    id: 'favouriteTeamToastSuccessContent',
    defaultMessage: 'Saved',
  })

  const handleChange = value => {
    setFavouriteTeam(value)
  }

  const navigateToNextStep = () => {
    navigate('/roster-name')
  }

  const handleSubmit = async () => {
    setIsLoading(true)
    try {
      if (!favouriteTeam) {
        setError(true)
        setErrorMessage(<FormattedMessage
          id='favouriteTeamNoTeamSelectedErrorMsg'
          defaultMessage='Select your favourite team or skip this step.'
        />)

        return
      }

      await UserService.updateFavouriteTeam({ teamId: favouriteTeam })
      localStorage.setItem('favouriteTeamId', favouriteTeam)
      const updatedUser = {
        ...user,
        favouriteTeamId: Number(favouriteTeam),
      }
      dispatch(setUser(updatedUser))

      Toast.show({
        icon: 'success',
        content: toastContent,
      })

      setTimeout(() => {
        navigateToNextStep()
      }, 1000)
    } catch (e) {
      setError(true)
      setErrorMessage(e.message)
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    const fetchTeams = async () => {
      try {
        const response = await TeamService.getAll()
        setTeams(response.data)
      } catch (error) {
        setError(true)
        setErrorMessage(error.message)
      } finally {
        setIsLoadingTeams(false)
      }
    }

    fetchTeams()
  }, [])

  return (
    <>
      <SEO
        pageTitle={intl.formatMessage({ id: 'favouriteTeamTitle', defaultMessage: 'Your favourite team' })}
        pageDescription={intl.formatMessage({ id: 'favouriteTeamTitle', defaultMessage: 'Your favourite team' })}
      />
      <div className='main-container'>
        <h2 className='align-center margin-bottom-24 margin-top-16'>
          <FormattedMessage id='favouriteTeamTitle' defaultMessage='Your favourite team' />
        </h2>

        { hasError ? <Message message={errorMessage} /> : null }

        {
          isLoadingTeams
            ? (
              <div className='favourite-team-skeleton--container'>
                <Skeleton animated style={{ height: '84px', margin: '0 0 8px' }} />
                <Skeleton animated style={{ height: '84px', margin: '0 0 8px' }} />
                <Skeleton animated style={{ height: '84px', margin: '0 0 8px' }} />
              </div>
            )
            : (
              <div className='favourite-team-container'>
                { teams.map(team => (
                  <label key={team.id} htmlFor={`team-${team.id}`} className='favourite-team-cell'>
                    <input
                      type='radio'
                      name='favourite-team'
                      id={`team-${team.id}`}
                      value={team.id}
                      onChange={e => handleChange(e.target.value)}
                      checked={favouriteTeam === String(team.id)}
                    />
                    <span className='favourite-team-cell-logo'>
                      <Image className='favourite-team-cell-logo--image' src={team.logoPath} alt={team.name} />
                    </span>
                  </label>
                ))}
              </div>
            )
        }

        <div className='sticky-button-container'>
          <Button
            onClick={handleSubmit}
            className='button-primary'
            block
            loading={isLoading}
          >
            <FormattedMessage id='favouriteTeamContinueBtn' defaultMessage='Continue' />
          </Button>
          <Button
            onClick={() => navigateToNextStep()}
            className='button-secondary'
            block
          >
            <FormattedMessage id='favouriteTeamSkipBtn' defaultMessage='Skip this step' />
          </Button>
        </div>
      </div>
    </>
  )
}

export default SelectFavouriteTeam
