import React from 'react'
import { Image, Button } from 'antd-mobile'
import { FormattedMessage } from 'react-intl'

const PlayerCardEmpty = props => {
  const {
    player,
    setOpenPlayersListPopup,
    setSelectedSlotAndRoleId,
    disabledButton,
    dummyButton,
  } = props

  const { playerPhotoPath, role, slot } = player

  return (
    <div className='player-list-selection-card'>
      <div className='player-list-selection-card-image'>
        <Image src={playerPhotoPath} />
      </div>
      <div className='player-list-selection-card-text'>
        <h4>{role.name}</h4>
      </div>
      <div className='player-list-selection-card-btn'>
        <Button
          className='player-card__action__button'
          disabled={disabledButton}
          onClick={() => {
            if (!dummyButton) {
              setOpenPlayersListPopup(true)
              setSelectedSlotAndRoleId({ roleId: role.id, slot })
            }
          }}
        >
          <FormattedMessage id='playerCardEmptySelectBtn' defaultMessage='Select' />
        </Button>
      </div>
    </div>
  )
}

export default PlayerCardEmpty
