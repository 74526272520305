import React from 'react'
import { Image } from 'antd-mobile'
import { FormattedMessage } from 'react-intl'
import './PlayerCard.css'

const PlayerCardPoints = props => {
  const { player } = props

  function getMatches(matches) {
    if (!matches || !matches.length) {
      return (
        <p className='text-small color-white-80'>
          <FormattedMessage id='playerCardNotPlayingText' defaultMessage='Not playing' />
        </p>
      )
    }

    return matches.map(match => {
      const { id, teamALogoPath, teamAScore, teamBLogoPath, teamBScore, status } = match.match
      const key = `${id}-${player.id}`
      return (
        <div key={key} className='player-card__content__match'>
          <Image
            src={teamALogoPath}
            className='player-card__content__matches__team-logo'
          />
          <span className='text-extra-small'>
            {status === 'ENDED' ? teamAScore : '∞'}
          </span>
          <span className='text-extra-small'>-</span>
          <span className='text-extra-small'>
            {status === 'ENDED' ? teamBScore : '∞'}
          </span>
          <Image
            src={teamBLogoPath}
            className='player-card__content__matches__team-logo'
          />
        </div>
      )
    })
  }

  return (
    <div className='player-card'>
      <div className='player-card__image'>
        <Image src={player.playerPhotoPath} />
      </div>
      <div className='player-card__content'>
        <h4 className=''>{player.name}</h4>
        <p className='text-small color-white-80'>
          {player.role.name}
        </p>
        <div className='player-card__content__match-list'>
          {getMatches(player.matchData)}
        </div>
      </div>
      <div className='player-card__points'>
        <h4>{player.totalPoints}</h4>
        <p className='text-small'>
          <FormattedMessage id='playerCardActionPointsLabel' defaultMessage='points' />
        </p>
      </div>
    </div>
  )
}

export default PlayerCardPoints
