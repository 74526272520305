import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import { FormattedMessage } from 'react-intl'
import { LanguageIcon } from '@heroicons/react/24/outline'
import Logo from './Logo'
import './Footer.css'

const Footer = () => {
  const location = useLocation()
  const { pathname } = location

  function renderPrivacyLink() {
    return (
      <button
        type='button'
        className='footer__links-column__link'
        style={
          {
            background: 'transparent', color: 'var(--color-white)', padding: 0, textWrap: 'wrap', textAlign: 'left',
          }
        }
        onClick={e => {
          e.preventDefault()
          if (window.googlefc && window.googlefc.callbackQueue) {
            window.googlefc.callbackQueue.push(window.googlefc.showRevocationMessage)
          }
        }}
      >
        Privacy and cookie settings
      </button>
    )
  }

  return (
    <footer className='footer'>
      <Logo className='footer__logo' />
      <div className='footer__links-column footer__links-column-menu'>
        <h5 className='footer__links-column__title'>
          <FormattedMessage id='footerMenu' defaultMessage='Menu' />
        </h5>
        <Link to='/login' className='footer__links-column__link'>
          <FormattedMessage id='footerLogin' defaultMessage='Login' />
        </Link>
        <Link to='/create-account' className='footer__links-column__link'>
          <FormattedMessage id='footerCreateAccount' defaultMessage='Create account' />
        </Link>
        <Link to='/events' className='footer__links-column__link'>
          <FormattedMessage id='navigationMenuBarLabelItemEvents' defaultMessage='Events' />
        </Link>
        {/* <Link to='/faq' className='footer__links-column__link'>
          <FormattedMessage id='footerFAQ' defaultMessage='FAQ' />
        </Link> */}
        <Link to='/contact' className='footer__links-column__link'>
          <FormattedMessage id='footerContact' defaultMessage='Contact' />
        </Link>
      </div>
      <div className='footer__links-column footer__links-column-legal'>
        <h5 className='footer__links-column__title'>
          <FormattedMessage id='footerLegal' defaultMessage='Legal' />
        </h5>
        <Link to='/terms-of-service' className='footer__links-column__link'>
          <FormattedMessage id='footerTermsAgreement' defaultMessage='Terms & agreement' />
        </Link>
        <Link to='/privacy-policy' className='footer__links-column__link'>
          <FormattedMessage id='footerPrivacyPolicy' defaultMessage='Privacy policy' />
        </Link>
        {renderPrivacyLink()}
      </div>
      <div className='footer__links-column footer__links-column--row-3'>
        <h5 className='footer__links-column__title footer__links-column__title--with-icon'>
          <LanguageIcon className='footer__links-column__icon' />
          <FormattedMessage id='footerLanguage' defaultMessage='Language' />
        </h5>
        <Link to='/language/en' state={{ previousPath: pathname }} className='footer__links-column__link'>
          <FormattedMessage id='footerLanguageEN' defaultMessage='English' />
        </Link>
        <Link to='/language/pt' state={{ previousPath: pathname }} className='footer__links-column__link'>
          <FormattedMessage id='footerLanguagePT' defaultMessage='Português' />
        </Link>
        <Link to='/language/es' state={{ previousPath: pathname }} className='footer__links-column__link'>
          <FormattedMessage id='footerLanguageES' defaultMessage='Español' />
        </Link>
      </div>
      <div className='footer__company-name'>
        <p className='text-small'>2024. Rigoletto Caires Entretenimento Digital LTDA.</p>
      </div>
    </footer>
  )
}

export default Footer
