// eg format 04/08/2024, 19:18
const formatDate = dateString => {
  const date = new Date(dateString)
  const options = {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    hour12: false,
  }
  return new Intl.DateTimeFormat('en-GB', options).format(date)
}

// eg format Aug 04 - 7:18 PM
const formatDateAndTime = (dateCreatedAt, locale) => {
  const announcementCreatedAt = new Date(dateCreatedAt)

  const dayMonthFormatOptions = {
    day: '2-digit',
    month: 'short',
  }

  const dayMonthYearFormatOptions = {
    ...dayMonthFormatOptions,
    year: 'numeric',
  }

  const timeFormatOptions = {
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
  }

  const dayMonthFormatter = new Intl.DateTimeFormat(locale, dayMonthFormatOptions)
  const dayMonthYearFormatter = new Intl.DateTimeFormat(locale, dayMonthYearFormatOptions)
  const timeFormatter = new Intl.DateTimeFormat(locale, timeFormatOptions)

  const formattedDayMonth = dayMonthFormatter.format(announcementCreatedAt)
  const formattedDayMonthYear = dayMonthYearFormatter.format(announcementCreatedAt)
  const formattedTime = timeFormatter.format(announcementCreatedAt)

  return {
    date: formattedDayMonth,
    time: formattedTime,
    both: `${formattedDayMonth} - ${formattedTime}`,
    dayMonthYear: formattedDayMonthYear,
  }
}

const dateUtils = {
  formatDate,
  formatDateAndTime,
}

export default dateUtils
