import React from 'react'
import { Image, Button } from 'antd-mobile'
import { TrashIcon } from '@heroicons/react/20/solid'
import './PlayerCard.css'

const PlayerCardSelected = props => {
  const { player, action } = props

  return (
    <div className='player-card'>
      <div className='player-card__image'>
        <Image src={player.playerPhotoPath} />
      </div>
      <div className='player-card__content'>
        <h4 className=''>{player.name}</h4>
        <p className='text-small color-white-80'>
          {player.role.name}
        </p>
        <div className='player-card__content__complement'>
          <div className='player-card__action__team-name'>
            <Image className='player-card__action__team-name__icon' src={player.team.logoPath} />
            <span className='text-small color-white-80'>
              {player.team.name}
            </span>
          </div>
        </div>
      </div>
      <div className='player-card__action'>
        <p className='text-small color-white-80'>
          <span>$&nbsp;</span>
          <span>{player.price}</span>
        </p>
        <Button
          className='player-card__action__icon-button'
          onClick={() => action(player, player.slot)}
        >
          <TrashIcon className='player-card__action__icon-button__icon' />
        </Button>
      </div>
    </div>
  )
}

export default PlayerCardSelected
