import React, { useEffect, useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { Toast, Skeleton } from 'antd-mobile'
import { FormattedMessage, useIntl } from 'react-intl'
import EventsService from '../../services/EventsService'
import { getDisplayRosterName } from '../../utils/userHelper'
import PointsPerLineup from './PointsPerLineup'
import './EventRanking.css'

const EventRanking = props => {
  const { event, setLeftAction, setTitle, popupVisible, setPopupVisible } = props
  const intl = useIntl()
  const location = useLocation()
  const navigate = useNavigate()
  const [loading, setLoading] = useState(true)
  const [rankingData, setRankingData] = useState([])
  const [selectedRoster, setSelectedRoster] = useState(null)
  const [username, setUsername] = useState(null)
  const [rosterName, setRosterName] = useState(null)

  const handleSelectRoster = async roster => {
    setSelectedRoster(roster.userId)

    const queryParams = new URLSearchParams(location.search)
    queryParams.set('roster', roster.userId)
    navigate({ search: queryParams.toString() })
  }

  useEffect(() => {
    if (popupVisible) {
      setSelectedRoster(null)
      setUsername(null)
      setRosterName(null)
    }
  }, [popupVisible])

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search)
    const roster = queryParams.get('roster')
    if (roster) {
      setSelectedRoster(roster)
    }
  }, [location.search, setPopupVisible])

  useEffect(() => {
    const fetchRanking = async () => {
      try {
        const { data } = await EventsService.getRanking(event.id)
        setRankingData(data)
      } catch ({ error }) {
        Toast.show({
          icon: 'fail',
          content: error.response?.data?.message || error.message,
        })

        window.gtag('event', 'exception', {
          description: `Bad response while creating account: ${JSON.stringify(error)}`,
          fatal: false,
        })
      } finally {
        setLoading(false)
      }
    }
    fetchRanking()
  }, [event.id])

  const handleKeyPress = (e, roster) => {
    if (e.key === 'Enter' || e.key === ' ') {
      e.preventDefault()
      handleSelectRoster(roster)
    }
  }

  function renderView() {
    if (loading) {
      return (
        <>
          <Skeleton animated style={{ height: '88px', marginBottom: '4px' }} />
          <Skeleton animated style={{ height: '88px', marginBottom: '4px' }} />
          <Skeleton animated style={{ height: '88px', marginBottom: '4px' }} />
          <Skeleton animated style={{ height: '88px' }} />
        </>
      )
    }

    if (selectedRoster) {
      return (
        <>
          <h3 className='margin-top-16'>
            {getDisplayRosterName(rosterName, intl)}
          </h3>
          <p className='color-white-80 margin-bottom-24'>
            {username}
          </p>
          <PointsPerLineup
            event={event}
            userId={selectedRoster}
            setLeftAction={setLeftAction}
            setTitle={setTitle}
            setSelectedRoster={setSelectedRoster}
            setUsername={setUsername}
            setRosterName={setRosterName}
            popupVisible={popupVisible}
            setPopupVisible={setPopupVisible}
          />
        </>
      )
    }

    return (
      <table className='ranking-table'>
        <thead className='ranking-table__header'>
          <tr>
            <th align='left'>#</th>
            <th align='left'>
              <FormattedMessage id='EventRankingTeamHeader' defaultMessage='Team' />
            </th>
            <th align='right'>
              <FormattedMessage id='EventRankingPointsHeader' defaultMessage='Points' />
            </th>
          </tr>
        </thead>
        <tbody>
          {rankingData.map(roster => (
            <tr
              key={roster.rosterId}
              onClick={() => handleSelectRoster(roster)}
              role='button'
              tabIndex='0'
              onKeyPress={e => handleKeyPress(e, roster)}
            >
              <td>{roster.ranking}</td>
              <td>
                <p className='text-bold'>{getDisplayRosterName(roster.rosterName, intl)}</p>
                <p className='color-gray-1'>{roster.username}</p>
              </td>
              <td align='right'>{roster.totalPoints}</td>
            </tr>
          ))}
        </tbody>
      </table>
    )
  }

  return (
    renderView()
  )
}

export default EventRanking
