import React, { useState } from 'react'
import { Button, Input } from 'antd-mobile'
import { useNavigate } from 'react-router-dom'
import { useIntl, FormattedMessage } from 'react-intl'
import { EnvelopeOpenIcon } from '@heroicons/react/24/outline'
import Confirmation from '../confirmation/Confirmation'
import UserService from '../../services/UserService'

function ResetPassword() {
  const navigate = useNavigate()
  const [email, setEmail] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [hasError, setHasError] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [hasRequestedReset, setHasRequestedReset] = useState(false)

  const intl = useIntl()

  const handleChange = value => {
    setEmail(value)
  }

  const handleSubmit = async () => {
    setIsLoading(true)
    try {
      await UserService.resetPasswordRequest({ email })
      setHasRequestedReset(true)
    } catch (errors) {
      setHasError(true)
      setErrorMessage(errors.message)
    } finally {
      setIsLoading(false)
    }
  }

  const handleBackToLogin = () => {
    navigate('/login')
  }

  return (
    <div className='main-container'>
      <h2 className='align-center margin-top-24 margin-bottom-24'>
        <FormattedMessage id='ResetPasswordTitle' defaultMessage='Forgot password' />
      </h2>

      {!hasRequestedReset
        ? (
          <>
            <p className='align-center margin-bottom-24'>
              <FormattedMessage id='ResetPasswordRequestMsg' defaultMessage='Enter your account’s email and we’ll send you an email to reset your password.' />
            </p>
            <div className={`input-group ${hasError && errorMessage.email ? 'input-group-error' : ''}`}>
              <label htmlFor='email'>
                <FormattedMessage id='ResetPasswordEmailField' defaultMessage='Email address' />
                <Input
                  id='email'
                  name='email'
                  type='text'
                  placeholder={intl.formatMessage({ id: 'ResetPasswordEmailPlaceholder', defaultMessage: 'name@example.com' })}
                  onChange={value => handleChange(value)}
                />
              </label>
            </div>
            <Button
              className='button-primary button-login'
              block
              loading={isLoading}
              onClick={handleSubmit}
              disabled={email === ''}
            >
              <FormattedMessage id='ResetPasswordPasswordField' defaultMessage='Request password reset' />
            </Button>
          </>
        )
        : (
          <Confirmation
            buttonLabel='Back to login'
            icon={EnvelopeOpenIcon}
            isLoading={isLoading}
            handleAction={handleBackToLogin}
            componentFlag='resetPassword'
          />
        )}
    </div>
  )
}

export default ResetPassword
