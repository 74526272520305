import React, { useState, useEffect } from 'react'
import { Skeleton } from 'antd-mobile'
import { useParams, useNavigate } from 'react-router-dom'
import { FormattedMessage } from 'react-intl'
import { ExclamationCircleIcon, CheckCircleIcon } from '@heroicons/react/24/outline'
import Confirmation from '../confirmation/Confirmation'
import UserService from '../../services/UserService'

function ConfirmEmail() {
  const navigate = useNavigate()
  const queryParams = useParams()
  const { token } = queryParams
  const [isLoading, setIsLoading] = useState(true)
  const [hasError, setHasError] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [title, setTitle] = useState()

  const handleBackToEvents = () => {
    navigate('/events')
  }

  useEffect(() => {
    const validateEmailToken = async () => {
      try {
        const response = await UserService.confirmEmail({ emailToken: token })
        if (response.status !== 200) {
          throw new Error(response)
        }
        setTitle(<FormattedMessage id='confirmEmailConfirmationTitle' defaultMessage='You are all set!' />)
      } catch (error) {
        setHasError(true)
        setErrorMessage(error.message || 'Unknown error occurred')
        setTitle(<FormattedMessage id='confirmEmailConfirmationTitleError' defaultMessage='Something went wrong' />)
      } finally {
        setIsLoading(false)
      }
    }

    validateEmailToken()
  }, [token])

  if (isLoading) {
    return (
      <>
        <Skeleton.Title animated />
        <Skeleton animated style={{ height: '600px', margin: '16px 0' }} />
      </>
    )
  }

  return (
    <div className='main-container'>
      <Confirmation
        icon={hasError ? ExclamationCircleIcon : CheckCircleIcon}
        title={title}
        componentFlag={hasError ? 'confirmEmailError' : 'confirmEmail'}
        buttonLabel='See current tournaments'
        handleAction={handleBackToEvents}
        isLoading={isLoading}
      />
    </div>
  )
}

export default ConfirmEmail
