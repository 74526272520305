import React from 'react'
import { Link } from 'react-router-dom'
import { Image, Button } from 'antd-mobile'
import { FormattedMessage } from 'react-intl'
import EventStatusText from './EventStatusText'
import './EventsList.css'

const EventCard = props => {
  const { event } = props

  return (
    <Link to={{ pathname: `/events/${event.slug}` }} key={event.id} className='col-span-4 lg-col-span-3 no-underline'>
      <div className='event-card'>
        <div className='event-card__header'>
          <h6 className='event-card__body__title'>
            {event.name}
          </h6>
        </div>
        <Image
          className='event-card__image'
          height='100%'
          src={event.logoPath}
          fit='cover'
        />
        <div className='event-card__body'>
          <p className='event-card__status margin-bottom-16'>
            <EventStatusText event={event} />
          </p>
          <div className='event-card__controls'>
            <Button className='button button-primary full-width'>
              <FormattedMessage id='eventsListDetailsBtn' defaultMessage='See details' />
            </Button>
          </div>
        </div>
      </div>
    </Link>
  )
}

export default EventCard
