export function getRoleBySlot(slot) {
  const roles = [
    { id: 1, name: 'In-game lead' },
    { id: 2, name: 'Rifler' },
    { id: 3, name: 'AWPer' },
    { id: 4, name: 'Coach' },
  ]

  const roleMapping = {
    0: 1, // In-game lead
    1: 3, // AWPer
    2: 2, // Rifler
    3: 2, // Rifler
    4: 2, // Rifler
    5: 4, // Coach
  }

  const roleId = roleMapping[slot]
  const role = roles.find(r => r.id === roleId)
  return role
}
