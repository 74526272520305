import React, { useState, useEffect } from 'react'
import { Button } from 'antd-mobile'
import { FormattedMessage } from 'react-intl'
import ReactPixel from 'react-facebook-pixel'
import UserService from '../services/UserService'
import './CookieBanner.css'

// eslint-disable-next-line arrow-body-style
const loadScript = url => {
  return new Promise((resolve, reject) => {
    const script = document.createElement('script')
    script.src = url
    script.async = true
    script.onload = resolve
    script.onerror = () => {
      // eslint-disable-next-line no-console
      console.error(`Failed to load script: ${url}`)
      reject(new Error(`Failed to load script: ${url}`))
    }
    document.body.appendChild(script)
  })
}

const loadGoogleAnalytics = () => {
  loadScript('https://www.googletagmanager.com/gtag/js?id=G-5LHFS9NTQX').then(() => {
    window.dataLayer = window.dataLayer || []
    function gtag() {
      // eslint-disable-next-line prefer-rest-params
      window.dataLayer.push(arguments)
    }
    gtag('js', new Date())
    gtag('config', 'G-5LHFS9NTQX', {
      anonymize_ip: true,
      allow_ad_personalization_signals: false,
      allow_google_signals: false,
    })
  // eslint-disable-next-line no-console
  }).catch(error => console.error(error))
}

// eslint-disable-next-line arrow-body-style
const loadTwitterPixel = () => {
  return loadScript('https://static.ads-twitter.com/uwt.js').then(() => {
    if (!window.twq) {
      // eslint-disable-next-line func-names
      window.twq = function () {
        // eslint-disable-next-line no-unused-expressions
        window.twq.exe
          // eslint-disable-next-line prefer-rest-params, prefer-spread
          ? window.twq.exe.apply(window.twq, arguments)
          // eslint-disable-next-line prefer-rest-params
          : window.twq.queue.push(arguments)
      }
      window.twq.version = '1.1'
      window.twq.queue = []
    }
    window.twq('init', 'omhx8')
    window.twq('track', 'PageView')
  // eslint-disable-next-line no-console
  }).catch(error => console.error(error))
}

const loadTrackingScripts = () => {
  window.dataLayer.push({ event: 'consent_given' })
  window.gtag('config', 'G-5LHFS9NTQX', {
    anonymize_ip: true,
    allow_ad_personalization_signals: true,
    allow_google_signals: true,
  })

  // Initialize Facebook Pixel using react-facebook-pixel
  ReactPixel.init('986257199629974')
  ReactPixel.pageView()

  loadTwitterPixel()
}

const CookieBanner = () => {
  const [isVisible, setIsVisible] = useState(false)

  useEffect(() => {
    const cookieConsent = localStorage.getItem('ck-cookie-consent')
    if (!cookieConsent) {
      setIsVisible(true)
    } else if (cookieConsent === 'accepted') {
      loadTrackingScripts()
    }
    loadGoogleAnalytics()
  }, [])

  const handleAccept = async () => {
    localStorage.setItem('ck-cookie-consent', 'accepted')
    setIsVisible(false)
    loadTrackingScripts()
    try {
      await UserService.cookieConsent({ allowCookies: true })
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error)
    }
  }

  const handleDecline = async () => {
    localStorage.setItem('ck-cookie-consent', 'declined')
    setIsVisible(false)
    try {
      await UserService.cookieConsent({ allowCookies: false })
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error)
    }
  }

  if (!isVisible) return null

  return (
    <div className='cookie-banner'>
      <p className='text-small'>
        <FormattedMessage id='cookieBannerP1' defaultMessage='We use cookies to ensure the best experience on our website and to improve our game.' />
      </p>
      <p className='text-small'>
        <FormattedMessage
          id='cookieBannerP2'
          defaultMessage='By continuing to browse or selecting Accept,
            you agree to the use of cookies for optimizing performance and analytics.'
        />
      </p>
      <p className='text-small'>
        <FormattedMessage id='cookieBannerP3' defaultMessage='For more information, please read our Privacy Policy.' />
      </p>
      <Button className='button-primary full-width margin-top-12' onClick={handleAccept}>
        <FormattedMessage id='cookieBannerAcceptButton' defaultMessage='Accept' />
      </Button>
      <Button className='button-secondary full-width margin-top-12' onClick={handleDecline}>
        <FormattedMessage id='cookieBannerDeclineButton' defaultMessage='Necessary cookies only' />
      </Button>
    </div>
  )
}

export default CookieBanner
