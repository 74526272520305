import he from 'he'

export const UNNAMED_TEAM = 'Unnamed Team'

export function isTeamUnnamed(rosterName) {
  return rosterName === UNNAMED_TEAM
}

export function getDisplayRosterName(rosterName, intl) {
  if (!rosterName) return

  return rosterName === UNNAMED_TEAM
    ? intl.formatMessage({ id: 'UnnamedTeamPlaceholder', defaultMessage: UNNAMED_TEAM })
    : he.decode(rosterName)
}
