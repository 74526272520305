import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

const useReferralCode = () => {
  const location = useLocation()

  useEffect(() => {
    const params = new URLSearchParams(location.search)
    const referralCode = params.get('referralCode')
    if (referralCode) {
      localStorage.setItem('referralCode', referralCode)
    }
  }, [location])
}

export default useReferralCode
