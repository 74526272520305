import React, { useState, useEffect, useCallback } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { Skeleton, Button } from 'antd-mobile'
import { FormattedMessage, useIntl } from 'react-intl'
import {
  ArrowRightIcon,
  BookOpenIcon,
  ChartBarSquareIcon,
  CurrencyDollarIcon,
  FireIcon,
  GiftIcon,
  UserGroupIcon,
} from '@heroicons/react/20/solid'
import EventHeaderPopup from './EventHeaderPopup'
import EventTop3 from './EventTop3'
import EventStatusText from './EventStatusText'
import { getDisplayRosterName, isTeamUnnamed } from '../../utils/userHelper'
import './EventHeader.css'

const EventHeader = props => {
  const {
    event,
    stage,
    lineupStats,
    performanceStats,
    isBalanceLoading,
    setPopupVisible,
    popupVisible,
  } = props
  const { balance: rawBalance, playerCounter } = lineupStats
  const balance = Number(rawBalance)
  const { playerRank, totalPlayers, totalPoints } = performanceStats
  const rosterName = useSelector(state => state.user?.rosterName)

  const intl = useIntl()
  const location = useLocation()
  const navigate = useNavigate()

  const [contentType, setContentType] = useState()
  const [pageTitle, setPageTitle] = useState()

  const handleActionButtons = useCallback(
    context => {
      setPopupVisible(true)
      setContentType(context)
      const queryParams = new URLSearchParams(location.search)
      queryParams.set('modal', context)
      navigate({ search: queryParams.toString() })
    },
    [navigate, location.search, setPopupVisible],
  )

  const closeModal = () => {
    setPopupVisible(false)
    setContentType(null)
    const queryParams = new URLSearchParams(location.search)
    queryParams.delete('modal')
    queryParams.delete('roster')
    navigate({ search: queryParams.toString() })
    document.title = pageTitle
  }

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search)
    const modal = queryParams.get('modal')
    if (modal) {
      setPopupVisible(true)
      setContentType(modal)
    } else {
      setPopupVisible(false)
      setContentType(null)
    }
    setPageTitle(window.document.title)
  }, [location.search, setPopupVisible])

  const hasAnyFinishedStages = event.stages?.some(eventStage => eventStage.status === 'ENDED')

  const renderTeamNameSection = () => (
    <div className='team_header__roster__name__row'>
      <h4>{getDisplayRosterName(rosterName, intl)}</h4>
      {isTeamUnnamed(rosterName) ? (
        <button
          onClick={() => navigate('/roster-name', { state: { from: location.pathname } })}
          type='button'
          className='button-secondary-small'
        >
          <FormattedMessage id='eventHeaderChangeRosterNameButton' defaultMessage='Change' />
        </button>
      ) : null}
    </div>
  )

  const renderPlayerRank = () => (
    <>
      <ChartBarSquareIcon className='team-header__stats__column__icon' />
      <h6>{playerRank}</h6>
      <p className='text-small color-white-80'>
        <FormattedMessage id='scoreboardOutOfLabel' defaultMessage='Out of' />
          &nbsp;
        {totalPlayers}
      </p>
    </>
  )

  const renderStatsTotalPoints = () => (
    <>
      <FireIcon className='team-header__stats__column__icon' />
      <h6>{totalPoints}</h6>
      <p className='text-small color-white-80'>
        <FormattedMessage id='scoreboardTotalPointsLabel' defaultMessage='Points' />
      </p>
    </>
  )

  const getStats = eventStatus => (
    eventStatus === 'ENDED' ? (
      <div className='team-header'>
        {renderTeamNameSection()}
        <div className='team-header__stats'>
          <div className='team-header__stats__column__status__ended'>
            {playerRank ? renderPlayerRank() : null}
          </div>
          <div className='team-header__stats__column__status__ended'>
            {renderStatsTotalPoints()}
          </div>
        </div>
      </div>
    ) : (
      <div className='team-header'>
        {renderTeamNameSection()}
        {/*<span className='badge'>Saved</span>*/}
        <div className='team-header__stats'>
          <div className='team-header__stats__column'>
            <UserGroupIcon className='team-header__stats__column__icon' />
            <h6>
              {playerCounter}
              &nbsp;/ 6
            </h6>
            <p className='text-small color-white-80'>
              <FormattedMessage id='scoreboardSelectedPlayersLabel' defaultMessage='Players' />
            </p>
          </div>

          <div className='team-header__stats__column'>
            <CurrencyDollarIcon className='team-header__stats__column__icon' />
            <h6 className={balance < 0 ? 'text-color-error' : ''}>
              { isBalanceLoading ? <Skeleton.Paragraph style={{ marginTop: '2px', marginBottom: '4px' }} lineCount={1} animated /> : (
                <>
                  $&nbsp;
                  {balance.toFixed(0)}
                </>
              )}
            </h6>
            <p className='text-small color-white-80'>
              <FormattedMessage id='scoreboardBalanceLabel' defaultMessage='Balance' />
            </p>
          </div>

          <div className='team-header__stats__column'>
            { playerRank ? renderPlayerRank() : null }
          </div>

          <div className='team-header__stats__column'>
            {renderStatsTotalPoints()}
          </div>
        </div>
      </div>
    )
  )

  return (
    <div className='event-header'>
      <div className='event-header__content'>
        <div className='event-header__content__title'>
          <p className='text-small bold color-gray-1'>
            <EventStatusText event={event} />
          </p>
          <h3>{event.name}</h3>
        </div>
        <div className='event-header__content__action-list'>
          <button
            onClick={() => handleActionButtons('prizes')}
            type='button'
            className='button-secondary-small'
          >
            <GiftIcon className='button-secondary-small__icon' />
            <FormattedMessage id='eventActionPrizes' defaultMessage='Prizes' />
          </button>
          <button
            onClick={() => handleActionButtons('rules')}
            type='button'
            className='button-secondary-small'
          >
            <BookOpenIcon className='button-secondary-small__icon' />
            <FormattedMessage id='eventActionRules' defaultMessage='Rules' />
          </button>
          <button
            onClick={() => handleActionButtons('leagues')}
            type='button'
            className='button-secondary-small'
          >
            <UserGroupIcon className='button-secondary-small__icon' />
            <FormattedMessage id='eventActionLeagues' defaultMessage='Leagues' />
          </button>
          <button
            onClick={() => handleActionButtons('standings')}
            type='button'
            className='button-secondary-small'
          >
            <ChartBarSquareIcon className='button-secondary-small__icon' />
            <FormattedMessage id='eventActionStandings' defaultMessage='Standings' />
          </button>
        </div>
        { event.status === 'ENDED' ? <EventTop3 eventId={event.id} />
          : (
            <div className='event-header__content__stage'>
              <h6 className='event-header__content__stage__stage-name'>{stage.name}</h6>
              <button
                onClick={() => handleActionButtons('matches')}
                type='button'
                className='button-arrow-link'
              >
                <FormattedMessage id='eventActionMatches' defaultMessage='See matches' />
                <ArrowRightIcon className='button-arrow-link__icon' />
              </button>
            </div>
          )}

        {event.status === 'PUBLISHED' ? null : (
          getStats(event.status)
        )}

        {hasAnyFinishedStages && event.status !== 'ENDED' && event.status !== 'PUBLISHED' ? (
          <Button
            onClick={() => handleActionButtons('performance')}
            className='full-width margin-top-24 button-secondary performance-button'
          >
            <FormattedMessage id='eventHeaderPerformanceButton' defaultMessage='See performance in past rounds' />
          </Button>
        ) : null}
      </div>

      <EventHeaderPopup
        event={event}
        contentType={contentType}
        setPopupVisible={setPopupVisible}
        popupVisible={popupVisible}
        onClose={closeModal}
      />
    </div>
  )
}

export default EventHeader
