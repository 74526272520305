import React, { useEffect, useState } from 'react'
import { Toast, Skeleton } from 'antd-mobile'
import { TrophyIcon, FireIcon } from '@heroicons/react/16/solid'
import { useIntl } from 'react-intl'
import { getDisplayRosterName } from '../../utils/userHelper'
import EventsService from '../../services/EventsService'
import './EventTop3.css'

const EventTop3 = props => {
  const { eventId } = props
  const intl = useIntl()

  const [top3, setTop3] = useState([])
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    const fetchRanking = async () => {
      try {
        const { data } = await EventsService.getTop3(eventId)
        setTop3(data)
      } catch (error) {
        Toast.show({
          icon: 'fail',
          content: error.response?.data?.message || error.message,
        })
      } finally {
        setIsLoading(false)
      }
    }
    fetchRanking()
  }, [eventId, setIsLoading])

  function localiseStanding(standing) {
    if (standing === 2) {
      return '2nd'
    }
    return '3rd'
  }

  return (
    isLoading ? (
      <Skeleton animated style={{ height: '253px', marginTop: '32px' }} />
    ) : (
      <div className='event-top-3'>
        { top3.map(row => (
          <div className='event-top-3__item' key={row.rosterId}>
            <div className='event-top-3__item__standing'>
              { row.ranking === 1 ? (
                <TrophyIcon className='event-top-3__item__standing__icon' />
              ) : (
                <span className='text-extra-small'>
                  {localiseStanding(row.ranking)}
                </span>
              )}
            </div>
            <div className='event-top-3__item__player-data'>
              <h6>{getDisplayRosterName(row.rosterName, intl)}</h6>
              <p className='color-gray-1'>{row.username}</p>
            </div>
            <div className='event-top-3__item__points'>
              <p className='text-small'>{Math.round(row.totalPoints)}</p>
              <FireIcon className='event-top-3__item__points__icon' />
            </div>
          </div>
        ))}
      </div>
    )
  )
}

export default EventTop3
