import React from 'react'
import { motion } from 'framer-motion'

const AnimatedRoute = ({ element, variants, fadeInTransition, fadeOutTransition }) => (
  <motion.div
    initial='initial'
    animate='in'
    exit='out'
    variants={variants}
    transition={{
      in: fadeInTransition,
      out: fadeOutTransition,
    }}
  >
    {element}
  </motion.div>
)

export default AnimatedRoute
