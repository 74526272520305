import React from 'react'
import { FormattedMessage } from 'react-intl'

const TermsOfService = () => (
  <div className='main-container justify-text'>
    <h2>
      <FormattedMessage id='termsOfService_title' defaultMessage='ClutchKing Terms of Service' />
    </h2>
    &nbsp;
    <h4>
      1.&nbsp;
      <FormattedMessage id='termsOfServiceArticle_1_subtitle' defaultMessage='Introduction' />
    </h4>
    <p>
      <FormattedMessage
        id='termsOfServiceArticle_1_content'
        defaultMessage='Welcome to ClutchKing! This document outlines the terms and conditions
      for using our fantasy league game.'
      />
    </p>
    <h4>
      2.&nbsp;
      <FormattedMessage
        id='termsOfServiceArticle_2_acceptanceOfTerms'
        defaultMessage='Acceptance of Terms'
      />
    </h4>
    <p>
      <FormattedMessage
        id='termsOfServiceArticle_2_content'
        defaultMessage='By creating an account and participating in the game, you agree to these terms.'
      />
    </p>
    <h4>
      3.&nbsp;
      <FormattedMessage
        id='termsOfServiceArticle_3_title'
        defaultMessage='Eligibility'
      />
    </h4>
    <p>
      3.1&nbsp;
      <FormattedMessage
        id='termsOfServiceArticle_3_1_content'
        defaultMessage={`Age Requirement: There is no age restriction for participation in ClutchKing.
        However, if you are under the age of 18 (or the age of legal
        majority in your jurisdiction),
        you confirm that you have received your parent's or legal guardian's
        permission to use the service.`}
      />
    </p>
    <p>
      3.2&nbsp;
      <FormattedMessage
        id='termsOfServiceArticle_3_2_content'
        defaultMessage='Account Registration: To be eligible to create an account and participate in
        ClutchKing, users must provide valid and current information as requested during the
        registration process, including a valid email address.'
      />
    </p>
    <p>
      3.3&nbsp;
      <FormattedMessage
        id='termsOfServiceArticle_3_3_content'
        defaultMessage='Responsibility: By using ClutchKing, you agree to be fully responsible for your
        activities on the platform. If you are using ClutchKing on behalf of a legal entity,
        you represent that you have the authority to bind the entity to these Terms.'
      />
    </p>
    <p>
      3.4&nbsp;
      <FormattedMessage
        id='termsOfServiceArticle_3_4_content'
        defaultMessage='Compliance with Laws: You must use ClutchKing in compliance with all applicable
        local, state, national, and international laws, rules, and regulations.'
      />

    </p>
    <p>
      3.5&nbsp;
      <FormattedMessage
        id='termsOfServiceArticle_3_5_content'
        defaultMessage='Prohibited Users: Individuals who have been suspended or removed from ClutchKing by the administrators for any reason are not eligible to use the service.'
      />
    </p>
    <p>
      3.6&nbsp;
      <FormattedMessage
        id='termsOfServiceArticle_3_6_content'
        defaultMessage='Geographical Restrictions: While ClutchKing aims to be accessible worldwide,
        the service may not be available or may be restricted in certain countries
        or regions where operation is prohibited or limited by local laws.'
      />
    </p>
    <h4>
      4.&nbsp;
      <FormattedMessage
        id='termsOfServiceArticle_4_title'
        defaultMessage='Account Registration'
      />
    </h4>
    <p>
      4.1&nbsp;
      <FormattedMessage
        id='termsOfServiceArticle_4_1_content'
        defaultMessage='Requirements for Registration: To participate in ClutchKing, users
        are required to register for an account. During registration, users
        must provide accurate and current information as requested, including
        but not limited to a valid email address, a unique username, and a
        team name. Please note that team names are not exclusive and can be
        used by multiple users.'
      />
    </p>
    <p>
      4.2&nbsp;
      <FormattedMessage
        id='termsOfServiceArticle_4_2_content'
        defaultMessage='Optional Information - Favorite Team: During the account registration
        process, users have the option, but are not obliged, to select a favorite
        Counter-Strike team. This information is purely optional and may be used
        for personalizing the user experience and for marketing purposes.
        Users can choose to not select a favorite team or change their selection
        at any time through their account settings.'
      />
    </p>
    <p>
      4.3&nbsp;
      <FormattedMessage
        id='termsOfServiceArticle_4_3_content'
        defaultMessage='Responsibility for Account Information: Users are responsible for
        maintaining the confidentiality of their account information, including
        their password, and for all activities that occur under their account.
        Users agree to immediately notify ClutchKing of any unauthorized use of
        their account or any other breach of security.'
      />
    </p>
    <p>
      4.4&nbsp;
      <FormattedMessage
        id='termsOfServiceArticle_4_4_content'
        defaultMessage='Updating Account Information: It is the responsibility of the user
        to keep their account information, including their email address, up to
        date. ClutchKing is not responsible for any issues that arise due to
        outdated or incorrect account information.'
      />
    </p>
    <p>
      4.5&nbsp;
      <FormattedMessage
        id='termsOfServiceArticle_4_5_content'
        defaultMessage='Acceptance of Terms: By completing the registration process, users
        agree to comply with these Terms of Service and confirm that the
        information they provide is truthful and accurate to the best of their knowledge.'
      />
    </p>
    <h4>
      5.&nbsp;
      <FormattedMessage
        id='termsOfServiceArticle_5_title'
        defaultMessage='User Conduct and Prohibited Actions'
      />
    </h4>
    <p>
      5.1&nbsp;
      <FormattedMessage
        id='termsOfServiceArticle_5_1_item_a'
        defaultMessage='General Conduct: Users must adhere to acceptable standards of behavior.
        Prohibited actions include, but are not limited to:'
      />
    </p>
    <ul>
      <li>
        <FormattedMessage
          id='termsOfServiceArticle_5_1_item_b'
          defaultMessage='Abuse, harassment, or bullying of other users.'
        />
      </li>
      <li>
        <FormattedMessage
          id='termsOfServiceArticle_5_1_item_c'
          defaultMessage='Cheating, fraud, and use of unauthorized software or bots.'
        />
      </li>
      <li>
        <FormattedMessage
          id='termsOfServiceArticle_5_1_item_d'
          defaultMessage='Impersonation of other players or staff.'
        />
      </li>
      <li>
        <FormattedMessage
          id='termsOfServiceArticle_5_1_item_e'
          defaultMessage='Creating or posting inappropriate content.'
        />
      </li>
      <li>
        <FormattedMessage
          id='termsOfServiceArticle_5_1_item_f'
          defaultMessage='Intellectual property infringement.'
        />
      </li>
      <li>
        <FormattedMessage
          id='termsOfServiceArticle_5_1_item_g'
          defaultMessage='Spamming and unsolicited advertisements.'
        />
      </li>
      <li>
        <FormattedMessage
          id='termsOfServiceArticle_5_1_item_h'
          defaultMessage='Unauthorized access, hacking, and distribution of harmful software.'
        />
      </li>
      <li>
        <FormattedMessage
          id='termsOfServiceArticle_5_1_item_i'
          defaultMessage='Exploiting bugs or glitches for unfair advantage.'
        />
      </li>
      <li>
        <FormattedMessage
          id='termsOfServiceArticle_5_1_item_j'
          defaultMessage='False reporting and abuse of the reporting system.'
        />
      </li>
      <li>
        <FormattedMessage
          id='termsOfServiceArticle_5_1_item_k'
          defaultMessage='Mishandling of others personal information.'
        />
      </li>
      <li>
        <FormattedMessage
          id='termsOfServiceArticle_5_1_item_l'
          defaultMessage='Engaging in illegal activities or violating laws.'
        />
      </li>
    </ul>
    <h4>
      5.2&nbsp;
      <FormattedMessage
        id='termsOfServiceArticle_5_2_title'
        defaultMessage='Prohibition of Multiple Accounts for Unfair Advantage:'
      />
    </h4>
    <p>
      <FormattedMessage
        id='termsOfServiceArticle_5_2_content'
        defaultMessage='Users are not permitted to create and use multiple accounts for the purpose of
        gaining an unfair advantage in the game. This includes, but is not limited to,
        manipulating rankings, scores, or events within ClutchKing. Violation of this
        rule may result in immediate suspension or termination of all associated
        accounts.'
      />
    </p>
    <h4>
      5.3&nbsp;
      <FormattedMessage
        id='termsOfServiceArticle_5_3_title'
        defaultMessage='Record of Prohibition:'
      />
    </h4>
    <p>
      <FormattedMessage
        id='termsOfServiceArticle_5_3_parag_1'
        defaultMessage={`In the event a user is prohibited or banned from using ClutchKing due to
        violations of these Terms, ClutchKing reserves the right to maintain a minimal
        record of the user's infringement. This record will include only necessary
        information such as the user's identifier and the reason for prohibition.
        This record is maintained to uphold the integrity of ClutchKing, to prevent
        circumvention of the platform's rules, and to enforce our Terms of Service.`}
      />
    </p>
    <p>
      <FormattedMessage
        id='termsOfServiceArticle_5_3_parag_3'
        defaultMessage='Such records will be processed and retained in accordance with applicable
        data protection laws and principles, particularly those pertaining to data
        minimization and purpose limitation.'
      />
    </p>
    <p>
      <FormattedMessage
        id='termsOfServiceArticle_5_3_parag_4'
        defaultMessage='Users who have been prohibited from using the service and have had their
        personal data erased may be denied re-registration based on this retained
        record.'
      />
    </p>
    <h4>
      6.&nbsp;
      <FormattedMessage
        id='termsOfServiceArticle_6_title'
        defaultMessage='User-Generated Content and Team Names'
      />
    </h4>
    <p>
      <FormattedMessage
        id='termsOfServiceArticle_6_content'
        defaultMessage='Users retain ownership rights over their user-generated content.
        However, by creating this content within ClutchKing, users grant ClutchKing
        a perpetual, irrevocable, non-exclusive,
        worldwide, royalty-free license to use, reproduce, adapt, modify, publish, and
        display such content for any purpose related to the game and its promotion.
        This license continues even if the user discontinues
        their use of our services or deletes their account.'
      />
    </p>
    <h4>
      7.&nbsp;
      <FormattedMessage
        id='termsOfServiceArticle_7_title'
        defaultMessage='Gameplay and Events'
      />
    </h4>
    <p>
      7.1&nbsp;
      <FormattedMessage
        id='termsOfServiceArticle_7_1'
        defaultMessage='Game Mechanics: ClutchKing is a fantasy league game where users can buy and
        sell players for their team using fantasy money. The performance of these players
        in real-life Counter-Strike tournaments determines their scores in the game.'
      />
    </p>
    <p>
      7.2&nbsp;
      <FormattedMessage
        id='termsOfServiceArticle_7_2'
        defaultMessage='Participation in Events: Users can choose to participate in various events within
        ClutchKing. Participation is voluntary, and users decide which events they wish
        to engage in.'
      />
    </p>
    <p>
      7.3&nbsp;
      <FormattedMessage
        id='termsOfServiceArticle_7_3'
        defaultMessage='Scoring and Ranking: Player scores are based on their performance
        in specific real-world tournaments. These scores will be used to rank users
        within ClutchKing’s league system.'
      />
    </p>
    <p>
      7.4&nbsp;
      <FormattedMessage
        id='termsOfServiceArticle_7_4'
        defaultMessage='Fair Play: Users are expected to play fairly and respect the rules of the game.
        Any form of cheating, use of unauthorized tools, or manipulation of scores is strictly
        prohibited and may result in account suspension or termination.'
      />
    </p>
    <p>
      7.5&nbsp;
      <FormattedMessage
        id='termsOfServiceArticle_7_5'
        defaultMessage='Changes to Events: ClutchKing reserves the right to modify,
        cancel, or suspend events at any time due to unforeseen circumstances or
        for operational reasons. Users will be notified of significant changes
        to events where possible.'
      />
    </p>
    <p>
      7.6&nbsp;
      <FormattedMessage
        id='termsOfServiceArticle_7_6'
        defaultMessage='Rewards and Prizes: Top-ranked users may be eligible for rewards or prizes
        as determined by ClutchKing. Details of such rewards will be communicated
        through the platform. Eligibility for rewards may be subject to additional
        terms and conditions.'
      />
    </p>
    <p>
      7.7&nbsp;
      <FormattedMessage
        id='termsOfServiceArticle_7_7'
        defaultMessage='No Real Money Gambling: It is important to note that ClutchKing does not involve
        real money gambling. The fantasy money used in the game cannot be exchanged
        for real currency or monetary value.'
      />
    </p>
    <p>
      7.8&nbsp;
      <FormattedMessage
        id='termsOfServiceArticle_7_8'
        defaultMessage='User Responsibility: Users are responsible for managing their teams and
        participation in events. ClutchKing is not responsible for any decisions or
        actions taken by users that may affect their standing or scores in the game.'
      />
    </p>
    <p>
      7.9&nbsp;
      <FormattedMessage
        id='termsOfServiceArticle_7_9'
        defaultMessage='User-Created Leagues: Leagues created by users do not offer prizes. Any leagues
        or competitions organized by users are solely for entertainment purposes and are not
        eligible for official rewards or prizes from ClutchKing. The creator of each league is solely
        responsible for its content, management, and any disputes arising from it. ClutchKing is not
        liable for any actions, content, or claims related to user-created leagues.'
      />
    </p>
    <h4>
      8.&nbsp;
      <FormattedMessage
        id='termsOfServiceArticle_8_title'
        defaultMessage='Service Interruptions'
      />
    </h4>
    <p>
      <FormattedMessage
        id='termsOfServiceArticle_8_content'
        defaultMessage='ClutchKing reserves the right to cancel leagues or events due to
        unforeseen circumstances, without prior notice or liability.'
      />
    </p>
    <h4>
      9.&nbsp;
      <FormattedMessage
        id='termsOfServiceArticle_9_title'
        defaultMessage='Dispute Resolution'
      />
    </h4>
    <p>
      <p>
        <FormattedMessage
          id='termsOfServiceArticle_9_content_1'
          defaultMessage='In the event of a dispute, users are encouraged to first contact
          ClutchKing customer support for resolution.'
        />
      </p>
      <p>
        <FormattedMessage
          id='termsOfServiceArticle_9_content_2'
          defaultMessage='If the dispute cannot be resolved internally, it will be settled by
          binding arbitration conducted online under Brazilian law.'
        />
      </p>
      <p>
        <FormattedMessage
          id='termsOfServiceArticle_9_content_3'
          defaultMessage='Claims must be brought within 1-year of the incident.'
        />
      </p>
      <p>
        <FormattedMessage
          id='termsOfServiceArticle_9_content_4'
          defaultMessage='Users agree to bring claims only on an individual basis and not as part of
          any class action or collective proceeding.'
        />
      </p>
      <p>
        <FormattedMessage
          id='termsOfServiceArticle_9_content_5'
          defaultMessage='Liability of ClutchKing is limited to the fullest extent permitted by applicable law.'
        />
      </p>
      <p>
        <FormattedMessage
          id='termsOfServiceArticle_9_content_6'
          defaultMessage='Users agree to indemnify ClutchKing against all claims,
          damages, and expenses arising from their use of the service.'
        />
      </p>
      <p>
        <FormattedMessage
          id='termsOfServiceArticle_9_content_7'
          defaultMessage='All disputes will be governed by the laws of Brazil.'
        />
      </p>
    </p>
    <h4>
      10.&nbsp;
      <FormattedMessage
        id='termsOfServiceArticle_10_title'
        defaultMessage='Intellectual Property Rights'
      />
    </h4>
    <p>
      <FormattedMessage
        id='termsOfServiceArticle_10_content'
        defaultMessage='All content on ClutchKing, including text, graphics, and logos, is the property of
        ClutchKing or its licensors and is protected by intellectual property laws.'
      />
    </p>
    <h4>
      11.&nbsp;
      <FormattedMessage
        id='termsOfServiceArticle_11_title'
        defaultMessage='Disclaimer of Warranties'
      />
    </h4>
    <p>
      <FormattedMessage
        id='termsOfServiceArticle_11_content'
        defaultMessage={`The service is provided 'as is' and 'as available' without&nbsp;
        any warranties, express or implied.`}
      />
    </p>
    <h4>
      12.&nbsp;
      <FormattedMessage
        id='termsOfServiceArticle_12_title'
        defaultMessage='Limitation on Liability'
      />
    </h4>
    <p>
      <FormattedMessage
        id='termsOfServiceArticle_12_content'
        defaultMessage='ClutchKing is not liable for indirect, incidental, special,
        consequential, or punitive damages.'
      />
    </p>
    <h4>
      13.&nbsp;
      <FormattedMessage
        id='termsOfServiceArticle_13_title'
        defaultMessage='Updates and Maintenance'
      />
    </h4>
    <p>
      <FormattedMessage
        id='termsOfServiceArticle_13_content'
        defaultMessage='The service may undergo maintenance that may result in temporary downtime, and
        ClutchKing is not liable for any disruption this causes.'
      />
    </p>
    <h4>
      14.&nbsp;
      <FormattedMessage
        id='termsOfServiceArticle_14_title'
        defaultMessage='Termination of Accounts'
      />
    </h4>
    <p>
      14.1&nbsp;
      <FormattedMessage
        id='termsOfServiceArticle_14_1'
        defaultMessage={`Right to Terminate: ClutchKing reserves the right to suspend or terminate a user's
        account and access to the game and its services at any time, with or without notice, for
        conduct that ClutchKing believes violates these Terms of Service or is harmful to other
        users of ClutchKing, to ClutchKing's business, or to other third parties.`}
      />
    </p>
    <p>
      14.2&nbsp;
      <FormattedMessage
        id='termsOfServiceArticle_14_2'
        defaultMessage='Reasons for Termination: Reasons for account termination may
        include, but are not limited to:'
      />
    </p>
    <ul>
      <li>
        <FormattedMessage
          id='termsOfServiceArticle_14_2_li_1'
          defaultMessage='Repeated violations of the Terms of Service.'
        />
      </li>
      <li>
        <FormattedMessage
          id='termsOfServiceArticle_14_2_li_2'
          defaultMessage='Illegal or fraudulent activity.'
        />
      </li>
      <li>
        <FormattedMessage
          id='termsOfServiceArticle_14_2_li_3'
          defaultMessage='Providing false or misleading information during account registration
          or in the course of using the game.'
        />
      </li>
    </ul>
    <p>
      14.3&nbsp;
      <FormattedMessage
        id='termsOfServiceArticle_14_3'
        defaultMessage={`Process of Termination: In the event of termination,
        users will be notified using the email
        address provided at registration. Users will have the opportunity
        to download a copy of their data,
        subject to ClutchKing's data retention policies.`}
      />
    </p>
    <p>
      14.4&nbsp;
      <FormattedMessage
        id='termsOfServiceArticle_14_4'
        defaultMessage='Effect of Termination: Upon termination, all rights granted
        to the user under the Terms of Service will immediately cease. Users must
        stop all use of the game and any of its services. ClutchKing is not responsible
        for any loss or harm related to the termination of an account.'
      />
    </p>
    <p>
      14.5&nbsp;
      <FormattedMessage
        id='termsOfServiceArticle_14_5'
        defaultMessage='Appeal Process: If a user believes their account has been terminated in error,
        they may contact ClutchKing at support@clutchking.com.br to appeal the decision.
        ClutchKing will review the appeal and respond within a reasonable timeframe.'
      />
    </p>
    <p>
      14.6&nbsp;
      <FormattedMessage
        id='termsOfServiceArticle_14_6'
        defaultMessage='Account Deactivation by User:
        Users may choose to deactivate their account at any time.
        Instructions for account deactivation will be available within the game settings or
        by contacting customer support.'
      />
    </p>
    <h4>
      15.&nbsp;
      <FormattedMessage
        id='termsOfServiceArticle_15'
        defaultMessage='Links to Other Websites'
      />
    </h4>
    <p>
      15.1.&nbsp;
      <FormattedMessage
        id='termsOfServiceArticle_15_1'
        defaultMessage='External Links: ClutchKing may contain links to third-party websites or
        services that are not owned or controlled by ClutchKing. These links are
        provided for your convenience and to enhance your user experience.'
      />
    </p>
    <p>
      15.2&nbsp;
      <FormattedMessage
        id='termsOfServiceArticle_15_2'
        defaultMessage='No Endorsement: The inclusion of any links does not imply endorsement,
        sponsorship, or recommendation by ClutchKing of the third-party websites,
        their content, services, or practices.'
      />
    </p>
    <p>
      15.3&nbsp;
      <FormattedMessage
        id='termsOfServiceArticle_15_3'
        defaultMessage='Disclaimer of Responsibility: ClutchKing has no control over,
        and assumes no responsibility for, the content, privacy policies, or
        practices of any third-party websites or services. You acknowledge and
        agree that ClutchKing shall not be responsible or liable, directly or
        indirectly, for any damage or loss caused or alleged to be caused by or
        in connection with the use of or reliance on any such content, goods,
        or services available on or through any such websites or services.'
      />
    </p>
    <p>
      15.4&nbsp;
      <FormattedMessage
        id='termsOfServiceArticle_15_4'
        defaultMessage='User Discretion Advised: We encourage you to be aware when you
        leave our site and to read the terms and conditions and privacy policy
        of each other website that you visit. Your interactions with these features
        are governed by the privacy policy and other policies of the companies
        providing them.'
      />
    </p>
    <h4>
      16.&nbsp;
      <FormattedMessage
        id='termsOfServiceArticle_16_title'
        defaultMessage='Changes to the Service'
      />
    </h4>
    <p>
      <FormattedMessage
        id='termsOfServiceArticle_16_content'
        defaultMessage='ClutchKing reserves the right to modify or discontinue the
        service at any time without notice.'
      />
    </p>
    <h4>
      17.&nbsp;
      <FormattedMessage
        id='termsOfServiceArticle_17_title'
        defaultMessage='Governing Law'
      />
    </h4>
    <p>
      <FormattedMessage
        id='termsOfServiceArticle_17_content'
        defaultMessage='These Terms are governed by the laws of Brazil.'
      />
    </p>
    <h4>
      18.&nbsp;
      <FormattedMessage
        id='termsOfServiceArticle_18_title'
        defaultMessage='Severability'
      />
    </h4>
    <p>
      <FormattedMessage
        id='termsOfServiceArticle_18_content'
        defaultMessage='If any provision of these Terms is held to be invalid or unenforceable,
        the remaining provisions will remain in effect.'
      />
    </p>
    <h4>
      19.&nbsp;
      <FormattedMessage
        id='termsOfServiceArticle_19_title'
        defaultMessage='Entire Agreement'
      />
    </h4>
    <p>
      <FormattedMessage
        id='termsOfServiceArticle_19_content'
        defaultMessage='These Terms constitute the entire agreement between the user and ClutchKing.'
      />
    </p>
    <h4>
      20.&nbsp;
      <FormattedMessage
        id='termsOfServiceArticle_20_title'
        defaultMessage='Amendments to Terms'
      />
    </h4>
    <p>
      20.1&nbsp;
      <FormattedMessage
        id='termsOfServiceArticle_20_1'
        defaultMessage='Notification of Changes: ClutchKing reserves the right to modify or
        change these Terms of Service at any time. When significant changes are made,
        users will be notified via email, provided they have confirmed their email
        address with ClutchKing.'
      />
    </p>
    <p>
      20.2&nbsp;
      <FormattedMessage
        id='termsOfServiceArticle_20_2'
        defaultMessage='Confirmation of Email Address: It is the responsibility of
        the user to confirm their email address with ClutchKing to ensure receipt
        of such notifications. Users who have not confirmed their email address will
        not receive direct notifications of changes, although updates will still be
        applicable to them. It is recommended that users regularly check their email
        and the ClutchKing website for any updates to the Terms of Service.'
      />
    </p>
    <p>
      20.3&nbsp;
      <FormattedMessage
        id='termsOfServiceArticle_20_3'
        defaultMessage='Effective Date of Changes: Changes or amendments to these Terms will
        become effective immediately upon posting on our website or at a time specified
        in the notification email. Continued use of ClutchKing after any such changes
        constitutes your consent to such changes.'
      />
    </p>
    <p>
      20.4&nbsp;
      <FormattedMessage
        id='termsOfServiceArticle_20_4'
        defaultMessage='User Responsibility: Users are encouraged to periodically review the
        Terms of Service to stay informed of our updates. Your continued use of
        ClutchKing following the posting of revised Terms of Service means that
        you accept and agree to the changes.'
      />
    </p>
    <h4>
      21.&nbsp;
      <FormattedMessage
        id='termsOfServiceArticle_21_title'
        defaultMessage='Contact Information'
      />
    </h4>
    <p>
      <FormattedMessage
        id='termsOfServiceArticle_21_content'
        defaultMessage='For inquiries: questions@clutchking.com.br'
      />
    </p>
  </div>
)

export default TermsOfService
