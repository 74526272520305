import React, { useEffect } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { useDispatch, connect } from 'react-redux'
import { setUser } from '../../redux/actions/userAction'

const getCookie = name => {
  const value = `; ${document.cookie}`
  const parts = value.split(`; ${name}=`)
  if (parts.length === 2) return parts.pop().split(';').shift()
}

const getUserDataFromCookie = () => {
  const userDataCookie = getCookie('login')
  if (userDataCookie) {
    try {
      const decodedCookie = decodeURIComponent(userDataCookie)
      return JSON.parse(decodedCookie)
    } catch (error) {
      console.error('Error parsing userDataCookie', error)
    }
  }
  return null
}

const AuthCallback = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const location = useLocation()

  useEffect(() => {
    const user = getUserDataFromCookie()
    if (user) {
      const searchParams = new URLSearchParams(location.search)
      const redirectTo = searchParams.get('to')
      dispatch(setUser(user))

      if (user.type === 'login') {
        window.gtag('event', 'login', {
          event_category: 'User',
          event_label: 'User login with Google',
          value: 1,
        })
        navigate(redirectTo || '/events')
      } else {
        window.gtag('event', 'sign_up', {
          event_category: 'User',
          event_label: 'Account Created with Google',
          value: 1,
        })
        localStorage.removeItem('referralCode')
        navigate(redirectTo || '/favourite-team')
      }
    } else {
      navigate('/create-account?error=true')
    }
  }, [navigate, dispatch, location.search])
}

export default AuthCallback
