/* eslint-disable default-param-last */
import { initialState } from './initialState'

const eventsReducer = (state = initialState.events, action) => {
  switch (action.type) {
  case 'FETCH_EVENTS_REQUEST':
    return state
  case 'FETCH_EVENTS_SUCCESS':
    return {
      ...state,
      events: action.payload,
      error: null,
    }
  case 'FETCH_EVENTS_FAILURE':
    return {
      ...state,
      error: action.payload,
    }
  default:
    return state
  }
}

export default eventsReducer
