import React from 'react'
import { Navigate, useLocation } from 'react-router-dom'
import http from '../http-common'

const PrivateRoute = ({ element: Element, user, ...rest }) => {
  const location = useLocation()
  if (!user) {
    return <Navigate to='/login' state={{ from: location }} />
  }

  http.defaults.headers.common.Authorization = `Bearer ${user.accessToken}`

  return <Element {...rest} />
}

export default PrivateRoute
