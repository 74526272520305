import React from 'react'
import { Link } from 'react-router-dom'
import { FormattedMessage, useIntl } from 'react-intl'
import { PencilSquareIcon, PencilIcon, LockClosedIcon, StarIcon, EnvelopeIcon } from '@heroicons/react/24/outline'
import SEO from '../SEO'

const Settings = () => {
  const intl = useIntl()
  return (
    <>
      <SEO
        pageTitle={intl.formatMessage({ id: 'menuSettingsTitle', defaultMessage: 'Settings' })}
        pageDescription={intl.formatMessage({ id: 'menuSettingsTitle', defaultMessage: 'Settings' })}
      />
      <div className='main-menu'>
        <div className='main-menu__menu-list no-padding'>
          <h3 className='margin-bottom-16'>
            <FormattedMessage id='menuSettingsTitle' defaultMessage='Settings' />
          </h3>

          <div>
            <Link
              to='/edit-username'
              className='main-menu__menu-list__menu-item'
            >
              <PencilIcon className='main-menu__menu-list__menu-item__icon' />
              <p>
                <FormattedMessage id='menuSettingsEditUsernameItem' defaultMessage='Edit your username' />
              </p>
            </Link>
          </div>
          <div>
            <Link
              to='/roster-name'
              className='main-menu__menu-list__menu-item'
            >
              <PencilSquareIcon className='main-menu__menu-list__menu-item__icon' />
              <p className='main-menu__menu-list__menu-item__label'>
                <FormattedMessage id='menuSettingsTeamNameMenuItem' defaultMessage='Rename your team' />
              </p>
            </Link>
          </div>
          <div>
            <Link
              to='/change-password'
              className='main-menu__menu-list__menu-item'
            >
              <LockClosedIcon className='main-menu__menu-list__menu-item__icon' />
              <p className='main-menu__menu-list__menu-item__label'>
                <FormattedMessage
                  id='menuSettingsEditPasswordMenuItem'
                  defaultMessage='Change password'
                />
              </p>
            </Link>
          </div>
          <div>
            <Link
              to='/favourite-team'
              className='main-menu__menu-list__menu-item'
            >
              <StarIcon className='main-menu__menu-list__menu-item__icon' />
              <p className='main-menu__menu-list__menu-item__label'>
                <FormattedMessage
                  id='menuSettingsEditFavouriteTeamMenuItem'
                  defaultMessage='Change favourite team'
                />
              </p>
            </Link>
          </div>
          <div>
            <Link
              to='/email-settings'
              className='main-menu__menu-list__menu-item'
            >
              <EnvelopeIcon className='main-menu__menu-list__menu-item__icon' />
              <p className='main-menu__menu-list__menu-item__label'>
                <FormattedMessage
                  id='menuSettingsEmailSettingsMenuItem'
                  defaultMessage='Email settings'
                />
              </p>
            </Link>
          </div>
        </div>
      </div>
    </>
  )
}

export default Settings
