import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { Skeleton, Button } from 'antd-mobile'
import { FormattedMessage, useIntl } from 'react-intl'
import { HeartIcon, ChatBubbleOvalLeftIcon } from '@heroicons/react/24/outline'
import { HeartIcon as HeartSolidIcon } from '@heroicons/react/24/solid'
import { useLanguage } from '../../LanguageContext'
import AnnouncementService from '../../services/AnnouncementService'
import dateUtils from '../../utils/dateUtils'
import SEO from '../SEO'
import './Announcements.css'

const mapStateToProps = state => ({ user: state.user })

const Announcements = ({ user }) => {
  const { locale } = useLanguage()
  const intl = useIntl()
  const [announcements, setAnnouncements] = useState([])
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    const fetchAnnouncements = async () => {
      try {
        const response = await AnnouncementService.getAllAnnouncements()
        setAnnouncements(response.data)
        setLoading(false)
      } catch (error) {
        console.error('Error fetching announcements:', error)
        setLoading(false)
      }
    }

    fetchAnnouncements()
  }, [])

  const getTranslation = translations => {
    const translation = translations.find(t => t.language === locale)
    if (translation) {
      return translation
    }
    return translations.find(t => t.language === 'en') || {}
  }

  const renderAnnouncements = () => {
    if (loading) {
      return <Skeleton animated style={{ height: '200px', marginBottom: '24px' }} />
    }

    if (!announcements || announcements.length === 0) {
      return <FormattedMessage id='announcementNotFoundPlaceholderMsg' defaultMessage='No announcements' />
    }

    const isAnnouncementLiked = announcement => (
      user && announcement
        ? announcement.likes?.some(like => like.userId === user.id)
        : null
    )

    const handleLike = async announcement => {
      try {
        const hasLikedAnnouncement = isAnnouncementLiked(announcement)

        await AnnouncementService.likeAnnouncement(announcement.id)

        if (hasLikedAnnouncement) {
          setAnnouncements(prevAnnouncements => prevAnnouncements
            .map(announc => (announc.id === announcement.id
              ? { ...announc, likes: announc.likes.filter(like => like.userId !== user.id) }
              : announc
            )))
        } else {
          setAnnouncements(prevAnnouncements => prevAnnouncements
            .map(announc => (announc.id === announcement.id
              ? { ...announc, likes: [...announc.likes, { userId: user.id }] }
              : announc
            )))
        }
      } catch (error) {
        console.error('Error toggling like on announcement:', error)
      }
    }

    return (
      <div className='announcements-list margin-bottom-24 display-grid'>
        {announcements.map(announcement => {
          const translatedAnnouncement = getTranslation(announcement.translations)
          return (
            <div className='announcement-card-container col-span-4' key={announcement.id}>
              <Link to={`/announcements/${announcement.slug}`} key={announcement.id} className='announcement-card'>
                <div className='announcement-card__header'>
                  <h6>
                    {translatedAnnouncement.title}
                  </h6>
                  <p className='text-small color-white-80 margin-bottom-8'>
                    {dateUtils.formatDateAndTime(announcement.createdAt, locale).both}
                  </p>
                </div>
                <div className='announcement-card__body'>
                  <p>
                    {translatedAnnouncement.body.slice(0, 100)}
                    ...
                  </p>
                </div>
              </Link>
              <div className='announcement-card__footer'>
                <Button
                  className='announcement-card__footer__button'
                  onClick={() => handleLike(announcement)}
                >
                  {isAnnouncementLiked(announcement)
                    ? <HeartSolidIcon className='announcement-card__footer__button__icon icon-liked' />
                    : <HeartIcon className='announcement-card__footer__button__icon' />}
                  {announcement.likes?.length ? announcement.likes?.length : null }
                </Button>
                <span className='announcement-card__footer__button'>
                  <Link
                    to={`/announcements/${announcement.slug}`}
                    key={announcement.id}
                  >
                    <ChatBubbleOvalLeftIcon className='announcement-card__footer__button__icon' />
                    {announcement.repliesCount ? announcement.repliesCount : null }
                  </Link>
                </span>
              </div>
            </div>
          )
        })}
      </div>
    )
  }

  return (
    <>
      <SEO
        pageTitle={intl.formatMessage({ id: 'announcementTitle', defaultMessage: 'Announcements' })}
        pageDescription={intl.formatMessage({ id: 'announcementTitle', defaultMessage: 'Announcements' })}
      />
      <div className='main-container'>
        <h3 className='margin-bottom-24'>
          <FormattedMessage id='announcementTitle' defaultMessage='Announcements' />
        </h3>
        {renderAnnouncements()}
      </div>
    </>
  )
}

export default connect(mapStateToProps)(Announcements)
