import http from '../http-common'

class LeagueService {
  getAllLeagues = () => (
    http.get('/leagues')
  )

  getMyLeagues = () => (
    http.get('/leagues/me')
  )

  getLeagueDetails = leagueId => (
    http.get(`/leagues/${leagueId}`)
  )

  createLeague = data => (
    http.post('/leagues', data)
  )

  updateLeague = (leagueId, data) => (
    http.put(`/leagues/${leagueId}`, data)
  )

  removePlayer = (leagueId, playerId) => (
    http.delete(`/leagues/${leagueId}/remove/${playerId}`)
  )

  joinLeague = leagueId => (
    http.post(`/leagues/${leagueId}/join`)
  )

  leaveLeague = leagueId => (
    http.post(`/leagues/${leagueId}/leave`)
  )

  deleteLeague = leagueId => (
    http.delete(`/leagues/${leagueId}`)
  )

  requestToJoin = leagueId => (
    http.post(`/leagues/${leagueId}/request`)
  )

  accept = data => (
    http.post('/leagues/accept', data)
  )

  refuse = data => (
    http.post('/leagues/refuse', data)
  )

  getUserInvites = () => (
    http.get('/leagues/invites')
  )
}

export default new LeagueService()
