import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import { Button, Skeleton } from 'antd-mobile'
import { EnvelopeOpenIcon } from '@heroicons/react/24/outline'
import { FormattedMessage } from 'react-intl'
import dateUtils from '../utils/dateUtils'
import './Notifications.css'

const Notifications = props => {
  const { notifications, loadingNotifications, setIsNotificationsOpen, locale } = props
  const location = useLocation()

  const getTranslation = translations => {
    const translation = translations.find(t => t.language === locale)
    if (translation) {
      return translation
    }
    return translations.find(t => t.language === 'en') || {}
  }

  const localiseNotificationType = type => {
    switch (type) {
    case 'new_event':
      return <FormattedMessage id='notificationsNewEvent' defaultMessage='New tournament' />
    case 'event_update':
      return <FormattedMessage id='notificationsEventUpdate' defaultMessage='Tournament update' />
    case 'market_open':
      return <FormattedMessage id='notificationsMarketOpen' defaultMessage='Market open' />
    case 'event_finished':
      return <FormattedMessage id='notificationsEventFinished' defaultMessage='Tournament ended' />
    case 'league_invite':
      return <FormattedMessage id='notificationsLeagueInvitation' defaultMessage='League invitation' />
    case 'league_join_request':
      return <FormattedMessage id='notificationsLeagueRequest' defaultMessage='League request' />
    case 'announcement':
      return <FormattedMessage id='notificationsAnnouncement' defaultMessage='Announcement' />
    default:
      return null
    }
  }

  const handleActionClick = (e, actionUrl) => {
    if (location.pathname === actionUrl) {
      e.preventDefault()
      setIsNotificationsOpen(false)
    }
  }

  const renderNotifications = () => {
    if (loadingNotifications) {
      return (
        <>
          <Skeleton animated style={{ height: '136px', marginBottom: '8px', marginTop: '32px' }} />
          <Skeleton animated style={{ height: '136px', marginBottom: '8px' }} />
          <Skeleton animated style={{ height: '136px', marginBottom: '8px' }} />
        </>
      )
    }

    if (notifications.length === 0) {
      return (
        <div className='notifications'>
          <div className='notifications__empty-state'>
            <EnvelopeOpenIcon className='notifications__empty-state__icon' />
            <h4>
              <FormattedMessage id='LeaguesNoneNotificationsTitle' defaultMessage='You are all caught up' />
            </h4>
            <p>
              <FormattedMessage id='LeaguesNoneNotificationsMsg' defaultMessage='There are no notifications for you at the moment.' />
            </p>
          </div>
          <Button className='button-primary full-width' onClick={() => setIsNotificationsOpen(false)}>
            <FormattedMessage id='LeaguesNotificationsCloseButton' defaultMessage='Close' />
          </Button>
        </div>
      )
    }

    return (
      <div className='notifications'>
        { notifications.length ? (
          <div className='margin-bottom-32'>
            <h4 className='margin-bottom-16'>
              <FormattedMessage id='notificationsTitle' defaultMessage='Notifications' />
            </h4>
            { notifications.map(notification => {
              const { type, actionUrl, translations, createdAt } = notification
              const localisedNotification = getTranslation(translations)
              const { message, action } = localisedNotification
              return (
                <Link
                  to={actionUrl}
                  key={notification.id}
                  className='leagues-list__link'
                  onClick={(e) => handleActionClick(e, actionUrl)}
                >
                  <div className='notifications__card'>
                    <div className='notifications__card__content'>
                      <div className='notifications__card__content__text'>
                        <p className='color-white-80'>{localiseNotificationType(type)}</p>
                        <p className='text-extra-small color-white-80 margin-bottom-8'>
                          {dateUtils.formatDateAndTime(createdAt, locale).dayMonthYear}
                        </p>
                        <h6>{message}</h6>
                      </div>
                    </div>
                    <div className='notifications__card__actions'>
                      <Button className='player-card__action__button'>
                        {action}
                      </Button>
                    </div>
                  </div>
                </Link>
              )
            })}
          </div>
        ) : null }
      </div>
    )
  }

  return (
    renderNotifications()
  )
}

export default Notifications
