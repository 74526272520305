import http from '../http-common'

class LeagueService {
  getAllAnnouncements = () => (
    http.get('/announcements')
  )

  getAnnouncementById = announcementId => (
    http.get(`/announcements/${announcementId}`)
  )

  replyToAnnouncement = (announcementId, reply) => (
    http.post(`/announcements/${announcementId}/reply`, reply)
  )

  likeAnnouncement = announcementId => (
    http.post(`/announcements/${announcementId}/like`)
  )

  likeAnnouncementReply = replyId => (
    http.post(`/announcements/reply/${replyId}/like`)
  )

  reportAnnouncementReply = replyId => (
    http.post(`/announcements/reply/${replyId}/report`)
  )
}

export default new LeagueService()
